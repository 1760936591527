// import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Link } from 'react-router-dom';
import { homeRed } from 'components/Map/utils';
import { getFullAddress } from 'utils/format';
import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data alertu',
        accessor: 'date',
        key: 'createdAt',
        sortable: true,
      },
      {
        Header: 'Producent nieczystości',
        accessor: 'producer',
        key: 'producer.address.street',
        sortable: false,
      },
      {
        Header: 'Przyczyna alertu',
        accessor: 'description',
        key: 'description',
        sortable: true,
      },
    ],
    rows: results.map((row) => ({
      date: moment(row.createdAt).format('YYYY-MM-DD'),
      producer: row.producer ? <Link className="underline" to={`/producer/${row.producer._id}/details`}>{getFullAddress(row.producer.address)}</Link> : '',
      description: row.description,
    })),
  };
  return tableData;
};

const getMapData = (data) => data.data.map((row) => row.producer && ({
  id: row.producer._id,
  address: row.producer.address,
  icon: homeRed,
  reason: row.description,
  alert: true,
}));

export { getTableData, getMapData };
