import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import CircularProgress from '@mui/material/CircularProgress';
import SuiTypography from 'components/SuiTypography';
import ProtectedContent from 'guard/ProtectedContent';
import Skeleton from '@mui/material/Skeleton';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import TextField from './TextField';
import SelectField from './SelectField';
import RadioButton from './RadioButton';

const DataForm = ({
  initialValues,
  validationSchema,
  formSchema,
  loading,
  fetching,
  submit,
  cancel,
  reinitial,
}) => {
  const navigate = useNavigate();
  const cancelClick = () => {
    if (typeof cancel === 'string') {
      navigate(cancel);
    } else {
      cancel();
    }
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <Formik
      enableReinitialize={reinitial}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
      }) => (
        <Form>
          <Grid container spacing={2} alignItems="center">
            {formSchema.fields.map((field) => (
              (!field.related || (field.related && field.related.values.includes(values[field.related.key]))) && (
                <ProtectedContent key={field.name} userRole={field.role} organizationType={field.organizationType}>
                  <Grid key={field.name} item xs={!isMobile() ? (field.width || 12) : 12}>
                    {
                      loading || !values ? <Skeleton height={48} sx={{ marginBottom: '16px' }} />
                        : (
                          <SuiBox mb={2}>
                            {
                              field.type === 'select'
                              && (
                                <SelectField
                                  field={field}
                                  error={errors[field.name] && touched[field.name]}
                                  value={values[field.name]}
                                  handleChange={handleChange}
                                  readonly={field.readonly}
                                />
                              )
                            }
                            {
                              field.type === 'radio'
                              && (
                                <RadioButton
                                  field={field}
                                  error={errors[field.name] && touched[field.name]}
                                  value={values[field.name]}
                                  handleChange={handleChange}
                                />
                              )
                            }
                            {
                              field.type === 'button'
                              && (
                                <SuiButton
                                  onClick={() => field.action()}
                                  disabled={fetching || loading}
                                  color={field.color}
                                  variant={field.variant}
                                  size={field.size}
                                >
                                  {field.label}
                                </SuiButton>
                              )
                            }
                            {
                              (!field.type || field.type === 'text' || field.type === 'password' || field.type === 'number')
                              && (
                                <TextField
                                  field={field}
                                  error={errors[field.name] && touched[field.name]}
                                  value={values[field.name]}
                                  handleChange={handleChange}
                                  readonly={field.readonly}
                                />
                              )
                            }
                            <SuiTypography component="div" color="error" variant="caption">
                              {errors[field.name] && touched[field.name] && errors[field.name]}
                            </SuiTypography>
                          </SuiBox>
                        )
                    }
                  </Grid>
                </ProtectedContent>
              )
            ))}
            <Grid item xs={12}>
              <SuiBox
                className="add-collection-buttons"
                mt={2}
                mb={1}
                sx={{ display: 'flex', alignItems: 'center', flexDirection: isMobile() && 'column !important' }}
              >
                {
                  loading || !values ? <Skeleton height={48} width={180} />
                    : (
                      <SuiButton
                        disabled={fetching || loading}
                        type="submit"
                        size={formSchema.button.size}
                        variant={formSchema.button.variant}
                        color={formSchema.button.color}
                        fullWidth={formSchema.button.fullWidth}
                      >
                        {fetching && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} />}
                        {formSchema.button.label}
                      </SuiButton>
                    )
                }
                {
                  cancel && (
                    loading || !values ? <Skeleton height={28} width={180} />
                      : (
                        <SuiBox sx={{ marginLeft: isMobile() ? 0 : 2, width: isMobile() && '100%' }}>
                          <SuiButton
                            onClick={() => cancelClick()}
                            disabled={fetching || loading}
                            color="info"
                            variant="outlined"
                          >
                            Anuluj
                          </SuiButton>
                        </SuiBox>
                      )
                  )
                }
              </SuiBox>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

DataForm.defaultProps = {
  loading: false,
  fetching: false,
  cancel: '',
  reinitial: true,
};

DataForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  formSchema: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  fetching: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.string || PropTypes.func,
  reinitial: PropTypes.bool,
};

export default DataForm;
