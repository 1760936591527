/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard PRO React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

const DataTableBodyCell = ({ noBorder, align, children }) => {
  const { light } = colors;
  const { size } = typography;
  const { borderWidth } = borders;

  return (
    <SuiBox
      component="td"
      textAlign={align}
      fontSize={size.sm}
      borderBottom={noBorder ? 'none' : `${borderWidth[1]} solid ${light.main}`}
      py={1.5}
      px={3}
    >
      <SuiBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: 'middle' }}
      >
        {children}
      </SuiBox>
    </SuiBox>
  );
};

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default DataTableBodyCell;
