import PropTypes from 'prop-types';
import DataTableBody from './DataTableBody';
import DataTableSkeleton from './DataTableSkeleton';

const DataTable = ({
  pagination,
  isSorted,
  noEndBorder,
  paginationPage,
  changeParam,
  count,
  table,
  sortPage,
  isLoading,
  isFetching,
  limit,
}) => (
  isLoading ? <DataTableSkeleton />
    : (
      <DataTableBody
        pagination={pagination}
        isSorted={isSorted}
        noEndBorder={noEndBorder}
        paginationPage={paginationPage}
        changeParam={changeParam}
        count={count}
        table={table}
        sortPage={sortPage}
        isFetching={isFetching}
        limit={limit}
      />
    )
);

DataTable.defaultProps = {
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  isLoading: false,
  table: {},
  paginationPage: 0,
  changeParam: () => null,
  sortPage: '',
  isFetching: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  table: PropTypes.objectOf(PropTypes.array),
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  paginationPage: PropTypes.number,
  changeParam: PropTypes.func,
  count: PropTypes.number.isRequired,
  sortPage: PropTypes.string,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  limit: PropTypes.number.isRequired,
};

export default DataTable;
