import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SuiBox from 'components/SuiBox';
import { useSoftUIController, setLayout } from 'context';

const PageLayout = ({ background, children }) => {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'page');
  }, [pathname]);

  return (
    <SuiBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}
    >
      {children}
    </SuiBox>
  );
};

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: 'default',
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  background: PropTypes.oneOf(['white', 'light', 'default']),
  children: PropTypes.node.isRequired,
};

export default PageLayout;
