import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data odbioru',
        accessor: 'date',
        key: 'collections.date',
        sortable: true,
      },
      {
        Header: 'Pojazd odbierający',
        accessor: 'truck',
        key: 'clients.address.street',
        sortable: true,
      },
      {
        Header: 'Objętość',
        accessor: 'capacity',
        key: 'clients.contact.phone',
        sortable: true,
      },
    ],
    rows: results.map((row) => ({
      ...row,
      date: moment(row.date).format('YYYY-MM-DD'),
      capacity: `${row.capacity}m3`,
    })),
  };
  return tableData;
};

export { getTableData };
