import * as yup from 'yup';
import { Link } from 'react-router-dom';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import confirmModal from 'components/Modal/ConfirmModal';
import UserAPI from 'api/user';
import { getUserDataFromToken } from 'utils/user';

const removeUser = (id, refetch) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz usunąć tego użytkownika ?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await UserAPI.delete(id);
      refetch();
    },
    successText: 'Użytkownik został usunięty.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

const resetPassword = (id) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz zresetować hasło tego użytkownika ?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await UserAPI.reset(id);
    },
    successText: 'Link do zmiany hasła został przesłany na email użytkownika.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

const getUserData = ({ data }, refetch) => {
  const { results, countAdmins } = data;
  const deletedColumn = [];
  const deletedUsers = results.some((item) => item.isDeleted !== undefined);
  if (deletedUsers) {
    deletedColumn.push({
      Header: 'Usunięty',
      accessor: 'isDeleted',
      sortable: true,
      key: 'isDeleted',
    });
  }
  const tableData = {
    columns: [
      {
        Header: 'Imie',
        accessor: 'name',
        sortable: true,
        key: 'name',
      },
      {
        Header: 'Nazwisko',
        accessor: 'surname',
        sortable: true,
        key: 'surname',
      },
      {
        Header: 'Email',
        accessor: 'email',
        sortable: true,
        key: 'email',
      },
      {
        Header: 'Rola',
        accessor: 'role',
        sortable: true,
        key: 'role',
      },
      {
        Header: 'Status',
        accessor: 'isActive',
        sortable: true,
        key: 'isActive',
      },
      ...deletedColumn,
      {
        Header: 'Edytuj',
        accessor: 'edit',
        width: '8%',
        key: 'edit',
      },
      {
        Header: 'Usuń',
        accessor: 'delete',
        width: '8%',
        key: 'delete',
      },
      {
        Header: 'Reset hasła',
        accessor: 'reset',
        width: '8%',
        key: 'reset',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      name: row.name,
      isActive: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={row.isActive ? 'success' : 'error'} />
          {
            row.isActive ? 'aktywowany'
              : 'nieaktywowany'
          }
        </SuiBox>
      ),
      isDeleted: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={!row.isDeleted ? 'success' : 'error'} />
          {
            row.isDeleted ? 'usunięty'
              : 'aktywny'
          }
        </SuiBox>
      ),
      edit: (
        <Link to={`/user/${row._id}`}>
          <Tooltip title="Edytuj" placement="top">
            <SuiButton variant="outlined" color="info" circular iconOnly>
              <EditIcon fontSize="large" />
            </SuiButton>
          </Tooltip>
        </Link>
      ),
      delete: (
        <Tooltip title="Usuń" placement="top">
          <SuiButton disabled={countAdmins === 1} onClick={() => removeUser(row._id, refetch)} variant="outlined" color="error" circular iconOnly>
            <DeleteIcon fontSize="large" />
          </SuiButton>
        </Tooltip>
      ),
      reset: (
        <Tooltip title="Reset hasła" placement="top">
          <SuiButton onClick={() => resetPassword(row._id)} variant="outlined" color="info" circular iconOnly>
            <RefreshIcon fontSize="large" />
          </SuiButton>
        </Tooltip>
      ),
    })),
  };
  return tableData;
};

const formSchema = {
  fields: [
    {
      name: 'name',
      placeholder: 'Imię',
    },
    {
      name: 'surname',
      placeholder: 'Nazwisko',
    },
    {
      name: 'email',
      placeholder: 'Email',
    },
    {
      name: 'role',
      placeholder: 'Rola',
      type: 'select',
      options: [
        {
          label: 'Administrator',
          value: 'admin',
        },
        {
          label: 'Edytor',
          value: 'editor',
        },
      ],
    },
  ],
  button: {
    fullWidth: false,
    label: 'Zapisz',
    size: 'large',
    variant: 'gradient',
    color: 'info',
  },
};

const validationSchema = yup.object({
  name: yup
    .string()
    .required('To pole jest wymagane!'),
  surname: yup.string().required('To pole jest wymagane!'),
  email: yup.string().required('To pole jest wymagane!').email('Podaj prawidłowy adres email!'),
  role: yup.string().required('To pole jest wymagane!'),
});

const initialValues = getUserDataFromToken().group === 'Administrator firmy' ? {
  name: '',
  surname: '',
  email: '',
  company_id: '',
} : {
  name: '',
  surname: '',
  email: '',
  company_id: '',
  group: '',
};

export {
  removeUser,
  resetPassword,
  getUserData,
  initialValues,
  validationSchema,
  formSchema,
};
