import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import LayoutLogin from 'layouts/LayoutLogin';
import SuiTypography from 'components/SuiTypography';
import Gniezno from 'assets/img/gniezno.jpg';
import Card from '@mui/material/Card';
import { setMessage } from 'store';
import DataForm from 'components/DataForm';
import Auth from 'api/auth';
import SuiBox from 'components/SuiBox';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { getErrorFromApi } from 'utils/messages';
import { initialValues, validationSchema, formSchema } from './utils';

const ConfirmPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const mutation = useMutation((data) => Auth.confirm(data), {
    onSuccess: () => {
      navigate('/');
      dispatch(
        setMessage({
          variant: 'success',
          title: 'Hasło zostało zapisane, teraz możesz się zalogować.',
        }),
      );
    },
    onError: (err) => {
      const { data } = err.response;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(data),
        }),
      );
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({
      password: data.password,
      hash: token,
    });
  };

  return (
    <LayoutLogin image={Gniezno}>
      <Card>
        <SuiBox p={3} mb={1} textAlign="center">
          <Logo className="login-logo" />
          <SuiTypography variant="h5" fontWeight="medium">
            Utwórz hasło do swojego konta.
          </SuiTypography>
        </SuiBox>
        <SuiBox p={3}>
          <DataForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            formSchema={formSchema}
            fetching={mutation.isLoading}
            submit={onSubmit}
          />
        </SuiBox>
      </Card>
    </LayoutLogin>
  );
};

export default ConfirmPassword;
