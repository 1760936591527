import React from 'react';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiProgress from 'components/SuiProgress';
import Skeleton from '@mui/material/Skeleton';
import { PropTypes } from 'prop-types';
import { getProgressColor } from 'utils/table';

const DataInfo = ({
  isLoading,
  value,
  label,
  bold,
  withBalance,
}) => (
  <>
    <SuiTypography variant="caption">
      {label}
    </SuiTypography>
    {
      isLoading ? (
        <Skeleton height={31} sx={{ marginBottom: '16px', width: '100%' }} />
      ) : (
        <SuiBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SuiTypography variant={bold ? 'h4' : 'body2'}>
            {value}
          </SuiTypography>
          {
            withBalance && (
              <SuiProgress
                value={parseInt(value, 10) > 100 ? 100 : parseInt(value, 10)}
                color={getProgressColor(parseInt(value, 10))}
                sx={{ width: '200px', height: 16, marginLeft: 4 }}
              />
            )
          }
        </SuiBox>
      )
    }
  </>
);

DataInfo.defaultProps = {
  isLoading: true,
  value: '-',
  label: '-',
  bold: false,
  withBalance: false,
};

DataInfo.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  bold: PropTypes.bool,
  withBalance: PropTypes.bool,
};

export default DataInfo;
