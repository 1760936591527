import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useSelector } from 'react-redux';
import SuiAvatar from 'components/SuiAvatar';
import { useLocation, matchPath } from 'react-router-dom';
import breakpoints from 'assets/theme/base/breakpoints';
import Gniezno from 'assets/img/gniezno.jpg';
import routes from 'routes';
import AppAlert from './AppAlert';
import DashboardNavbar from '../DashboardNavbar';

const Header = ({ setHideMobileMenu }) => {
  const alerts = useSelector((state) => state.app.alerts);
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const route = useLocation().pathname;

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const getRouteData = () => {
    let foundRoute;
    routes.forEach((item) => {
      if (item.type !== 'divider') {
        if (!foundRoute) {
          foundRoute = matchPath({ path: item.route }, route) ? item : null;
          if (!foundRoute && item.children) {
            foundRoute = item.children.forEach((it) => {
              foundRoute = matchPath({ path: it.route }, route) ? it : null;
            });
          }
        }
      }
    });
    return foundRoute;
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox position="relative">
      <DashboardNavbar setHideMobileMenu={setHideMobileMenu} absolute light />
      <SuiBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight={`${isMobile() ? '40px' : '8.75rem'}`}
        borderRadius={`${isMobile() ? '10px' : 'xl'}`}
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) => `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6),
          )}, url(${Gniezno})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
          margin: `${isMobile() && '15px'}`,
        }}
      />
      <Card
        sx={{
          backdropFilter: 'saturate(200%) blur(30px)',
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
          display: isMobile() && 'none',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={1}>
            <SuiAvatar
              variant="rounded"
              size="xl"
              shadow="sm"
              bgColor="info"
            >
              {getRouteData() && getRouteData().titleIcon}
            </SuiAvatar>
          </Grid>
          <Grid item xs={11}>
            <SuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} height="100%" mt={0.5} lineHeight={1}>
              <SuiTypography variant="h2" fontWeight="medium" textTransform="capitalize">
                {getRouteData() && getRouteData().name}
              </SuiTypography>
              <SuiBox sx={{ display: 'flex', overflowX: 'auto' }}>
                {
                  alerts && alerts.map((item) => (
                    item && (
                      <AppAlert key={item._id} data={item} />
                    )
                  ))
                }
              </SuiBox>
            </SuiBox>
          </Grid>
        </Grid>
      </Card
>
    </SuiBox>
  );
};

Header.propTypes = {
  setHideMobileMenu: PropTypes.func.isRequired,
};

export default Header;
