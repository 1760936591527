import React from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import ReceptionAPI from 'api/reception';
import SuiInput from 'components/SuiInput';
import SearchIcon from '@mui/icons-material/Search';
import SuiBox from 'components/SuiBox';
import SuiSelect from 'components/SuiSelect';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import SuiButton from 'components/SuiButton';
import ProtectedContent from 'guard/ProtectedContent';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import SuiButton from 'components/SuiButton';
// import SuiTypography from 'components/SuiTypography';
// import Popover from '@mui/material/Popover';
// import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import { quarterDates } from 'utils/date';
import { getTableData } from './utils';

const Reception = () => {
  // const [anchorEl, setAnchorEl] = useState(null);
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
    year: quarterDates()[0].value,
    // collected_impurities_from: '',
    // collected_impurities_to: '',
    // type: '',
  }, 'receptions_list', () => ReceptionAPI.receptionList(params));
  const navigate = useNavigate();

  return (
    <Card>
      <SuiBox>
        <SuiBox sx={{ padding: 4, display: 'flex', justifyContent: 'space-between' }}>
          <SuiBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SuiBox sx={{ marginRight: 2 }}>
              <SuiInput
                name="search"
                placeholder="Szukaj..."
                icon={{
                  component: <SearchIcon />,
                  direction: 'left',
                }}
                onChange={(event) => changeParam(event.target.name, event.target.value, true)}
                isLoading={isLoading}
              />
            </SuiBox>
            <SuiBox sx={{ marginRight: 2 }}>
              <SuiSelect
                value={params.year || quarterDates()[0].value}
                placeholder={quarterDates()[0].label}
                name="year"
                onChange={(event) => changeParam('year', event.value)}
                options={quarterDates()}
                isLoading={isLoading}
              />
            </SuiBox>
          </SuiBox>
          <ProtectedContent groups={['Administrator firmy']}>
            <SuiBox>
              <SuiButton isLoading={isLoading} onClick={() => navigate('/receipts/add_receipt')} variant="gradient" color="info">
                Dodaj nowy
                <AddIcon sx={{ marginLeft: 2 }} />
              </SuiButton>
            </SuiBox>
          </ProtectedContent>
          {/* <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
            {
              isLoading ? <Skeleton height={38} sx={{ minWidth: '38px' }} />
                : (
                  <SuiButton aria-describedby="popover" onClick={(event) => setAnchorEl(!anchorEl ? event.currentTarget : null)} variant="outlined" color="info" iconOnly>
                    <FilterListIcon />
                  </SuiButton>
                )
            }
            <Popover
              id="popover"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <SuiBox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 2,
              }}
              >
                <SuiBox>
                  <SuiTypography sx={{ marginRight: 2 }} variant="caption">Odebrane nieczystości:</SuiTypography>
                </SuiBox>
                <SuiBox sx={{ display: 'flex' }}>
                  <SuiInput
                    name="balance_from"
                    placeholder="Od"
                    onChange={(event) => changeParam(event.target.name, event.target.value, true)}
                    isLoading={isLoading}
                    type="number"
                    sx={{ width: '100px !important' }}
                  />
                  -
                  <SuiInput
                    name="balance_to"
                    placeholder="Do"
                    onChange={(event) => changeParam(event.target.name, event.target.value, true)}
                    isLoading={isLoading}
                    type="number"
                    sx={{ width: '100px !important' }}
                  />
                </SuiBox>
              </SuiBox>
              <SuiBox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
              >
                <SuiBox>
                  <SuiTypography sx={{ marginRight: 2 }} variant="caption">Rodzaj nieczystości:</SuiTypography>
                </SuiBox>
                <SuiSelect
                  placeholder="Rodzaj nieczystości"
                  name="type"
                  onChange={(event) => changeParam('year', event.value)}
                  options={[
                    { value: 0, label: 'Bytowe' },
                    { value: 1, label: 'Inne' },
                  ]}
                  isLoading={isLoading}
                />
              </SuiBox>
            </Popover>
          </SuiBox> */}
        </SuiBox>
        <SuiBox>
          <DataTable
            table={data && getTableData(data.data)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </SuiBox>
    </Card>
  );
};

export default Reception;
