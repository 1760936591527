import React, { useState } from 'react';
// import DefaultCounterCard from 'components/CounterCards/DefaultCounterCard';
import Grid from '@mui/material/Grid';
// import SuiProgress from 'components/SuiProgress';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import SuiInput from 'components/SuiInput';
import SuiSelect from 'components/SuiSelect';
import SearchIcon from '@mui/icons-material/Search';
import Map from 'components/Map';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DataTable from 'components/DataTable';
import AlertAPI from 'api/alert';
import { useQuery } from 'react-query';
import useDataTable from 'hooks/useDataTable';
// import SuiSelect from 'components/SuiSelect';
import SuiTypography from 'components/SuiTypography';
import { quarterDates } from 'utils/date';
import { getTableData, getMapData } from './utils';

const Dashboard = () => {
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({ page: 1, search: '', year: quarterDates()[0].value }, 'alerts', () => AlertAPI.getAlerts(params));
  const [menu, setMenu] = useState(0);
  const handleSetMenu = (event, newMenu) => setMenu(newMenu);

  const mapQuery = useQuery(
    'alerts_map',
    AlertAPI.getAllAlerts,
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  return (
    <Grid container spacing={3} sx={{ marginTop: 0 }}>
      <Grid item xs={12} md={12} xl={12}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <SuiTypography variant="subtitle" sx={{ marginBottom: 2 }}>Alerty</SuiTypography>
          </Grid>
          <Grid item xs={4}>
            <SuiBox marginBottom={3}>
              <AppBar position="static">
                <Tabs orientation="horizontal" value={menu} onChange={handleSetMenu}>
                  <Tab label="Widok mapy" />
                  <Tab label="Widok listy" />
                </Tabs>
              </AppBar>
            </SuiBox>
          </Grid>
        </Grid>
        <Card>
          {menu === 0 && <Map alertPage markers={mapQuery.data && getMapData(mapQuery.data)} isLoading={mapQuery.isLoading} />}
          {menu === 1 && (
            <SuiBox sx={{ padding: 4 }}>
              <SuiBox sx={{ display: 'flex', marginBottom: 4 }}>
                <SuiInput
                  name="search"
                  placeholder="Szukaj producenta..."
                  icon={{
                    component: <SearchIcon />,
                    direction: 'left',
                  }}
                  onChange={(event) => changeParam(event.target.name, event.target.value, true)}
                  isLoading={isLoading}
                />
                <SuiBox sx={{ marginLeft: 2, width: 400 }}>
                  <SuiSelect
                    value={params.alertType}
                    placeholder={params.alertType || 'Przyczyna alertu'}
                    name="alertType"
                    onChange={(event) => changeParam('alertType', event.value)}
                    options={[
                      {
                        label: 'Wszystkie',
                        value: '',
                      },
                      {
                        label: 'Podmiot nieujęty w ewidencji',
                        value: 'NEW_PRODUCER',
                      },
                      {
                        label: 'Niezgodność bilansu',
                        value: 'BALANCE',
                      },
                      {
                        label: 'Częstotliwość odbiorów niezgodna z regulaminem',
                        value: 'COLLECTIONS_FREQUENCY',
                      },
                      {
                        label: 'Brak umowy na wywóz nieczystości',
                        value: 'NO_CONTRACT',
                      },
                    ]}
                    isLoading={isLoading}
                  />
                </SuiBox>
              </SuiBox>
              <DataTable
                table={data && getTableData(data.data)}
                loading={isLoading}
                paginationPage={params.page}
                sortPage={params.sort}
                changeParam={changeParam}
                count={count}
                isLoading={isLoading}
                isFetching={isFetching}
                limit={params.limit}
              />
            </SuiBox>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
