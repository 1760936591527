import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import { useDispatch, useSelector } from 'react-redux';
import { useSoftUIController } from 'context';
import Tooltip from '@mui/material/Tooltip';
import { destroyTokens } from 'store';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  card, cardContent,
} from './styles/sidenavCard';

const SidenavCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appState = useSelector((state) => state.app);
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card className="overflow-hidden" sx={(theme) => card(theme, { miniSidenav })}>
      <CardContent sx={(theme) => cardContent(theme, { sidenavColor })}>
        {
          !miniSidenav && (
            <SuiTypography sx={{ whiteSpace: 'pre-wrap' }} variant="caption" color="white">
              Zalogowany jako:
            </SuiTypography>
          )
        }
        <SuiBox lineHeight={1}>
          {
            !miniSidenav && (
              <SuiTypography sx={{ whiteSpace: 'pre-wrap' }} variant="h6" color="white">
                {appState.user && appState.user.email}
              </SuiTypography>
            )
          }
          <Tooltip title="Wyloguj" placement="top">
            <SuiButton
              size="small"
              color="white"
              fullWidth
              onClick={() => {
                dispatch(destroyTokens());
                navigate('/');
              }}
              style={{ padding: `${miniSidenav && '0px 0px'}`, minWidth: `${miniSidenav && '33px'}` }}
            >
              { !miniSidenav && 'Wyloguj' }
              <LogoutIcon style={{ marginLeft: `${!miniSidenav ? 12 : 0}` }} />
            </SuiButton>
          </Tooltip>
        </SuiBox>
      </CardContent>
    </Card>
  );
};

export default SidenavCard;
