const subPages = [
  {
    label: 'Dane firmy',
    route: 'details',
    organizationType: ['community', 'catchpoint'],
    role: ['admin', 'editor'],

  },
  {
    label: 'Rejestr umów',
    route: 'clients',
    organizationType: ['community', 'catchpoint'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Rejestr odbiorów',
    route: 'collections',
    organizationType: ['community', 'catchpoint'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Rejestr zrzutów',
    route: 'dumps',
    organizationType: ['catchpoint'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Rejestr raportów',
    route: 'reports',
    organizationType: ['catchpoint'],
    role: ['admin', 'editor'],
  },
];

export { subPages };
