import React, { useState } from 'react';
import AlertAPI from 'api/alert';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import { useMutation } from 'react-query';
import Dialog from '@mui/material/Dialog';
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import SoftDatePicker from 'components/SuiDatePicker';
import { Polish } from 'flatpickr/dist/l10n/pl';
import moment from 'moment';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { setMessage, setRefetchAlerts } from 'store';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { getErrorFromApi } from 'utils/messages';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SuiAlert from 'components/SuiAlert';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppAlert = ({ data }) => {
  const [open, setOpenDialog] = useState(false);
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();

  const mutationDelete = useMutation(() => AlertAPI.deleteAlert(data._id), {
    onSuccess: (response) => {
      const { message } = response.data;
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
      dispatch(setRefetchAlerts(true));
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const mutationUpdate = useMutation(() => AlertAPI.updateAlert(data._id, { date }), {
    onSuccess: (response) => {
      const { message } = response.data;
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
      dispatch(setRefetchAlerts(true));
      setOpenDialog(false);
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
      setOpenDialog(false);
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SuiAlert sx={{ marginLeft: 1, marginBottom: '5px' }} key={data._id} color="dark">
        <WarningAmberOutlinedIcon sx={{ marginRight: 2 }} fontSize="medium" />
        {data.description}
        <IconButton
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={handleClick}
          disabled={mutationDelete.isLoading}
        >
          {
            mutationDelete.isLoading
              ? <CircularProgress size={20} color="info" />
              : <MoreVertIcon />
          }
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem sx={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => mutationDelete.mutate()}>
            Usuń alert
            <DeleteIcon />
          </MenuItem>
          <MenuItem
            sx={{ display: 'flex', justifyContent: 'space-between' }}
            onClick={() => {
              setOpenDialog(true);
              handleClose();
            }}
          >
            Odłóż alert
            <AlarmOffIcon />
          </MenuItem>
        </Menu>
      </SuiAlert>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <SuiBox sx={{ padding: 2, width: '680px' }}>
          <DialogTitle>Podaj datę wznowienia alertu</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <SoftDatePicker
                options={{
                  locale: Polish,
                  dateFormat: 'Y-m-d',
                  minDate: moment(moment(new Date()).add(1, 'days')).format('YYYY-MM-DD'),
                }}
                onChange={([newDate]) => setDate(moment(newDate).format('YYYY-MM-DD'))}
                value={date}
                input={{
                  placeholder: 'Data',
                  value: date,
                  name: 'date',
                  icon: {
                    component: <CalendarToday />,
                    direction: 'left',
                  },
                }}
                onClear={() => setDate(null)}
                noLabel
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SuiButton
              size="small"
              onClick={() => {
                setOpenDialog(false);
                setDate(null);
              }}
              color="info"
              variant="outlined"
              disabled={mutationUpdate.isLoading}
              isFetching={mutationUpdate.isLoading}
            >
              Anuluj
            </SuiButton>
            <SuiButton
              size="small"
              variant="gradient"
              color="info"
              disabled={!date || mutationUpdate.isLoading}
              isFetching={mutationUpdate.isLoading}
              onClick={() => mutationUpdate.mutate()}
            >
              Zapisz
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </>
  );
};

AppAlert.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AppAlert;
