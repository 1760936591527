import React from 'react';
import ProducerAPI from 'api/producer';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import SuiInput from 'components/SuiInput';
import Card from '@mui/material/Card';
import SearchIcon from '@mui/icons-material/Search';
import SuiBox from 'components/SuiBox';
import { useParams } from 'react-router-dom';
import { getTableData } from './utils';

const ProducerDetailsCollections = () => {
  const { id } = useParams();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, ['producer_balances_', id], ProducerAPI.getProducerBalances);

  return (
    <SuiBox>
      <SuiBox sx={{
        padding: 4,
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <SuiBox sx={{ display: 'flex' }}>
          <SuiBox sx={{ marginRight: 2 }}>
            <SuiInput
              name="search"
              placeholder="Szukaj..."
              icon={{
                component: <SearchIcon />,
                direction: 'left',
              }}
              onChange={(event) => changeParam(event.target.name, event.target.value, true)}
              isLoading={isLoading}
            />
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <DataTable
            table={data && getTableData(data.data)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default ProducerDetailsCollections;
