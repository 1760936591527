import React from 'react';
import { Skeleton } from '@mui/material';

const DataTableSkeleton = () => (
  <>
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
    <Skeleton height={40} sx={{ marginBottom: '16px' }} />
  </>
);

export default DataTableSkeleton;
