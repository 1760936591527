import React, { useState } from 'react';
import SuiBox from 'components/SuiBox';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { subPages } from './utils';

const CompanyClientsDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(subPages.findIndex((item) => params['*'] === item.route));
  const handleSetMenu = (event, newMenu) => {
    setMenu(newMenu);
    navigate(`/client/${params.id}/${subPages[newMenu].route}`);
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox>
      <SuiBox marginBottom={3} marginTop={3}>
        <AppBar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
          position="static"
        >
          <SuiBox sx={{ width: isMobile() ? '100%' : '50%' }}>
            <Tabs orientation="horizontal" value={menu} onChange={handleSetMenu}>
              {
                subPages.map((page) => <Tab className="submenu-tab" key={page.route} label={page.label} />)
              }
            </Tabs>
          </SuiBox>
        </AppBar>
      </SuiBox>
      <SuiBox>
        <Outlet />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyClientsDetails;
