import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import GroupIcon from '@mui/icons-material/Group';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Dashboard from 'pages/Dashboard';
import User from 'pages/User';
import AddUser from 'pages/User/AddUser';
import EditUser from 'pages/User/EditUser';
import Producer from 'pages/Producer';
import ProducerDetails from 'pages/Producer/ProducerDetails';
import ProducerDetailsData from 'pages/Producer/ProducerDetails/ProducerDetailsData';
import ProducerDetailsCollections from 'pages/Producer/ProducerDetails/ProducerDetailsCollections';
import ProducerDetailsContracts from 'pages/Producer/ProducerDetails/ProducerDetailsContracts';
import ProducerDetailsCounters from 'pages/Producer/ProducerDetails/ProducerDetailsCounters';
import ProducerDetailsBalances from 'pages/Producer/ProducerDetails/ProducerDetailsBalances';
import ProducerAdd from 'pages/Producer/ProducerAdd';
import ProducerEdit from 'pages/Producer/ProducerEdit';
import Company from 'pages/Company';
import CompanyDetails from 'pages/Company/CompanyDetails';
import CompanyCollections from 'pages/Company/CompanyCollections';
import CompanyDumps from 'pages/Company/CompanyDumps';
import CompanyDumpsAdd from 'pages/Company/CompanyDumps/CompanyDumpsAdd';
import CompanyReports from 'pages/Company/CompanyReports';
import CompanyReportsDetails from 'pages/Company/CompanyReports/CompanyReportsDetails';
import CompanyVehicles from 'pages/Company/CompanyVehicles';
import CompanyDetailsData from 'pages/Company/CompanyDetails/CompanyDetailsData';
import CompanyDetailsCollections from 'pages/Company/CompanyDetails/CompanyDetailsCollections';
import CompanyDetailsDumps from 'pages/Company/CompanyDetails/CompanyDetailsDumps';
import CompanyDetailsClients from 'pages/Company/CompanyDetails/CompanyDetailsClients';
import CompanyDetailsReports from 'pages/Company/CompanyDetails/CompanyDetailsReports';
import Reception from 'pages/Reception';
import Import from 'pages/Import';
import CompanyDashboard from 'pages/Dashboard/CompanyDashboard';
import Report from 'pages/Report';
import ReportDetails from 'pages/Report/ReportDetails';
import CompanyCollectionsAdd from 'pages/Company/CompanyCollections/CompanyCollectionsAdd';
import CompanyClients from 'pages/Company/CompanyClients';
import CompanyClientsAdd from 'pages/Company/CompanyClients/CompanyClientsAdd/CompanyClientsAdd';
import CompanyClientsEdit from 'pages/Company/CompanyClients/CompanyClientsEdit/CompanyClientsEdit';
import CompanyClientsDetails from 'pages/Company/CompanyClients/CompanyClientsDetails';
import CompanyClientsDetailsData from 'pages/Company/CompanyClients/CompanyClientsDetailsData';
import CompanyClientsCollections from 'pages/Company/CompanyClients/CompanyClientsCollections';
import CompanyStatement from 'pages/Company/CompanyStatement';
import Organization from 'pages/Organization';
import OrganizationAdd from 'pages/Organization/OrganizationAdd';
import Collection from 'pages/Collection';
import Dump from 'pages/Dump';
import CompanyAdd from 'pages/Company/CompanyAdd';

const routes = [
  {
    type: 'collapse',
    name: 'Pulpit kontrolny',
    key: 'dashboard',
    route: '/dashboard',
    icon: <DesktopMacIcon size="12px" />,
    titleIcon: <DesktopMacIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['community'],
    role: ['admin', 'superadmin'],
    component: () => <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Organizacje',
    key: 'organization',
    route: '/organization',
    icon: <CorporateFareIcon size="12px" />,
    titleIcon: <CorporateFareIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['asenico'],
    role: ['superadmin'],
    component: () => <Organization />,
  },
  {
    name: 'Dodawanie organizacji',
    key: 'add_organization',
    route: '/organization/add',
    icon: <CorporateFareIcon size="12px" />,
    titleIcon: <CorporateFareIcon fontSize="large" />,
    organizationType: ['asenico'],
    role: ['superadmin'],
    component: () => <OrganizationAdd />,
  },
  {
    type: 'collapse',
    name: 'Odbiory',
    key: 'collections',
    route: '/collection',
    icon: <RvHookupIcon size="12px" />,
    titleIcon: <RvHookupIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyCollections />,
  },
  {
    name: 'Dodawanie odbiorów',
    key: 'add_receipt',
    route: '/collection/add',
    icon: <AssignmentIcon size="12px" />,
    titleIcon: <AssignmentIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyCollectionsAdd />,
  },
  {
    type: 'collapse',
    name: 'Zrzuty',
    key: 'dumps',
    route: '/dump',
    icon: <LocalGasStationIcon size="12px" />,
    titleIcon: <LocalGasStationIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyDumps />,
  },
  {
    name: 'Dodawanie zrzutów',
    key: 'add_dumps',
    route: '/dump/add',
    icon: <LocalGasStationIcon size="12px" />,
    titleIcon: <LocalGasStationIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyDumpsAdd />,
  },
  {
    type: 'collapse',
    name: 'Raporty',
    key: 'reports',
    route: '/report',
    icon: <ReceiptLongIcon size="12px" />,
    titleIcon: <ReceiptLongIcon fontSize="large" />,
    oCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyReports />,
  },
  {
    name: 'Szczegóły raportu',
    key: 'report_details',
    route: '/report/:id',
    icon: <ReceiptLongIcon size="12px" />,
    titleIcon: <ReceiptLongIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyReportsDetails />,
  },
  {
    type: 'collapse',
    name: 'Klienci',
    key: 'client',
    route: '/client',
    icon: <AssignmentIndIcon size="12px" />,
    titleIcon: <AssignmentIndIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyClients />,
  },
  {
    name: 'Dodawanie klienta',
    key: 'client_add',
    route: '/client/add',
    icon: <AssignmentIndIcon size="12px" />,
    titleIcon: <AssignmentIndIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyClientsAdd />,
  },
  {
    name: 'Dane klienta',
    key: 'client_details',
    route: '/client/:id/*',
    icon: <AssignmentIndIcon size="12px" />,
    titleIcon: <AssignmentIndIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyClientsDetails />,
    children: [
      {
        name: 'Dane podmiotu',
        key: 'client_details_data',
        route: 'details',
        icon: <AssignmentIndIcon size="12px" />,
        titleIcon: <AssignmentIndIcon fontSize="large" />,
        organizationType: ['company'],
        role: ['admin', 'editor'],
        component: () => <CompanyClientsDetailsData />,
      },
      {
        name: 'Rejestr obiorów',
        key: 'client_details_collections',
        route: 'collections',
        icon: <AssignmentIndIcon size="12px" />,
        titleIcon: <AssignmentIndIcon fontSize="large" />,
        organizationType: ['company'],
        role: ['admin', 'editor'],
        component: () => <CompanyClientsCollections />,
      },
    ],
  },
  {
    name: 'Edycja danych klienta',
    key: 'client_edit',
    route: '/client/:id/edit',
    icon: <AssignmentIndIcon size="12px" />,
    titleIcon: <AssignmentIndIcon fontSize="large" />,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyClientsEdit />,
  },
  {
    type: 'collapse',
    name: 'Flota',
    key: 'fleet',
    route: '/fleet',
    icon: <LocalShippingIcon size="12px" />,
    titleIcon: <LocalShippingIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyVehicles />,
  },
  {
    type: 'collapse',
    name: 'Sprawozdanie kwartalne',
    key: 'statement',
    route: '/statement',
    icon: <FormatListBulletedIcon size="12px" />,
    titleIcon: <FormatListBulletedIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company'],
    role: ['admin', 'editor'],
    component: () => <CompanyStatement />,
  },
  {
    type: 'collapse',
    name: 'Raporty',
    key: 'report',
    route: '/report',
    icon: <ReceiptLongIcon size="12px" />,
    titleIcon: <ReceiptLongIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['catchpoint'],
    role: ['admin', 'editor'],
    component: () => <Report />,
  },
  {
    type: 'collapse',
    name: 'Producenci nieczystości',
    key: 'producer',
    route: '/producer',
    icon: <HomeIcon size="12px" />,
    titleIcon: <HomeIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['community', 'asenico'],
    role: ['admin', 'editor'],
    component: () => <Producer />,
  },
  {
    name: 'Dodawanie nowego podmiotu',
    key: 'producer_add',
    route: '/producer/add',
    icon: <HomeIcon size="12px" />,
    titleIcon: <HomeIcon fontSize="large" />,
    organizationType: ['community'],
    role: ['admin', 'editor'],
    component: () => <ProducerAdd />,
  },
  {
    name: 'Edycja danych podmiotu',
    key: 'producer_edit',
    route: '/producer/:id/edit',
    icon: <HomeIcon size="12px" />,
    titleIcon: <HomeIcon fontSize="large" />,
    organizationType: ['community'],
    role: ['admin', 'editor'],
    component: () => <ProducerEdit />,
  },
  {
    name: 'Producenci nieczystości',
    key: 'producer_details',
    route: '/producer/:id/*',
    organizationType: ['community'],
    role: ['admin', 'editor'],
    component: () => <ProducerDetails />,
    children: [
      {
        name: 'Dane identyfikacyjne',
        key: 'producer_details_data',
        route: 'details',
        icon: <HomeIcon size="12px" />,
        titleIcon: <HomeIcon fontSize="large" />,
        organizationType: ['community'],
        role: ['admin', 'editor'],
        component: () => <ProducerDetailsData />,
      },
      {
        name: 'Rejestr odbiorów',
        key: 'producer_details_collections',
        route: 'collections',
        icon: <HomeIcon size="12px" />,
        titleIcon: <HomeIcon fontSize="large" />,
        organizationType: ['community'],
        role: ['admin', 'editor'],
        component: () => <ProducerDetailsCollections />,
      },
      {
        name: 'Rejestr umów',
        key: 'producer_details_contracts',
        route: 'contracts',
        icon: <HomeIcon size="12px" />,
        titleIcon: <HomeIcon fontSize="large" />,
        organizationType: ['community'],
        role: ['admin', 'editor'],
        component: () => <ProducerDetailsContracts />,
      },
      {
        name: 'Rejestr pomiarów',
        key: 'producer_details_counters',
        route: 'measurements',
        icon: <HomeIcon size="12px" />,
        titleIcon: <HomeIcon fontSize="large" />,
        organizationType: ['community'],
        role: ['admin', 'editor'],
        component: () => <ProducerDetailsCounters />,
      },
      {
        name: 'Bilanse',
        key: 'producer_details_balances',
        route: 'balances',
        icon: <HomeIcon size="12px" />,
        titleIcon: <HomeIcon fontSize="large" />,
        organizationType: ['community'],
        role: ['admin', 'editor'],
        component: () => <ProducerDetailsBalances />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Firmy asenizacyjne',
    key: 'company',
    route: '/company',
    icon: <LocalShippingIcon size="12px" />,
    titleIcon: <LocalShippingIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['catchpoint', 'community'],
    role: ['admin', 'editor'],
    component: () => <Company />,
  },
  {
    name: 'Dodawanie firmy',
    key: 'add_company',
    route: '/company/add',
    icon: <LocalShippingIcon size="12px" />,
    titleIcon: <LocalShippingIcon fontSize="large" />,
    organizationType: ['community'],
    role: ['admin', 'editor'],
    component: () => <CompanyAdd />,
  },
  {
    name: 'Firmy asenizacyjne',
    key: 'company_details',
    route: '/company/:id/*',
    organizationType: ['community', 'catchpoint'],
    role: ['admin', 'editor'],
    component: () => <CompanyDetails />,
    children: [
      {
        name: 'Dane identyfikacyjne',
        key: 'company_details_data',
        route: 'details',
        icon: <LocalShippingIcon size="12px" />,
        titleIcon: <LocalShippingIcon fontSize="large" />,
        organizationType: ['community', 'catchpoint'],
        role: ['admin', 'editor'],
        component: () => <CompanyDetailsData />,
      },
      {
        name: 'Rejestr odbiorów',
        key: 'company_details_collections',
        route: 'collections',
        icon: <LocalShippingIcon size="12px" />,
        titleIcon: <LocalShippingIcon fontSize="large" />,
        organizationType: ['community', 'catchpoint'],
        role: ['admin', 'editor'],
        component: () => <CompanyDetailsCollections />,
      },
      {
        name: 'Rejestr zrzutów',
        key: 'company_details_dumps',
        route: 'dumps',
        icon: <LocalShippingIcon size="12px" />,
        titleIcon: <LocalShippingIcon fontSize="large" />,
        organizationType: ['catchpoint'],
        role: ['admin', 'editor'],
        component: () => <CompanyDetailsDumps />,
      },
      {
        name: 'Rejestr umów',
        key: 'company_details_clients',
        route: 'clients',
        icon: <LocalShippingIcon size="12px" />,
        titleIcon: <LocalShippingIcon fontSize="large" />,
        organizationType: ['community', 'catchpoint'],
        role: ['admin', 'editor'],
        component: () => <CompanyDetailsClients />,
      },
      {
        name: 'Rejestr raportów',
        key: 'company_details_reports',
        route: 'reports',
        icon: <LocalShippingIcon size="12px" />,
        titleIcon: <LocalShippingIcon fontSize="large" />,
        organizationType: ['catchpoint'],
        role: ['admin', 'editor'],
        component: () => <CompanyDetailsReports />,
      },
    ],
  },
  {
    type: 'collapse',
    name: 'Odbiory',
    key: 'collection',
    route: '/collection',
    icon: <RvHookupIcon size="12px" />,
    titleIcon: <RvHookupIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['catchpoint', 'community'],
    role: ['admin', 'editor'],
    component: () => <Collection />,
  },
  {
    type: 'collapse',
    name: 'Zrzuty',
    key: 'dumps',
    route: '/dump',
    icon: <LocalGasStationIcon size="12px" />,
    titleIcon: <LocalGasStationIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['catchpoint'],
    role: ['admin', 'editor'],
    component: () => <Dump />,
  },
  {
    name: 'Raport',
    key: 'report',
    route: '/report/:id',
    icon: <ReceiptLongIcon size="12px" />,
    titleIcon: <ReceiptLongIcon fontSize="large" />,
    organizationType: ['asenico', 'catchpoint'],
    role: ['admin', 'superadmin', 'editor'],
    component: () => <ReportDetails />,
  },
  {
    type: 'divider',
    key: 'divider',
  },
  {
    type: 'collapse',
    name: 'Zarządzaj użytkownikami',
    key: 'user',
    route: '/user',
    icon: <GroupIcon size="12px" />,
    titleIcon: <GroupIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['company', 'asenico', 'catchpoint', 'community'],
    role: ['admin', 'superadmin'],
    component: () => <User />,
  },
  {
    name: 'Dodaj użytkownika',
    key: 'add',
    route: '/user/add',
    icon: <GroupIcon size="12px" />,
    titleIcon: <GroupIcon fontSize="large" />,
    organizationType: ['company', 'asenico', 'catchpoint', 'community'],
    role: ['admin', 'superadmin'],
    component: () => <AddUser />,
  },
  {
    name: 'Edytuj użytkownika',
    key: 'edit',
    route: '/user/:id',
    icon: <GroupIcon size="12px" />,
    titleIcon: <GroupIcon fontSize="large" />,
    organizationType: ['company', 'asenico', 'catchpoint', 'community'],
    role: ['admin', 'superadmin'],
    component: () => <EditUser />,
  },
  {
    type: 'collapse',
    name: 'Pulpit kontrolny',
    key: 'dashboard',
    route: '/dashboard',
    icon: <DesktopMacIcon size="12px" />,
    titleIcon: <DesktopMacIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['Superadmin'],
    role: ['admin'],
    component: () => <CompanyDashboard />,
  },
  {
    name: 'Raport',
    key: 'report',
    route: '/report/:id',
    icon: <ReceiptLongIcon size="12px" />,
    titleIcon: <ReceiptLongIcon fontSize="large" />,
    organizationType: ['Superadmin', 'Administrator urzędu', 'Administrator firmy', 'Administrator punktu zlewczego'],
    role: ['admin'],
    component: () => <ReportDetails />,
  },
  {
    type: 'collapse',
    name: 'Rejestr odbiorów',
    key: 'reception',
    route: '/reception',
    icon: <AssignmentIcon size="12px" />,
    titleIcon: <AssignmentIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['Superadmin', 'Administrator firmy', 'Administrator urzędu'],
    role: ['admin'],
    component: () => <Reception />,
  },
  {
    type: 'collapse',
    name: 'Importuj dane licznikowe',
    key: 'import',
    route: '/import',
    icon: <ImportExportIcon size="12px" />,
    titleIcon: <ImportExportIcon fontSize="large" />,
    noCollapse: true,
    organizationType: ['asenico'],
    role: ['superadmin'],
    component: () => <Import />,
  },
];

export default routes;
