import { Link } from 'react-router-dom';

const getTableData = (data) => {
  const tableData = {
    columns: data.columns,
    rows: data.rows.map((row) => ({
      ...row,
      company: <Link className="underline" to={`/company/${row.company_id}/receipts`}>{row.company}</Link>,
    })),
  };
  return tableData;
};

export { getTableData };
