import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import Cookies from 'js-cookie';
import Box from '@mui/material/Box';
import { Routes, Route, useLocation } from 'react-router-dom';
import theme from 'assets/theme';
import Auth from 'api/auth';
import LogoSpinner from 'components/LogoSpinner';
import SuiBox from 'components/SuiBox';
import { setUserData, destroyTokens } from 'store';
import toastMessage from 'components/Modal/Toast';
import routes from 'routes';

import Login from 'pages/Login';
import ConfirmPassword from 'pages/ConfirmPassword';

import ProtectedRoutes from 'guard/ProtectedRoute';
import UnprotectedRoute from 'guard/UnprotectedRoute';
import LayoutDashboard from 'layouts/LayoutDashboard';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.app);
  const mutation = useMutation((data) => Auth.refresh(data), {
    onSuccess: (response) => {
      const { accessToken } = response.data;
      dispatch(
        setUserData({
          ACCESS_TOKEN: accessToken,
          REFRESH_TOKEN: Cookies.get('REFRESH_TOKEN'),
        }),
      );
    },
    onError: () => {
      dispatch(destroyTokens());
    },
  });

  useEffect(() => {
    const refreshToken = Cookies.get('REFRESH_TOKEN');
    if (refreshToken) {
      mutation.mutate({ refreshToken });
    } else {
      dispatch(destroyTokens());
    }
  }, []);

  useEffect(() => {
    if (document.querySelector('.main-content')) {
      document.querySelector('.main-content').scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (appState.message) {
      const { variant, title } = appState.message;
      toastMessage(variant, title);
    }
  }, [appState.message]);

  return (
    <ThemeProvider theme={theme}>
      <SuiBox sx={{ minWidth: appState && appState.user && appState.user.organizationType !== 'company' && '1460px' }}>
        <CssBaseline />
        {mutation.isLoading || appState.loadingApp ? (
          <Box
            sx={{
              display: 'flex',
              width: '100vw',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LogoSpinner />
          </Box>
        ) : (
          <Routes>
            <Route
              path="*"
              element={(
                <>
                  <UnprotectedRoute>
                    {
                      !appState.user && (
                        <Routes>
                          <Route exact path="/" element={<Login />} />
                          <Route exact path="/auth/confirm_password/:token" element={<ConfirmPassword />} />
                        </Routes>
                      )
                    }
                  </UnprotectedRoute>
                  <ProtectedRoutes>
                    {appState.user && (
                      <LayoutDashboard>
                        <Routes>
                          {routes.map(
                            (item) => item.component && item.organizationType.includes(appState.user.organizationType) && item.role.includes(appState.user.role)
                              && (item.children ? (
                                <Route
                                  exact
                                  key={item.key}
                                  path={item.route}
                                  element={<>{item.component()}</>}
                                >
                                  {item.children.map((child) => (
                                    <Route
                                      exact
                                      key={child.key}
                                      path={child.route}
                                      element={<>{child.component()}</>}
                                    />
                                  ))}
                                </Route>
                              ) : (
                                <Route
                                  exact
                                  key={item.key}
                                  path={item.route}
                                  element={<>{item.component()}</>}
                                />
                              )),
                          )}
                        </Routes>
                      </LayoutDashboard>
                    )}
                  </ProtectedRoutes>
                </>
              )}
            />
          </Routes>
        )}
      </SuiBox>
    </ThemeProvider>
  );
};

export default App;
