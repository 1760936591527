import { ORGANIZATION_TYPE } from 'utils/constants';
import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Nazwa',
        accessor: 'name',
        sortable: true,
        key: 'name',
      },
      {
        Header: 'Adres',
        accessor: 'address',
        sortable: true,
        key: 'address.street',
      },
      {
        Header: 'Typ',
        accessor: 'type',
        sortable: true,
        key: 'type',
      },
      {
        Header: 'Data utworzenia',
        accessor: 'createdAt',
        sortable: true,
        key: 'createdAt',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      type: ORGANIZATION_TYPE[row.type].label,
      createdAt: moment(row.createdAt).format('YYYY-MM-DD'),
      address: `${row.address.street} ${row.address.buildingNumber}${row.address.localNumber ? `/${row.address.localNumber}` : ''}, ${row.address.city}`,
    })),
  };
  return tableData;
};

export { getTableData };
