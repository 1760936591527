import React, { useState } from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store';
import CompanyAPI from 'api/company';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import AddIcon from '@mui/icons-material/Add';
import DataForm from 'components/DataForm';
import { useMutation } from 'react-query';
import ProtectedContent from 'guard/ProtectedContent';
import { getErrorFromApi } from 'utils/messages';
import {
  getTableData,
  initialValues,
  validationSchema,
  formSchema,
  initialValuesEdit,
} from './utils';

const CompanyVehicles = () => {
  const dispatch = useDispatch();
  const [toggleAdd, setToggleAdd] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const {
    params,
    changeParam,
    data,
    isFetching,
    isLoading,
    count,
    refetch,
  } = useDataTable({
    page: 1,
    sort: '',
  }, 'company_vehicle', CompanyAPI.vehicle);
  const mutation = useMutation((formData) => CompanyAPI.addVehicle(formData), {
    onSuccess: (response) => {
      const { message } = response.data;
      setToggleAdd(false);
      refetch();
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const mutationEdit = useMutation((formData) => CompanyAPI.editVehicle(formData, toggleEdit.id), {
    onSuccess: (response) => {
      const { message } = response.data;
      setToggleEdit(false);
      refetch();
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const onSubmit = async (values) => {
    await mutation.mutate(values);
  };

  const onSubmitEdit = async (values) => {
    await mutationEdit.mutate(values);
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox>
      <ProtectedContent groups={['Superadmin', 'Administrator urzędu']}>
        {
          toggleAdd && (
            <SuiBox className="vehicle-form" sx={{ padding: isMobile() ? 0 : 2, marginBottom: 4 }}>
              <DataForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                formSchema={formSchema}
                fetching={mutation.isLoading}
                submit={onSubmit}
                cancel={() => setToggleAdd(false)}
              />
            </SuiBox>
          )
        }
        {
          toggleEdit && (
            <SuiBox className="vehicle-form" sx={{ padding: isMobile() ? 0 : 2, marginBottom: 4 }}>
              <DataForm
                initialValues={initialValuesEdit(toggleEdit)}
                validationSchema={validationSchema}
                formSchema={formSchema}
                fetching={mutationEdit.isLoading}
                submit={onSubmitEdit}
                cancel={() => setToggleEdit(false)}
              />
            </SuiBox>
          )
        }
        {
          !toggleEdit && !toggleAdd && (
            (
              <SuiBox
                className="filter-box"
                sx={{
                  padding: 4,
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  overflow: 'visible',
                }}
              >
                <SuiBox className="floating-button-panel">
                  <SuiButton isLoading={isLoading} onClick={() => setToggleAdd(!toggleAdd)} variant="gradient" color="info">
                    Dodaj wóz
                    <AddIcon sx={{ marginLeft: 2 }} />
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            )
          )
        }
      </ProtectedContent>
      <SuiBox>
        <DataTable
          table={data && getTableData(data.data, refetch, setToggleEdit)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          isFetching={isFetching}
          limit={params.limit}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyVehicles;
