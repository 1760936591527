import SuiBox from 'components/SuiBox';
import moment from 'moment';
import { reportStatus } from 'utils/constants';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Raportowany dzień',
        accessor: 'date',
        sortable: true,
        key: 'reports.date',
      },
      {
        Header: 'Numer rejestracyjny',
        accessor: 'truck',
        sortable: true,
        key: 'reports.truck.number',
      },
      {
        Header: 'Rodzaj nieczystości',
        accessor: 'impuritiesType',
        sortable: true,
        key: 'reports.impuritiesType',
      },
      {
        Header: 'Status raportu',
        accessor: 'status',
        sortable: true,
        key: 'reports.status',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      redirect: `/report/${row.id}`,
      date: moment(row.date).format('YYYY-MM-DD'),
      impuritiesType: row.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe',
      status: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          {reportStatus()[row.status].icon}
          {reportStatus()[row.status].label}
        </SuiBox>
      ),
    })),
  };
  return tableData;
};

export { getTableData };
