import React, { useState } from 'react';
import PropTypes from 'prop-types';

// react-flatpickr components
import Flatpickr from 'react-flatpickr';

// react-flatpickr styles
import 'flatpickr/dist/flatpickr.css';

// Soft UI Dashboard PRO React components
import SuiInput from 'components/SuiInput';

const SuiDatePicker = ({ input, noLabel, ...rest }) => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      {
        !noLabel && (
          <label data-text={input.placeholder} htmlFor={input.name} variant="overline" className={`input-label ${focused && 'active'}`}>
            {input.placeholder}
          </label>
        )
      }
      <Flatpickr
        {...rest}
        render={({ defaultValue }, ref) => (
          <SuiInput
            {...input}
            defaultValue={defaultValue}
            inputRef={ref}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        )}
      />
    </>
  );
};

// Setting default values for the props of SuiDatePicker
SuiDatePicker.defaultProps = {
  input: {},
  noLabel: false,
};

// Typechecking props for the SuiDatePicker
SuiDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  noLabel: PropTypes.bool,
};

export default SuiDatePicker;
