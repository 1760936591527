import moment from 'moment';
import SuiBox from 'components/SuiBox';
import CircleIcon from '@mui/icons-material/Circle';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Firma asenizacyjna',
        accessor: 'company',
        sortable: true,
        key: 'name',
      },
      {
        Header: 'Status umowy',
        accessor: 'contractStatus',
        sortable: true,
        key: 'clients.contract.isActive',
      },
      {
        Header: 'Numer umowy',
        accessor: 'contractNumber',
        sortable: true,
        key: 'clients.contract.number',
      },
      {
        Header: 'Data zawarcia',
        accessor: 'date',
        sortable: true,
        key: 'clients.contract.date',
      },
      {
        Header: 'Data rozwiązania',
        accessor: 'inactiveDate',
        sortable: true,
        key: 'clients.contract.inactiveDate',
      },
    ],
    rows: results.map((row) => ({
      company: row.company,
      contractStatus: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={row.contract && row.contract.isActive ? 'success' : 'error'} />
          {
            row.contract.isActive ? 'aktywna'
              : 'rozwiązana'
          }
        </SuiBox>
      ),
      contractNumber: row.contract && row.contract.number ? row.contract.number : '-',
      date: moment(row.contract.date).format('YYYY-MM-DD'),
      inactiveDate: row.contract.inactiveDate ? moment(row.contract.inactiveDate).format('YYYY-MM-DD') : '-',
    })),
  };
  return tableData;
};

export { getTableData };
