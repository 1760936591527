/**
=========================================================
* Soft UI Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SuiButton
import SuiButtonRoot from 'components/SuiButton/SuiButtonRoot';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';

const SuiButton = forwardRef(
  ({
    isFetching,
    isLoading,
    color,
    variant,
    size,
    circular,
    iconOnly,
    children,
    disabled,
    ...rest
  }, ref) => (
    isLoading ? (
      <Skeleton height={size === 'large' ? 48 : 40} sx={{ width: '200px' }} />
    ) : (
      <SuiButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        ownerState={{
          color,
          variant,
          size,
          circular,
          iconOnly,
        }}
        disabled={isFetching || disabled}
      >
        {isFetching && <CircularProgress sx={{ marginRight: '16px' }} size={20} color={color} thickness={7} />}
        {children}
      </SuiButtonRoot>
    )),
);

// Setting default values for the props of SuiButton
SuiButton.defaultProps = {
  size: 'medium',
  variant: 'contained',
  color: 'white',
  circular: false,
  iconOnly: false,
  isLoading: false,
  isFetching: false,
  disabled: false,
};

// Typechecking props for the SuiButton
SuiButton.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
  color: PropTypes.oneOf([
    'white',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SuiButton;
