import homeRedIcon from 'assets/img/icons/homeRed.svg';
import homeGreenIcon from 'assets/img/icons/homeGreen.svg';

const homeGreen = {
  url: homeGreenIcon,
  fillColor: '#EB00FF',
  scale: 12,
};

const homeRed = {
  url: homeRedIcon,
  fillColor: '#EB00FF',
  scale: 12,
};

export { homeRed, homeGreen };
