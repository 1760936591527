import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SuiBox from 'components/SuiBox';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from 'components/DataForm/TextField';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import { useMutation } from 'react-query';
import { setMessage } from 'store';
import OrganizationAPI from 'api/organization';
import { Formik, Form } from 'formik';
import { getErrorFromApi } from 'utils/messages';
import { useNavigate } from 'react-router-dom';
import { initialValues, validationSchema } from './utils';

const CompanyAdd = () => {
  const [initValues, setInitValues] = useState(initialValues);
  const [find, setFind] = useState(undefined);
  const [loadingNip, setLoadingNip] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mutation = useMutation((formData) => OrganizationAPI.createOrganization(formData, find), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/company');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const submit = (values) => {
    mutation.mutate(values);
  };

  const findByNip = async (nip) => {
    setLoadingNip(true);
    try {
      const data = await OrganizationAPI.searchCompany({ nip });
      if (data && data.data) {
        setFind(true);
        setInitValues({
          organization: {
            id: data.data.organization._id,
            nip: data.data.organization.nip,
            licenseNumber: data.data.license ? data.data.license.licenseNumber : '',
            name: data.data.organization.name,
            address: data.data.organization.address,
            phoneNumber: data.data.organization.phoneNumber,
            type: 'company',
          },
          user: {
            name: data.data.user.name,
            surname: data.data.user.surname,
            email: data.data.user.email,
            role: 'admin',
            organizationType: 'company',
          },
        });
      } else {
        setFind(false);
        setInitValues({
          ...initialValues,
          organization: {
            ...initialValues.organization,
            nip,
          },
        });
      }
      setLoadingNip(false);
    } catch (error) {
      const errorData = error.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
      setLoadingNip(false);
    }
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', marginTop: !isMobile() && '32px' }}>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => submit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            {
              find === true && initValues.organization.licenseNumber && (
                <SuiTypography sx={{ textAlign: 'center' }} variant="subtitle2">
                  Już nadałeś koncesję dla firmy o tym numerze NIP - znajdziesz ją w zakładce Firmy asenizacyjne
                </SuiTypography>
              )
            }
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography sx={{ marginBottom: -6 }} variant="subtitle2">
                  Dane kontaktowe
                </SuiTypography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.nip',
                          placeholder: 'NIP',
                        }}
                        icon={{
                          component: loadingNip && <CircularProgress size={20} color="info" />,
                          direction: 'right',
                        }}
                        value={values.organization.nip}
                        error={errors && errors.organization && errors.organization.nip && touched.organization && touched.organization.nip}
                        onBlur={() => findByNip(values.organization.nip)}
                        handleChange={handleChange}
                        type="number"
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.nip && touched.organization && touched.organization.nip && errors.organization.nip}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={4}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.licenseNumber',
                          placeholder: 'Numer koncesji',
                        }}
                        value={values.organization.licenseNumber}
                        error={errors && errors.organization && errors.organization.licenseNumber && touched.organization && touched.organization.licenseNumber}
                        handleChange={handleChange}
                        readonly={find === undefined || (find === true && initValues.organization.licenseNumber)}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.licenseNumber && touched.organization && touched.organization.licenseNumber && errors.organization.licenseNumber}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={4}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.name',
                          placeholder: 'Nazwa firmy',
                        }}
                        value={values.organization.name}
                        error={errors && errors.organization && errors.organization.name && touched.organization && touched.organization.name}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.name && touched.organization && touched.organization.name && errors.organization.name}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.address.city',
                          placeholder: 'Miasto',
                        }}
                        value={values.organization.address.city}
                        error={errors && errors.organization && errors.organization.address && errors.organization.address.city && touched.organization && touched.organization.address && touched.organization.address.city}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.address && errors.organization.address.city && touched.organization && touched.organization.address && touched.organization.address.city && errors.organization.address.city}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.address.postCode',
                          placeholder: 'Kod pocztowy',
                        }}
                        value={values.organization.address.postCode}
                        error={errors && errors.organization && errors.organization.address && errors.organization.address.postCode && touched.organization && touched.organization.address && touched.organization.address.postCode}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.address && errors.organization.address.postCode && touched.organization && touched.organization.address && touched.organization.address.postCode && errors.organization.address.postCode}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.address.street',
                          placeholder: 'Ulica',
                        }}
                        value={values.organization.address.street}
                        error={errors && errors.organization && errors.organization.address && errors.organization.address.street && touched.organization && touched.organization.address && touched.organization.address.street}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.address && errors.organization.address.street && touched.organization && touched.organization.address && touched.organization.address.street && errors.organization.address.street}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.address.buildingNumber',
                          placeholder: 'Numer budynku',
                        }}
                        value={values.organization.address.buildingNumber}
                        error={errors && errors.organization && errors.organization.address && errors.organization.address.buildingNumber && touched.organization && touched.organization.address && touched.organization.address.buildingNumber}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.address && errors.organization.address.buildingNumber && touched.organization && touched.organization.address && touched.organization.address.buildingNumber && errors.organization.address.buildingNumber}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.address.localNumber',
                          placeholder: 'Numer lokalu',
                        }}
                        value={values.organization.address.localNumber}
                        error={errors && errors.organization && errors.organization.address && errors.organization.address.localNumber && touched.organization && touched.organization.address && touched.organization.address.localNumber}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.address && errors.organization.address.localNumber && touched.organization && touched.organization.address && touched.organization.address.localNumber}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
            <Card sx={{ marginTop: 2 }}>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography sx={{ marginBottom: -6 }} variant="subtitle2">
                  Osoba do kontaktu
                </SuiTypography>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'user.name',
                          placeholder: 'Imię',
                        }}
                        value={values.user.name}
                        error={errors && errors.user && errors.user.name && touched.user && touched.user.name}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.user && errors.user.name && touched.user && touched.user.name && errors.user.name}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'user.surname',
                          placeholder: 'Nazwisko',
                        }}
                        value={values.user.surname}
                        error={errors && errors.user && errors.user.surname && errors.user.surname && touched.user && touched.user.surname && touched.user.surname}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.user && errors.user.surname && touched.user && touched.user.surname && errors.user.surname}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'user.email',
                          placeholder: 'Email',
                        }}
                        value={values.user.email}
                        error={errors && errors.user && errors.user.email && touched.user && touched.user.email}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.user && errors.user.email && touched.user && touched.user.email && errors.user.email}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'organization.phoneNumber',
                          placeholder: 'Numer telefonu',
                        }}
                        value={values.organization.phoneNumber}
                        error={errors && errors.organization && errors.organization.phoneNumber && touched.organization && touched.organization.phoneNumber}
                        handleChange={handleChange}
                        readonly={find === undefined || find === true}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.organization && errors.organization.phoneNumber && touched.organization && touched.organization.phoneNumber && errors.organization.phoneNumber}
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
            <Grid justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
              <SuiButton
                sx={{ marginRight: 2 }}
                onClick={() => navigate('/company')}
                size="large"
                color="white"
                disabled={mutation.isLoading}
              >
                Anuluj
              </SuiButton>
              <SuiButton
                type="submit"
                size="large"
                color="info"
                variant="gradient"
                disabled={mutation.isLoading || (find === true && initValues.organization.licenseNumber)}
              >
                { mutation.isLoading && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} /> }
                Zapisz
              </SuiButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
};

export default CompanyAdd;
