/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for the SuiBadge
import SuiBadgeRoot from 'components/SuiBadge/SuiBadgeRoot';

const SuiBadge = forwardRef(
  ({
    color, variant, size, circular, indicator, border, container, children, ...rest
  }, ref) => (
    <SuiBadgeRoot
      {...rest}
      ownerState={{
        color, variant, size, circular, indicator, border, container, children,
      }}
      ref={ref}
      color="default"
    >
      {children}
    </SuiBadgeRoot>
  ),
);

// Setting default values for the props of SuiBadge
SuiBadge.defaultProps = {
  color: 'info',
  variant: 'gradient',
  size: 'sm',
  circular: false,
  indicator: false,
  border: false,
  children: false,
  container: false,
};

// Typechecking props of the SuiBadge
SuiBadge.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  variant: PropTypes.oneOf(['gradient', 'contained']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  circular: PropTypes.bool,
  indicator: PropTypes.bool,
  border: PropTypes.bool,
  children: PropTypes.node,
  container: PropTypes.bool,
};

export default SuiBadge;
