import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data odbioru',
        accessor: 'date',
        sortable: true,
        key: 'collections.date',
      },
      {
        Header: 'Pojazd odbierający',
        accessor: 'truck',
        sortable: true,
        key: 'collections.truck.number',
      },
      {
        Header: 'Klient',
        accessor: 'client',
        sortable: true,
        key: 'collections.client.address.street',
      },
      {
        Header: 'Rodzaj odebranych nieczystości',
        accessor: 'impuritiesType',
        sortable: true,
        key: 'collections.impuritiesType',
      },
      {
        Header: 'Objętość (m3)',
        accessor: 'capacity',
        sortable: true,
        key: 'collections.capacity',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      date: moment(row.date).format('YYYY-MM-DD'),
      impuritiesType: row.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe',
      client: `${row.client.address.street} ${row.client.address.buildingNumber}${row.client.address.localNumber ? `/${row.client.address.localNumber}` : ''}, ${row.client.address.community}`,
      capacity: `${row.capacity}m3`,
    })),
  };
  return tableData;
};

export { getTableData };
