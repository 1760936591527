import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

const UnprotectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const appState = useSelector((state) => state.app);

  const getRedirectUrl = (group) => {
    let url = '';
    if (location.pathname === '/') {
      switch (group) {
        case ('catchpoint'):
          url = '/report';
          break;
        case ('company'):
          url = '/collection';
          break;
        case ('community'):
          url = '/dashboard';
          break;
        default:
          url = '/';
          break;
      }
    } else {
      url = location.pathname;
    }
    return url;
  };

  useEffect(() => {
    if (appState.ACCESS_TOKEN && appState.REFRESH_TOKEN) {
      navigate(getRedirectUrl(appState.user.organizationType));
    }
  }, [appState]);

  return children;
};

UnprotectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnprotectedRoute;
