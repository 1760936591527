import React, { useState } from 'react';
import ProducerAPI from 'api/producer';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography';
import DataInfo from 'components/DataInfo';
import { getFullAddress } from 'utils/format';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import SuiButton from 'components/SuiButton';
import { getErrorFromApi } from 'utils/messages';
import { setMessage } from 'store';
import { useDispatch } from 'react-redux';
import RemoveProducerModal from './RemoveProducerModal';
import DeactivateProducerModal from './DeactivateProducerModal';

const ProducerDetailsData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const { data, isLoading, refetch } = useQuery(['producer_identification_data', id], () => ProducerAPI.getProducer(id));

  const mutation = useMutation(() => ProducerAPI.reactiveProducer(id), {
    onSuccess: (response) => {
      refetch();
      dispatch(
        setMessage({
          variant: 'success',
          title: response.data.message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  return (
    <SuiBox sx={{ width: '100%' }}>
      {
        data && data.data && (
          <RemoveProducerModal producerId={id} open={open} closeDialog={() => setOpen(false)} street={data.data.address.street} />
        )
      }
      {
        data && data.data && (
          <DeactivateProducerModal refetch={refetch} producerId={id} open={openDeactivate} closeDialog={() => setOpenDeactivate(false)} />
        )
      }
      {
        data && data.data && data.data.inactive && (
          <SuiBox sx={{
            background: 'white',
            borderRadius: 6,
            boxShadow: '0px 0px 10px 0px rgba(200, 200, 200, 1)',
            padding: 2,
            position: 'fixed',
            zIndex: 10,
            top: 120,
            right: 65,
          }}
          >
            {`Skanalizowany ${moment(data.data.connectionSewageSystemDate).format('YYYY-MM-DD')}`}
          </SuiBox>
        )
      }
      <SuiBox sx={{ marginBottom: 2 }}>
        <Card>
          <SuiBox sx={{ padding: 4 }}>
            <SuiTypography variant="subtitle2">
              Dane identyfikacyjne
            </SuiTypography>
            <Grid container spacing={2}>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Adres nieruchomości" value={data && data.data && getFullAddress(data.data.address, false)} />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo
                  isLoading={isLoading}
                  label="Rodzaj podmiotu"
                  value={data && data.data && (!data.data.producerType ? '-' : data.data.producerType === 'household' ? 'Gospodarstwo domowe' : 'Firma / Instytucja')}
                />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={2}>
                <DataInfo
                  isLoading={isLoading}
                  label="Należy do aglomeracji"
                  value={data && data.data && (!data.data.agglomeration ? 'Nie' : 'Tak')}
                />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={2}>
                <DataInfo isLoading={isLoading} label="Liczba zameldowanych" value={data && data.data && data.data.numberOfRegistered} />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={2}>
                <DataInfo isLoading={isLoading} label="Liczba zamieszkujących" value={data && data.data && data.data.numberOfInhabitants} />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox sx={{ marginBottom: 2 }}>
        <Grid container spacing={2}>
          <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={4}>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="subtitle2">
                  Dane o nieczystościach
                </SuiTypography>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Rodzaj dostarczanych nieczystości" value={data && data.data && data.data.impuritiesData.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe'} />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo
                    isLoading={isLoading}
                    label="Typ zbiornika nieczystości"
                    value={data && data.data && (!data.data.impuritiesData.impuritiesTankType ? '-' : data.data.impuritiesData.impuritiesTankType === 'drainless' ? 'Zbiornik bezodpływowy' : data.data.impuritiesData.impuritiesTankType === 'toi_toi' ? 'Toi Toi' : 'Przydomowa oczyszczalnia ścieków')}
                  />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo
                    isLoading={isLoading}
                    label="Pojemność zbiornika nieczystości"
                    value={data && data.data && (!data.data.impuritiesData.capacity ? '-' : `${data.data.impuritiesData.capacity}m3`)}
                  />
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
          <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={4}>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="subtitle2">
                  Dane o wodzie
                </SuiTypography>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo
                    isLoading={isLoading}
                    label="Sposób poboru"
                    value={data && data.data && (!data.data.waterData.collectionMethod ? '-' : data.data.waterData.collectionMethod === 'water' ? 'Przyłącze wodociągowe' : 'Studnia głębinowa')}
                  />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Liczniki główne" value={data && data.data && data.data.waterData.mainCounter.length > 0 ? data.data.waterData.mainCounter.map((item, index) => `${item.number}${index !== data.data.waterData.mainCounter.length - 1 ? ',' : ''}  `) : '-'} />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Liczniki ogrodowe" value={data && data.data && data.data.waterData.gardenCounter.length > 0 ? data.data.waterData.gardenCounter.map((item, index) => `${item.number}${index !== data.data.waterData.gardenCounter.length - 1 ? ',' : ''} `) : '-'} />
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
          <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={4}>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="subtitle2">
                  Dane kontaktowe
                </SuiTypography>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Osoba do kontaktu" value={data && data.data && data.data.contactData && (data.data.contactData.person || '-')} />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Numer telefonu" value={data && data.data && data.data.contactData && (data.data.contactData.phone || '-')} />
                </Grid>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={12}>
                  <DataInfo isLoading={isLoading} label="Adres email" value={data && data.data && data.data.contactData && (data.data.contactData.email || '-')} />
                </Grid>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      {
        (data && data.data && data.data.inactive) ? (
          <Grid justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
            <SuiButton
              ttype="submit"
              size="large"
              color="info"
              variant="gradient"
              isLoading={mutation.isLoading}
              onClick={() => mutation.mutate()}
            >
              Reaktywuj
            </SuiButton>
          </Grid>
        ) : (
          <Grid justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
            <SuiButton
              type="submit"
              size="large"
              color="white"
              onClick={() => setOpenDeactivate(true)}
              sx={{ marginRight: 2 }}
            >
              Deaktywuj
            </SuiButton>
            <SuiButton
              type="submit"
              size="large"
              color="white"
              onClick={() => setOpen(true)}
              sx={{ marginRight: 2 }}
            >
              Usuń
            </SuiButton>
            <SuiButton
              type="submit"
              size="large"
              color="info"
              variant="gradient"
              onClick={() => navigate(`/producer/${id}/edit`)}
            >
              Edytuj
            </SuiButton>
          </Grid>
        )
      }
    </SuiBox>
  );
};

export default ProducerDetailsData;
