import React from 'react';
import DataTable from 'components/DataTable';
import OrganizationAPI from 'api/organization';
import SuiInput from 'components/SuiInput';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import useDataTable from 'hooks/useDataTable';
import { useNavigate } from 'react-router-dom';
import { getTableData } from './utils';

const Organization = () => {
  const navigate = useNavigate();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, 'organization', OrganizationAPI.getOrganizations);

  return (
    <Card>
      <SuiBox sx={{
        padding: 4,
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'visible',
      }}
      >
        <SuiBox sx={{ display: 'flex' }}>
          <SuiBox sx={{ marginRight: 2 }}>
            <SuiInput
              name="search"
              placeholder="Szukaj..."
              icon={{
                component: <SearchIcon />,
                direction: 'left',
              }}
              onChange={(event) => changeParam(event.target.name, event.target.value, true)}
              isLoading={isLoading}
            />
          </SuiBox>
        </SuiBox>
        <SuiButton onClick={() => navigate('/organization/add')} variant="gradient" color="info">
          Dodaj nową organizację
        </SuiButton>
      </SuiBox>
      <SuiBox>
        <DataTable
          table={data && data.data && getTableData(data.data)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          isFetching={isFetching}
          limit={params.limit}
        />
      </SuiBox>
    </Card>
  );
};

export default Organization;
