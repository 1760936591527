import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OrganizationAPI from 'api/organization';
import ReportAPI from 'api/report';
import Grid from '@mui/material/Grid';
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import Skeleton from '@mui/material/Skeleton';
import SuiBadge from 'components/SuiBadge';
import DataInfo from 'components/DataInfo';
import Dialog from '@mui/material/Dialog';
import { getFullAddress } from 'utils/format';
import TextField from 'components/DataForm/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DataTable from 'components/DataTable';
import { setMessage } from 'store';
import moment from 'moment';
import Card from '@mui/material/Card';
import { useQuery, useMutation } from 'react-query';
import { reportStatus } from 'utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportsDetails = () => {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(['report_catchpoint_details', id], () => OrganizationAPI.reportDetails(id));

  const mutation = useMutation((reportData) => OrganizationAPI.editReport(id, reportData), {
    onSuccess: (response) => {
      const { message } = response.data;
      refetch();
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const pdfMutation = useMutation((reportData) => ReportAPI.getPdf(id, reportData), {
    onSuccess: (response) => {
      const fileURL = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: 'application/pdf' },
        ),
      );
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const fileName = response.headers['content-disposition'].split('=')[1];
      fileLink.setAttribute('download', fileName);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', paddingBottom: 4, marginTop: !isMobile() && '32px' }}>
      <SuiBox sx={{ marginBottom: 4 }}>
        <Card>
          <SuiBox
            sx={{
              padding: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {
              isLoading ? (
                <>
                  <Skeleton height={48} sx={{ marginBottom: '16px', width: '60%' }} />
                  <Skeleton height={48} sx={{ marginBottom: '16px', width: '20%' }} />
                </>
              ) : (
                <>
                  <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
                    <SuiTypography sx={{ marginRight: 4 }} variant="h3">
                      {data && data.data && data.data.truck}
                    </SuiTypography>
                    <SuiTypography sx={{ marginRight: 4 }} variant="h3">
                      {`Raport za ${data && data.data && moment(data.data.date).format('YYYY-MM-DD')}`}
                    </SuiTypography>
                    <SuiBadge
                      circular
                      size="lg"
                      container
                    >
                      {data && data.data && reportStatus()[data.data.status].icon}
                      {data && data.data && reportStatus()[data.data.status].label}
                    </SuiBadge>
                  </SuiBox>
                  <SuiBox>
                    {
                      data && data.data && (
                        <>
                          {
                            (data.data.status === 'sent' || data.data.status === 'approved') && (
                              <SuiButton
                                size="medium"
                                isFetching={mutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => setOpen(true)}
                                variant="outlined"
                                color="info"
                                sx={{ marginRight: 2 }}
                              >
                                Odrzuć raport
                              </SuiButton>
                            )
                          }
                          {
                            (data.data.status === 'sent' || data.data.status === 'rejected') && (
                              <SuiButton
                                size="medium"
                                isFetching={mutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => mutation.mutate({ data: { status: 'approved', organization: data.data.organization } })}
                                variant="gradient"
                                color="info"
                              >
                                Zatwierdź raport
                              </SuiButton>
                            )
                          }
                          {
                            data.data.status === 'approved' && (
                              <SuiButton
                                size="medium"
                                isFetching={mutation.isLoading || pdfMutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => pdfMutation.mutate(data.data)}
                                variant="gradient"
                                color="info"
                              >
                                Drukuj raport
                              </SuiButton>
                            )
                          }
                        </>
                      )
                    }
                  </SuiBox>
                </>
              )
            }
          </SuiBox>
        </Card>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Nazwa firmy"
                value={data && data.data && data.data.companyName}
              />
            </Grid>
            <Grid item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Adres siedziby"
                value={data && data.data && data.data.company && getFullAddress(data.data.company.address)}
              />
            </Grid>
            <Grid item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Numer rej. pojazdu"
                value={data && data.data && data.data.truck}
              />
            </Grid>
            <Grid item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Rodzaj nieczystości"
                value={data && data.data && data.data.impuritiesType && data.data.impuritiesType === 'household' ? 'bytowe' : 'przemysłowe'}
              />
            </Grid>
            <Grid item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Data złożenia raportu"
                value={data && data.data && data.data.sentDate ? moment(data.data.sentDate).format('YYYY-MM-DD') : '-'}
              />
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={4}>
              <DataInfo
                isLoading={isLoading}
                label="Data zatwierdzenia raportu"
                value={data && data.data && data.data.acceptedDate ? moment(data.data.acceptedDate).format('YYYY-MM-DD') : '-'}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <SuiBox sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  isLoading={isLoading}
                  label="Łączna objętość nieczystości odebranych"
                  value={data && data.data && `${data.data.collectionsCapacitySum}m3`}
                />
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  isLoading={isLoading}
                  label="Łączna objętość nieczystości zrzuconych"
                  value={data && data.data && `${data.data.dumpsCapacitySum}m3`}
                />
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  withBalance
                  isLoading={isLoading}
                  label="Bilans"
                  value={data && data.data && `${data.data.balance}%`}
                />
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="h4">
                  Rejestr odbiorów
                </SuiTypography>
                {
                  data && data.data && (
                    <DataTable
                      table={{
                        columns: [
                          {
                            Header: 'Data odbioru',
                            accessor: 'date',
                          },
                          {
                            Header: 'Objętość',
                            accessor: 'capacity',
                          },
                          {
                            Header: 'Klient',
                            accessor: 'client',
                            width: '50%',
                          },
                        ],
                        rows: data.data.collections.map((row) => ({
                          date: moment(row.date).format('YYYY-MM-DD'),
                          capacity: `${row.capacity}m3`,
                          client: getFullAddress(row.client.address),
                        })),
                      }}
                      loading={isLoading}
                      paginationPage={0}
                      sortPage=""
                      changeParam={() => null}
                      count={100}
                      isLoading={isLoading}
                      isFetching={isLoading}
                      limit={100}
                    />
                  )
                }
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="h4">
                  Rejestr zrzutów
                </SuiTypography>
                {
                  data && data.data && (
                    <DataTable
                      table={{
                        columns: [
                          {
                            Header: 'Data zrzutu',
                            accessor: 'date',
                          },
                          {
                            Header: 'Objętość',
                            accessor: 'capacity',
                          },
                        ],
                        rows: data.data.dumps.map((row) => ({
                          date: moment(row.date).format('YYYY-MM-DD'),
                          capacity: `${row.capacity}m3`,
                        })),
                      }}
                      loading={isLoading}
                      paginationPage={0}
                      sortPage=""
                      changeParam={() => null}
                      count={100}
                      isLoading={isLoading}
                      isFetching={isLoading}
                      limit={100}
                    />
                  )
                }
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <SuiBox sx={{ padding: 2, width: '680px' }}>
          <DialogTitle>Podaj powód odrzucenia raportu</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <TextField
                field={{
                  name: 'none',
                }}
                onBlur={(event) => setNote(event.target.value)}
                multiline
                rows={5}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <SuiButton
              size="small"
              onClick={() => setOpen(false)}
              color="info"
              variant="outlined"
              isFetching={mutation.isLoading}
            >
              Anuluj
            </SuiButton>
            <SuiButton
              size="small"
              onClick={() => {
                mutation.mutate({ data: { status: 'rejected', note, organization: data.data.organization } });
                setOpen(false);
                setNote('');
              }}
              variant="gradient"
              color="info"
              isFetching={mutation.isLoading}
              disabled={note === ''}
            >
              Zapisz
            </SuiButton>
          </DialogActions>
        </SuiBox>
      </Dialog>
    </SuiBox>
  );
};

export default ReportsDetails;
