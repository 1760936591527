import CircleIcon from '@mui/icons-material/Circle';
import SuiBox from 'components/SuiBox';
import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Adres nieruchomości',
        accessor: 'address',
        key: 'clients.address.street',
        sortable: true,
      },
      {
        Header: 'Status umowy',
        accessor: 'status',
        key: 'clients.contract.isActive',
        sortable: true,
      },
      {
        Header: 'Nr umowy',
        accessor: 'licenseNumber',
        key: 'clients.contract.date',
        sortable: true,
      },
      {
        Header: 'Data zawarcia',
        accessor: 'date',
        key: 'clients.contract.inactiveDate',
        sortable: true,
      },
      {
        Header: 'Data rozwiązania',
        accessor: 'inactiveDate',
        key: 'clients.contract.number',
        sortable: true,
      },
      {
        Header: 'Rodzaj nieczystości',
        accessor: 'impuritiesType',
        key: 'clients.contract.impuritiesType',
        sortable: true,
      },
    ],
    rows: results.map((row) => ({
      address: `${row.address.street} ${row.address.buildingNumber}${row.address.localNumber ? `/${row.address.localNumber}` : ''}`,
      licenseNumber: row.licenseNumber || '-',
      date: row.contractDate ? moment(row.contractDate).format('YYYY-MM-DD') : '-',
      inactiveDate: row.contractInactiveDate ? moment(row.contractInactiveDate).format('YYYY-MM-DD') : '-',
      impuritiesType: !row.impuritiesType ? '-' : row.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe',
      status: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={row.contract ? 'success' : 'error'} />
          {
            row.contract ? 'aktywna'
              : 'rozwiązana'
          }
        </SuiBox>
      ),
    })),
  };
  return tableData;
};

export { getTableData };
