import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import clusterIcon from 'assets/img/icons/cluster/blue/m1.png';
import clusterIconRed from 'assets/img/icons/cluster/red/m1.png';
import { Link } from 'react-router-dom';

const containerStyle = {
  width: '100%',
  height: '600px',
};

const center = {
  lat: 52.530088,
  lng: 17.6010273,
};

const Map = ({ markers, isLoading, alertPage }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC1S85e6s7lOvymBX2avK9J4OlrAx_feZM',
  });
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return isLoaded && !isLoading ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      options={{
        styles: [
          {
            elementType: 'labels',
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
        streetViewControl: false,
      }}
    >
      <MarkerClusterer
        styles={[
          {
            url: alertPage ? clusterIconRed : clusterIcon,
            height: 64,
            width: 64,
            textColor: '#FFF',
          },
        ]}
      >
        {
          (clusterer) => markers.map((item) => item && item.address.position.length > 0
          && (
            <Marker
              icon={item.icon}
              clusterer={clusterer}
              key={item.id}
              position={{ lat: parseFloat(item.address.position[0]), lng: parseFloat(item.address.position[1]) }}
              onClick={() => handleActiveMarker(item.id)}
            >
              {
                activeMarker === item.id && (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)} position={{ lat: parseFloat(item.address.position[0]), lng: parseFloat(item.address.position[1]) }}>
                    <div>
                      <h4 style={{ marginBottom: '16px' }}>{`${item.address.street} ${item.address.buildingNumber}${item.address.localNumber ? `/${item.address.localNumber}` : ''}, ${item.address.town}`}</h4>
                      {
                        item.reason && (
                          <p style={{ marginBottom: '16px' }}>{item.reason}</p>
                        )
                      }
                      {
                        item.id && (
                          <Link className="underline" to={`/producer/${item.id}/details`}>
                            NAWIGUJ DO PRODUCENTA
                          </Link>
                        )
                      }
                    </div>
                  </InfoWindow>
                )
              }
            </Marker>
          ))
        }
      </MarkerClusterer>
    </GoogleMap>
  ) : <Skeleton height={600} />;
};

Map.defaultProps = {
  markers: [],
  isLoading: false,
  alertPage: false,
};

Map.propTypes = {
  markers: PropTypes.array,
  isLoading: PropTypes.bool,
  alertPage: PropTypes.bool,
};

export default Map;
