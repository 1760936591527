import React, { useState, useEffect } from 'react';
import SuiBox from 'components/SuiBox';
import AppBar from '@mui/material/AppBar';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { setAlertsStore, setRefetchAlerts } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import ProducerAPI from 'api/producer';
import { useQuery } from 'react-query';
import ProtectedContent from 'guard/ProtectedContent';
import Tab from '@mui/material/Tab';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { subPages } from './utils';

const ProducerDetails = () => {
  const refetchAlerts = useSelector((state) => state.app.refetchAlerts);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(subPages.findIndex((item) => params['*'] === item.route));
  const handleSetMenu = (event, newMenu) => {
    setMenu(newMenu);
    navigate(`/producer/${params.id}/${subPages[newMenu].route}`);
  };

  const [alerts, setAlerts] = useState({
    NEW_PRODUCER: null,
    BALANCE: null,
    COLLECTIONS_FREQUENCY: null,
  });

  useEffect(() => {
    return () => {
      dispatch(setAlertsStore([]));
    };
  }, []);

  const { refetch } = useQuery(['producer_alerts', params.id], () => ProducerAPI.getAlerts(params.id), {
    onSuccess: (response) => {
      if (response.data) {
        const producerAlerts = {
          NEW_PRODUCER: response.data.find((item) => item.alertType === 'NEW_PRODUCER'),
          BALANCE: response.data.find((item) => item.alertType === 'BALANCE'),
          COLLECTIONS_FREQUENCY: response.data.find((item) => item.alertType === 'COLLECTIONS_FREQUENCY'),
          NO_CONTRACT: response.data.find((item) => item.alertType === 'NO_CONTRACT'),
        };
        setAlerts(producerAlerts);
        dispatch(setAlertsStore([producerAlerts.NEW_PRODUCER, producerAlerts.BALANCE, producerAlerts.COLLECTIONS_FREQUENCY, producerAlerts.NO_CONTRACT]));
      }
    },
  });

  useEffect(() => {
    if (refetchAlerts) {
      refetch();
      dispatch(setRefetchAlerts(false));
    }
  }, [refetchAlerts]);

  const renderIcon = (index) => {
    let render = false;
    if (index === 0 && alerts.NEW_PRODUCER) {
      render = alerts.NEW_PRODUCER;
    }
    if (index === 1 && alerts.COLLECTIONS_FREQUENCY) {
      render = alerts.COLLECTIONS_FREQUENCY;
    }
    if (index === 2 && alerts.NO_CONTRACT) {
      render = alerts.NO_CONTRACT;
    }
    if (index === 4 && alerts.BALANCE) {
      render = alerts.BALANCE;
    }
    return render;
  };

  return (
    <SuiBox>
      <SuiBox marginBottom={3} marginTop={3}>
        <AppBar position="static">
          <Tabs orientation="horizontal" value={menu} onChange={handleSetMenu}>
            {
              subPages.map((page, index) => (
                <ProtectedContent userRole={page.role} organizationType={page.organizationType}>
                  <Tab
                    icon={renderIcon(index) && (
                      <Tooltip title={renderIcon(index).description} placement="top">
                        <WarningAmberOutlinedIcon color="error" />
                      </Tooltip>
                    )}
                    iconPosition="end"
                    onClick={(event) => handleSetMenu(event, index)}
                    key={page.route}
                    label={page.label}
                  />
                </ProtectedContent>
              ))
            }
          </Tabs>
        </AppBar>
      </SuiBox>
      <SuiBox>
        <Outlet />
      </SuiBox>
    </SuiBox>
  );
};

export default ProducerDetails;
