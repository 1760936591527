import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuiInput from 'components/SuiInput';

const TextField = ({
  field,
  handleChange,
  value,
  error,
  readonly,
  onClick,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <>
      <label data-text={field.placeholder} htmlFor={field.name} variant="overline" className={`input-label ${focused && 'active'}`}>{field.placeholder}</label>
      <SuiInput
        icon={
          field.icon && {
            component: field.icon,
            direction: 'left',
          }
        }
        size="large"
        type={field.type || 'text'}
        placeholder={field.placeholder}
        name={field.name}
        onChange={handleChange}
        value={value}
        error={error}
        disabled={readonly}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onClick={onClick}
        {...rest}
      />
    </>
  );
};

TextField.defaultProps = {
  error: false,
  readonly: false,
  onClick: null,
};

TextField.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  readonly: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TextField;
