import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = {
  ACCESS_TOKEN: null,
  REFRESH_TOKEN: null,
  EXPIRATION_TIME: null,
  user: null,
  message: null,
  loadingApp: true,
  companies: [],
  alerts: [],
  refetchAlerts: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const decodedToken = jwtDecode(action.payload.ACCESS_TOKEN);
      const expirationTime = decodedToken.exp * 1000;
      state.ACCESS_TOKEN = action.payload.ACCESS_TOKEN;
      state.REFRESH_TOKEN = action.payload.REFRESH_TOKEN;
      state.EXPIRATION_TIME = expirationTime;
      state.user = {
        organizationType: decodedToken.organizationType,
        email: decodedToken.email,
        name: decodedToken.name,
        surname: decodedToken.surname,
        role: decodedToken.role,
        territorial_unit: decodedToken.territorial_unit,
      };
      state.loadingApp = false;
      Cookies.set('REFRESH_TOKEN', action.payload.REFRESH_TOKEN);
      Cookies.set('ACCESS_TOKEN', action.payload.ACCESS_TOKEN);
    },
    destroyTokens: (state) => {
      state.ACCESS_TOKEN = null;
      state.REFRESH_TOKEN = null;
      state.EXPIRATION_TIME = null;
      state.user = null;
      state.loadingApp = false;
      Cookies.remove('REFRESH_TOKEN');
      Cookies.remove('ACCESS_TOKEN');
      Cookies.remove('LAST_ACTIVITY');
    },
    setMessage: (state, action) => {
      state.message = {
        variant: action.payload.variant,
        title: action.payload.title,
      };
      setTimeout(() => {
        store.dispatch(appSlice.actions.clearMessage());
      }, 5000);
    },
    clearMessage: (state) => {
      state.message = null;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload.data;
    },
    setAlertsStore: (state, action) => {
      state.alerts = action.payload;
    },
    setRefetchAlerts: (state, action) => {
      state.refetchAlerts = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
});

export const {
  setUserData,
  destroyTokens,
  setMessage,
  setCompanies,
  setAlertsStore,
  setRefetchAlerts,
} = appSlice.actions;

export default store;
