import PropTypes from 'prop-types';
import List from '@mui/material/List';

const SidenavList = ({ children }) => (
  <List
    sx={{
      pl: 2,
      ml: 3,
    }}
  >
    {children}
  </List>
);

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidenavList;
