import Swal from 'sweetalert2';

const confirmModal = (CONFIG) => Swal.mixin({
  customClass: {
    confirmButton: 'button button-success',
    cancelButton: 'button button-error',
  },
}).fire({
  title: CONFIG.title,
  icon: 'question',
  showConfirmButton: true,
  showCancelButton: true,
  confirmButtonText: CONFIG.confirmButtonText,
  cancelButtonText: CONFIG.cancelButtonText,
}).then(async (result) => {
  if (result.isConfirmed) {
    try {
      await CONFIG.action();
      Swal.fire(CONFIG.successText, '', 'success');
    } catch (err) {
      Swal.fire(CONFIG.errorText, '', 'error');
    }
  }
});

export default confirmModal;
