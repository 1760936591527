/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import colors from 'assets/theme/base/colors';

const { info, dark } = colors;

export default {
  html: {
    scrollBehavior: 'smooth',
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
  },
  '.leaflet-container': {
    height: '100%',
    minHeight: 500,
    borderRadius: '1rem',
  },
  a: {
    color: info.main,
    position: 'relative',
  },
  'a.underline:after': {
    position: 'absolute',
    content: '""',
    width: '0%',
    height: '1px',
    background: info.main,
    margin: 'auto',
    left: 0,
    right: 0,
    bottom: '-5px',
    transition: '.3s',
  },
  'a.underline:hover:after': {
    width: '100%',
  },
  '.swal2-icon.swal2-question': {
    borderColor: `${info.main} !important`,
    color: `${info.focus} !important`,
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important',
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${dark.main} !important`,
    transition: 'color 150ms ease-in !important',
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${info.main} !important`,
  },
  'table td > div': {
    width: '100% !important',
  },
  '#popover .MuiPaper-root': {
    background: 'white',
  },
  '.MuiSkeleton-root': {
    borderRadius: '0.5rem !important',
    transform: 'scale(1) !important',
  },
  '.MuiPaper-root': {
    overflow: 'visible !important',
  },
  '.main-content': {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    padding: '0 24px 32px 24px !important',
  },
  '.main-content::-webkit-scrollbar': {
    display: 'none',
  },
  '.MuiPopover-paper': {
    padding: '20px !important',
    background: 'white !important',
  },
  '.overflow-hidden': {
    overflow: 'hidden !important',
  },
  '.custom-select div[class$="rmc-menu"]': {
    zIndex: 100,
  },
  '.disabled-select > div': {
    backgroundColor: '#e9ecef',
  },
  '.logo-spinner': {
    width: 100,
    animation: 'spin 1s linear infinite',
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '.simple-table td, .simple-table th': {
    padding: '12px 24px',
    textAlign: 'left',
  },
  '.login-logo': {
    width: 200,
  },
  '.glass': {
    backdropFilter: 'blur(5.7px)',
    backgroundColor: 'rgba(255,255,255,.2) !important',
  },
  '.brand-logo': {
    transition: '.3s',
    transform: 'rotate(0)',
  },
  '.brand-logo.roll': {
    transform: 'rotate(-180deg)',
  },
  '.sticky': {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF !important',
    zIndex: 1000,
    borderRadius: 15,
  },
  '.MuiTableContainer-root': {
    height: '100%',
  },
  '.sticky-table': {
    height: '100%',
  },
  '.sticky-table > .MuiBox-root': {
    height: '100%',
  },
  '.sticky-table .pagination-box': {
    backgroundColor: 'white',
    position: 'sticky',
    bottom: 0,
  },
  '.MuiTabs-root': {
    backgroundColor: 'transparent !important',
  },
  'tr.MuiTableRow-hover:hover': {
    backgroundColor: 'rgba(195, 211, 56, 0.2) !important',
  },
  '.radio-control': {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center',
  },
  '.radio-control .Mui-focused': {
    color: '#35d1f5 !important',
  },
  '.radio-group': {
    marginLeft: 40,
  },
  '.radio-label': {
    marginLeft: '20px !important',
  },
  'form .MuiGrid-root .MuiTypography-caption': {
    position: 'absolute !important',
  },
  'form .MuiGrid-root': {
    paddingTop: '30px !important',
    position: 'relative',
  },
  '.input-label': {
    fontWeight: '600 !important',
    position: 'absolute',
    top: 10,
    fontSize: '.75rem',
    transition: '.3s',
    '&.active': {
      background: 'linear-gradient(90deg, #C3D338 0%, #23B3A0 50%, #247EAF 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  '.react-tag-input__tag': {
    background: 'linear-gradient(90deg, #C3D338 0%, #23B3A0 50%, #247EAF 100%)',
  },
  '.react-tag-input__tag__remove': {
    backgroundColor: 'transparent !important',
  },
  '.submenu-tab': {
    minWidth: '300px !important',
  },
  '.MuiBadge-root': {
    backgroundColor: 'white',
    padding: '8px 32px',
    borderRadius: '324px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0rem 0rem 1.6875rem 0rem rgb(0 0 0 / 14%)',
    justifyContent: 'center',
  },
  '.error-card': {
    border: '2px solid red !important',
  },
  '.vehicle-form button': {
    marginTop: '-40px',
  },
  '.MuiBadge-badge': {
    display: 'none !important',
  },
  '.MuiDialog-paperScrollPaper': {
    maxWidth: '800px !important',
  },
  '.cluster-with-alert': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'rgba(239,48,56,.8)',
    boxShadow: '0px 0px 12px 4px rgb(239,48,56)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
  '.cluster-without-alert': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'rgba(60,179,113,.8)',
    boxShadow: '0px 0px 12px 4px rgb(60,179,113)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
  'body.swal2-toast-shown .swal2-container': {
    width: '600px !important',
  },
  '.listItem': {
    cursor: 'pointer',
    padding: '8px 16px !important',
    borderTop: '1px solid #e9ecef',
  },
  '.listItem:last-child': {
    borderBottom: '1px solid #e9ecef',
  },
  '.listItem:hover': {
    backgroundColor: 'rgba(195, 211, 56, 0.2)',
  },
  '.MuiInputBase-root': {
    width: '100%',
  },
  '.toggle-menu-mobile': {
    float: 'right',
    marginRight: '24px',
    marginBottom: '20px',
    display: 'none !important',
    zIndex: 100,
    position: 'relative',
  },
  '@media screen and (max-width: 768px)': {
    '.logo-container, .toggle-menu-desktop': {
      display: 'none',
    },
    '.filter-box': {
      flexDirection: 'column',
      padding: '0px !important',
      marginBottom: '8px',
      gap: '8px',
    },
    '.filter-box div': {
      margin: 0,
    },
    '.main-content': {
      padding: '0 16px 50px 16px !important',
      height: 'calc(100vh - 120px) !important',
      marginBottom: '120px',
    },
    '.add-collection-labels, .add-new-collection': {
      display: 'none !important',
    },
    '.add-collection-buttons': {
      justifyContent: 'center !important',
      flexDirection: 'column-reverse !important',
      gap: '8px',
      width: '100% !important',
      margin: '0px !important',
    },
    '.add-collection-buttons button': {
      margin: 0,
      width: '100%',
    },
    '.add-collection': {
      padding: '16px !important',
    },
    '.MuiBadge-root': {
      padding: '8px !important',
    },
    '.MuiBadge-root svg': {
      margin: 0,
    },
    '.report-subheader': {
      justifyContent: 'space-between',
      width: '100%',
    },
    '.toggle-menu-mobile': {
      display: 'flex !important',
    },
    '.floating-button-panel': {
      position: 'fixed',
      width: '100%',
      padding: '16px',
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: '1000',
      background: 'rgba(255,255,255,.7) !important',
    },
    'input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '.login-logo': {
      width: 70,
      height: 70,
    },
    '.login-card': {
      paddingTop: '0px !important',
    },
    '.login-card-top': {
      paddingBottom: '0px !important',
    },
    '.menu-divider': {
      display: 'none',
    },
    '.MuiPopover-paper': {
      width: '100%',
      top: '70px !important',
    },
    '.MuiPopover-paper .MuiBox-root': {
      width: '100% !important',
    },
    '.MuiLinearProgress-root': {
      width: '170px !important',
    },
  },
};
