import React, { useState, useRef, useEffect } from 'react';
import SuiBox from 'components/SuiBox';
import PropTypes from 'prop-types';
import SuiTagInput from 'components/SuiTagInput';

const TagField = ({
  field,
  handleChange,
  value,
  error,
  readonly,
  onClick,
}) => {
  const [focused, setFocused] = useState(false);
  const [tags, setTags] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.inputRef.current.addEventListener('blur', () => {
        setFocused(false);
      });
      inputRef.current.inputRef.current.addEventListener('focus', () => {
        setFocused(true);
      });
      return () => {
        inputRef.current.inputRef.current.removeEventListener('blur');
        inputRef.current.inputRef.current.removeEventListener('focus');
      };
    }
  }, []);

  useEffect(() => {
    if (focused === false && inputRef && inputRef.current.inputRef.current.value) {
      inputRef.current.addTag(inputRef.current.inputRef.current.value);
    }
  }, [focused]);

  const updateTags = (newTags) => {
    setTags(newTags);
    handleChange({ target: { value: newTags, name: field.name } });
  };

  return (
    <SuiBox>
      <label data-text={field.placeholder} htmlFor={field.name} variant="overline" className={`input-label ${focused && 'active'}`}>{field.placeholder}</label>
      <SuiTagInput
        ref={inputRef}
        icon={
          field.icon && {
            component: field.icon,
            direction: 'left',
          }
        }
        size="large"
        type={field.type || 'text'}
        placeholder={field.placeholder}
        name={field.name}
        onChange={updateTags}
        tags={tags}
        error={error}
        onClick={onClick}
      />
      {
        readonly && (
          <SuiBox sx={{
            position: 'absolute',
            bottom: 1,
            left: 1,
            width: 'calc(100% - 2px)',
            height: '46px',
            background: 'rgba(233,236,239)',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <SuiBox sx={{
              color: '#344767',
              opacity: '.5',
              padding: '0 0.75rem',
              fontSize: '.875rem',
            }}
            >
              {field.placeholder}
            </SuiBox>
          </SuiBox>
        )
      }
    </SuiBox>
  );
};

TagField.defaultProps = {
  error: false,
  readonly: false,
  onClick: null,
};

TagField.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  readonly: PropTypes.bool,
  onClick: PropTypes.func,
};

export default TagField;
