import React from 'react';
import DataTable from 'components/DataTable';
import SuiInput from 'components/SuiInput';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import SearchIcon from '@mui/icons-material/Search';
import useDataTable from 'hooks/useDataTable';
import ProtectedContent from 'guard/ProtectedContent';
import SuiButton from 'components/SuiButton';
import AddIcon from '@mui/icons-material/Add';
import SuiBox from 'components/SuiBox';
import {
  useNavigate,
} from 'react-router-dom';
import OrganizationAPI from 'api/organization';
import { getTableData } from './utils';

const Company = () => {
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
    refetch,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, 'organization_companies', OrganizationAPI.getCompanies);
  const navigate = useNavigate();
  const appState = useSelector((state) => state.app);

  return (
    <SuiBox>
      <SuiBox sx={{ padding: 4, display: 'flex', justifyContent: 'space-between' }}>
        <SuiBox sx={{ display: 'flex' }}>
          <SuiInput
            name="search"
            placeholder="Szukaj..."
            icon={{
              component: <SearchIcon />,
              direction: 'left',
            }}
            onChange={(event) => changeParam(event.target.name, event.target.value, true)}
            isLoading={isLoading}
          />
        </SuiBox>
        <ProtectedContent userRole={['admin', 'editor']} organizationType={['community']}>
          <SuiButton isLoading={isLoading} onClick={() => navigate('/company/add')} variant="gradient" color="info">
            Dodaj firmę
            <AddIcon sx={{ marginLeft: 2 }} />
          </SuiButton>
        </ProtectedContent>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <DataTable
            table={data && getTableData(data.data, appState.user, refetch)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default Company;
