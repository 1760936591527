/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for SuiTypography
import SuiTypographyRoot from 'components/SuiTypography/SuiTypographyRoot';
import Skeleton from '@mui/material/Skeleton';

const HEIGHTS = {
  h1: '66px',
  h2: '52px',
  h3: '49px',
  h4: '39px',
  h5: '33px',
  h6: '32px',
  subtitle1: '38px',
  subtitle2: '31px',
  body1: '38px',
  body2: '31px',
  button: '27px',
  caption: '21px',
  overline: '37px',
  inherit: '31px',
};

const SuiTypography = forwardRef(
  (
    {
      color, fontWeight, textTransform, verticalAlign, textGradient, opacity, children, variant, isLoading, ...rest
    },
    ref,
  ) => (
    isLoading ? <Skeleton sx={{ width: '100%', height: HEIGHTS[variant], marginRight: 2 }} />
      : (
        <SuiTypographyRoot
          {...rest}
          ref={ref}
          ownerState={{
            color, textTransform, verticalAlign, fontWeight, opacity, textGradient,
          }}
          variant={variant}
        >
          {children}
        </SuiTypographyRoot>
      )
  ),
);

// Setting default values for the props of SuiTypography
SuiTypography.defaultProps = {
  color: 'dark',
  fontWeight: false,
  textTransform: 'none',
  verticalAlign: 'unset',
  textGradient: false,
  opacity: 1,
  isLoading: false,
  children: '',
};

// Typechecking props for the SuiTypography
SuiTypography.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'white',
  ]),
  fontWeight: PropTypes.oneOf([false, 'light', 'regular', 'medium', 'bold']),
  textTransform: PropTypes.oneOf(['none', 'capitalize', 'uppercase', 'lowercase']),
  verticalAlign: PropTypes.oneOf([
    'unset',
    'baseline',
    'sub',
    'super',
    'text-top',
    'text-bottom',
    'middle',
    'top',
    'bottom',
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node,
  opacity: PropTypes.number,
  variant: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default SuiTypography;
