import CircleIcon from '@mui/icons-material/Circle';
import SuiBox from 'components/SuiBox';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Adres nieruchomości',
        accessor: 'address',
        key: 'clients.address.street',
        sortable: true,
      },
      {
        Header: 'Osoba kontaktowa',
        accessor: 'person',
        key: 'clients.contact.person',
        sortable: true,
      },
      {
        Header: 'Numer kontaktowy',
        accessor: 'phone',
        key: 'clients.contact.phone',
        sortable: true,
      },
      {
        Header: 'Status umowy',
        accessor: 'status',
        key: 'clients.contract.isActive',
        sortable: true,
      },
    ],
    rows: results.map((row) => ({
      redirect: `/client/${row.id}/details`,
      address: `${row.address.street} ${row.address.buildingNumber}${row.address.localNumber ? `/${row.address.localNumber}` : ''}, ${row.address.town} `,
      person: row.name || '-',
      phone: row.phone || '-',
      status: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={row.contract ? 'success' : 'error'} />
          {
            row.contract ? 'aktywna'
              : 'rozwiązana'
          }
        </SuiBox>
      ),
    })),
  };
  return tableData;
};

export { getTableData };
