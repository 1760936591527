import React, { useState, useEffect } from 'react';
import DataTable from 'components/DataTable';
import SuiButton from 'components/SuiButton';
import SuiInput from 'components/SuiInput';
import SearchIcon from '@mui/icons-material/Search';
import SuiTypography from 'components/SuiTypography';
import useDataTable from 'hooks/useDataTable';
import { useQuery } from 'react-query';
import Switch from '@mui/material/Switch';
import Card from '@mui/material/Card';
import Map from 'components/Map';
import SuiBox from 'components/SuiBox';
import ProducerAPI from 'api/producer';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { getTableData, getMapData } from './utils';

let oldParams = { search: '', alert: false };

const Producer = () => {
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    alert: false,
    page: 1,
    sort: '',
  }, 'producer', ProducerAPI.getAll);
  const [menu, setMenu] = useState(1);
  const navigate = useNavigate();
  const mapQuery = useQuery(
    'producer_map',
    () => ProducerAPI.getAllProducers({ alert: params.alert, search: params.search }),
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  const handleSetMenu = (event, newMenu) => setMenu(newMenu);

  useEffect(() => {
    if (oldParams.search !== params.search || oldParams.alert !== params.alert) {
      mapQuery.refetch();
    }
    oldParams = params;
  }, [params]);

  return (
    <SuiBox>
      <SuiBox sx={{ padding: 4, display: 'flex', justifyContent: 'space-between' }}>
        <SuiBox sx={{ display: 'flex' }}>
          <SuiInput
            name="search"
            placeholder="Szukaj..."
            icon={{
              component: <SearchIcon />,
              direction: 'left',
            }}
            onChange={(event) => changeParam(event.target.name, event.target.value, true)}
            isLoading={isLoading}
          />
          <SuiBox sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <SuiTypography sx={{ display: 'block', marginRight: 1 }} variant="h6">
              Pokaż tylko z alertem
            </SuiTypography>
            <Switch name="alert" checked={params.checked} onChange={(event) => changeParam(event.target.name, event.target.checked, false)} />
          </SuiBox>
          <SuiBox sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <SuiTypography sx={{ display: 'block', marginRight: 1 }} variant="h6">
              Pokaż deaktywowanych
            </SuiTypography>
            <Switch name="deactivated" checked={params.checked} onChange={(event) => changeParam(event.target.name, event.target.checked, false)} />
          </SuiBox>
        </SuiBox>
        <SuiBox sx={{ display: 'flex' }}>
          <AppBar sx={{ width: '400px' }} position="static">
            <Tabs orientation="horizontal" value={menu} onChange={handleSetMenu}>
              <Tab label="Widok mapy" />
              <Tab label="Widok listy" />
            </Tabs>
          </AppBar>
          <SuiButton sx={{ marginLeft: 2 }} isLoading={isLoading} onClick={() => navigate('/producer/add')} variant="gradient" color="info">
            Dodaj producenta
            <AddIcon sx={{ marginLeft: 2 }} />
          </SuiButton>
        </SuiBox>
      </SuiBox>
      {
        menu === 0 ? (
          <Card>
            <SuiBox>
              <Map cluster markers={mapQuery.data && getMapData(mapQuery.data)} isLoading={mapQuery.isLoading || mapQuery.isFetching} />
            </SuiBox>
          </Card>
        ) : (
          <Card>
            <SuiBox sx={{ padding: 4 }}>
              <DataTable
                table={data && getTableData(data.data)}
                loading={isLoading}
                paginationPage={params.page}
                sortPage={params.sort}
                changeParam={changeParam}
                count={count}
                isLoading={isLoading}
                isFetching={isFetching}
                limit={params.limit}
              />
            </SuiBox>
          </Card>
        )
      }
    </SuiBox>
  );
};

export default Producer;
