import { Link, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
// import HomeIcon from '@mui/icons-material/Home';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import routes from 'routes';

const Breadcrumbs = ({ route, light }) => {
  const getRouteData = (index) => {
    let foundRoute;
    let routeToCheck = '';
    if (index === 0) {
      routeToCheck = `/${route[index]}`;
    } else if (index === 1) {
      routeToCheck = `/${route[index - 1]}/${route[index]}`;
    } else if (index === 2) {
      routeToCheck = `/${route[index]}`;
    }
    routes.forEach((item) => {
      if (item.type !== 'divider') {
        if (!foundRoute) {
          if (index < 2) {
            foundRoute = matchPath({ path: item.route }, routeToCheck) ? item : null;
          } else if (item.children) {
            const parentRoute = matchPath({ path: item.route }, `/${route[index - 2]}/${route[index - 1]}`) ? item : null;
            if (parentRoute) {
              item.children.forEach((it) => {
                if (!foundRoute) {
                  foundRoute = matchPath({ path: `/${it.route}` }, routeToCheck) ? it : null;
                }
              });
            }
          }
        }
      }
    });
    if (foundRoute) foundRoute.link = routeToCheck;
    return foundRoute;
  };

  return (
    <SuiBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        {route.map((el, index) => (
          getRouteData(index)
          && (
            <Link to={getRouteData(index).link} key={el}>
              <SuiTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}
              >
                {getRouteData(index).name}
              </SuiTypography>
            </Link>
          )
        ))}
      </MuiBreadcrumbs>
    </SuiBox>
  );
};

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
