import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SuiBox from 'components/SuiBox';
import { useSoftUIController, setLayout } from 'context';

const DashboardLayout = ({ children }) => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname]);

  return (
    <SuiBox
      className="main-content"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',
        height: 'calc(100vh - 206px)',
        overflowY: 'auto',
        padding: '0 24px 32px 24px',
        margin: '0 0 24px 0',
        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(0) : pxToRem(0),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </SuiBox>
  );
};

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
