import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SuiBox from 'components/SuiBox';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from 'components/DataForm/TextField';
import SelectField from 'components/DataForm/SelectField';
import SoftDatePicker from 'components/SuiDatePicker';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import Popover from '@mui/material/Popover';
import CalendarToday from '@mui/icons-material/CalendarToday';
import OrganizationAPI from 'api/organization';
import { useQuery, useMutation } from 'react-query';
import moment from 'moment';
import { setMessage } from 'store';
import CompanyAPI from 'api/company';
import { Formik, Form } from 'formik';
import { getErrorFromApi } from 'utils/messages';
import SearchIcon from '@mui/icons-material/Search';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { useNavigate } from 'react-router-dom';
import { initialValues, validationSchema } from './utils';

const CompanyClientsAdd = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, isLoading } = useQuery('territories', OrganizationAPI.getTerritories, { refetchOnMount: false, refetchOnWindowFocus: false });
  const navigate = useNavigate();

  const mutation = useMutation((formData) => CompanyAPI.addClient(formData), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/client');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const submit = (formData) => {
    const territoryId = data && data.data && data.data.find((community) => community.name === formData.address.community)._id;
    formData.address.territory = territoryId;
    formData.contract.date = moment(formData.contract.date).format('YYYY-MM-DD');
    mutation.mutate(formData);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const getStreets = (community, town) => {
    let streets = [];
    if (data && data.data && community && town) {
      const foundTown = data.data.find((comm) => comm._id === community).towns.find((item) => item._id === town);
      streets = foundTown ? foundTown.streets : [];
    }
    return streets;
  };

  const validationFullAddress = (community, town, street, buildingNumber) => community || town || street || buildingNumber;

  return (
    <SuiBox sx={{ width: '100%' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => submit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card>
                  <SuiBox sx={{ padding: 4 }}>
                    <SuiTypography variant="subtitle2">
                      Dane kontaktowe
                    </SuiTypography>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <Popover
                        id="find_address"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.community',
                              placeholder: 'Gmina',
                              options: data && data.data ? data.data.map((item) => ({
                                label: `${item.name} (${item.additionalName})`,
                                value: item.name,
                                id: item._id,
                              })) : [],
                            }}
                            value={values.address.community}
                            error={errors && errors.address && errors.address.community && touched.address && touched.address.community}
                            handleChange={(event) => {
                              handleChange({ target: { value: event.id, name: 'address.communityId' } });
                              handleChange({ target: { value: '', name: 'address.townId' } });
                              handleChange({ target: { value: '', name: 'address.town' } });
                              handleChange({ target: { value: '', name: 'address.street' } });
                              handleChange(event);
                            }}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.community && touched.address && touched.address.community && errors.address.community }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.town',
                              placeholder: 'Miejscowość',
                              options: values.address.communityId && data && data.data ? data.data.find((comm) => comm._id === values.address.communityId).towns.map((item) => ({
                                label: item.name,
                                value: item.name,
                                id: item._id,
                              })) : [],
                            }}
                            value={values.address.town}
                            error={errors && errors.address && errors.address.town && touched.address && touched.address.town}
                            handleChange={(event) => {
                              handleChange({ target: { value: event.id, name: 'address.townId' } });
                              handleChange({ target: { value: '', name: 'address.street' } });
                              handleChange(event);
                            }}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.town && touched.address && touched.address.town && errors.address.town }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.street',
                              placeholder: 'Ulica',
                              options: values.address.town ? getStreets(values.address.communityId, values.address.townId).map((item) => ({
                                label: item.fullName,
                                value: item.fullName,
                              })) : [],
                            }}
                            value={values.address.street}
                            error={errors && errors.address && errors.address.street && touched.address && touched.address.street}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.street && touched.address && touched.address.street && errors.address.street }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <TextField
                            field={{
                              name: 'address.buildingNumber',
                              placeholder: 'Numer budynku',
                            }}
                            value={values.address.buildingNumber}
                            error={errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber && errors.address.buildingNumber }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <TextField
                            field={{
                              name: 'address.localNumber',
                              placeholder: 'Numer lokalu',
                            }}
                            value={values.address.localNumber}
                            error={errors && errors.address && errors.address.localNumber && touched.address && touched.address.localNumber}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.localNumber && touched.address && touched.address.localNumber && errors.address.localNumber }
                          </SuiTypography>
                        </SuiBox>
                      </Popover>
                      <TextField
                        onClick={handleClick}
                        field={{
                          name: 'fulladdress',
                          icon: <SearchIcon />,
                          placeholder: 'Adres nieruchomości',
                        }}
                        value={`${values.address.town} ${values.address.street} ${values.address.buildingNumber}${values.address.localNumber ? `/${values.address.localNumber}` : ''}${values.address.community ? `, ${values.address.community}` : ''}`}
                        error={validationFullAddress(
                          errors && errors.address && errors.address.community && touched.address && touched.address.community,
                          errors && errors.address && errors.address.town && touched.address && touched.address.town,
                          errors && errors.address && errors.address.street && touched.address && touched.address.street,
                          errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber,
                        )}
                        readonly={isLoading}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.fulladdress && touched.fulladdress && errors.fulladdress }
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'contact.person',
                          placeholder: 'Osoba do kontaktu',
                        }}
                        value={values.contact.person}
                        error={errors && errors.contact && errors.contact.person && touched.contact && touched.contact.person}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contact && errors.contact.person && touched.contact && touched.contact.person && errors.contact.person }
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'contact.phone',
                          placeholder: 'Numer telefonu',
                        }}
                        value={values.contact.phone}
                        error={errors && errors.contact && errors.contact.phone && touched.contact && touched.contact.phone}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contact && errors.contact.phone && touched.contact && touched.contact.phone && errors.contact.phone }
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'contact.email',
                          placeholder: 'Adres email',
                        }}
                        value={values.contact.email}
                        error={errors && errors.contact && errors.contact.email && touched.contact && touched.contact.email}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contact && errors.contact.email && touched.contact && touched.contact.email && errors.contact.email }
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <SuiBox sx={{ padding: 4 }}>
                    <SuiTypography variant="subtitle2">
                      Umowa
                    </SuiTypography>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'contract.number',
                          placeholder: 'Numer umowy',
                        }}
                        value={values.contract.number}
                        error={errors && errors.contract && errors.contract.number && touched.contract && touched.contract.number}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contract && errors.contract.number && touched.contract && touched.contract.number && errors.contract.number }
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <SoftDatePicker
                        options={{
                          locale: Polish,
                          dateFormat: 'Y-m-d',
                        }}
                        onChange={([date]) => {
                          handleChange({ target: { value: moment(date).format('YYYY-MM-DD'), name: 'contract.date' } });
                        }}
                        input={{
                          placeholder: 'Data zawarcia umowy',
                          value: values.contract.date,
                          name: 'contract.date',
                          error: errors && errors.contract && errors.contract.date && touched.contract && touched.contract.date,
                          size: 'large',
                          icon: {
                            component: <CalendarToday />,
                            direction: 'left',
                          },
                        }}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contract && errors.contract.date && touched.contract && touched.contract.date && errors.contract.date }
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <SelectField
                        field={{
                          placeholder: 'Status umowy',
                          name: 'contract.isActive',
                          options: [
                            {
                              label: 'Aktywna',
                              value: true,
                            },
                            {
                              label: 'Nieaktywna',
                              value: false,
                            },
                          ],
                        }}
                        value={values.contract.isActive}
                        error={errors && errors.contract && errors.contract.isActive && touched.contract && touched.contract.isActive}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contract && errors.contract.isActive && touched.contract && touched.contract.isActive && errors.contract.isActive}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ paddingTop: 4, marginTop: 2, position: 'relative' }}>
                      <SelectField
                        field={{
                          placeholder: 'Rodzaj przekazanych nieczystości',
                          name: 'contract.impuritiesType',
                          options: [
                            {
                              label: 'Bytowe',
                              value: 'household',
                            },
                            {
                              label: 'Przemysłowe',
                              value: 'industrial',
                            },
                          ],
                        }}
                        value={values.contract.impuritiesType}
                        error={errors && errors.contract && errors.contract.impuritiesType && touched.contract && touched.contract.impuritiesType}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contract && errors.contract.impuritiesType && touched.contract && touched.contract.impuritiesType && errors.contract.impuritiesType}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            </Grid>
            <Grid className="add-collection-buttons" justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
              <SuiButton
                sx={{ marginRight: 2 }}
                onClick={() => navigate('/client')}
                size="large"
                color="white"
                disabled={mutation.isLoading}
              >
                Anuluj
              </SuiButton>
              <SuiButton
                type="submit"
                size="large"
                color="info"
                variant="gradient"
                disabled={mutation.isLoading}
              >
                { mutation.isLoading && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} /> }
                Zapisz
              </SuiButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
};

export default CompanyClientsAdd;
