import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import Table from '@mui/material/Table';
import { useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SuiPagination from 'components/SuiPagination';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Skeleton } from '@mui/material';
import DataTableHeadCell from './DataTableHeadCell';
import DataTableBodyCell from './DataTableBodyCell';

const DataTableBody = ({
  pagination,
  isSorted,
  noEndBorder,
  paginationPage,
  changeParam,
  count,
  table,
  sortPage,
  isFetching,
  limit,
}) => {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const navigate = useNavigate();

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
  } = tableInstance;

  useEffect(() => setPageSize(limit), []);

  const pagesOptions = () => {
    const pages = [];
    for (let i = 1; i <= Math.ceil(count / limit); i += 1) {
      pages.push(i);
    }
    return pages;
  };

  const renderPagination = pagesOptions().map((option) => (
    (paginationPage - 2 <= option && paginationPage + 2 >= option)
    && (
      <SuiPagination
        item
        key={option}
        onClick={() => changeParam('page', Number(option))}
        active={paginationPage === option}
        className={`${paginationPage === option && 'active'}`}
      >
        {option}
      </SuiPagination>
    )
  ));
  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  const clickOnRow = (url) => navigate(url);

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      <Table {...getTableProps()}>
        <SuiBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <DataTableHeadCell
                  width={column.width ? column.width : 'auto'}
                  align={column.align ? column.align : 'left'}
                  sorted={setSortedValue(column)}
                  sortable={column.sortable}
                  sortPage={sortPage}
                  id={column.id}
                  key={column.id}
                  changeParam={changeParam}
                  sortedKey={columns[index].key}
                >
                  {column.render('Header')}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <TableBody {...getTableBodyProps()}>
          {
            page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow
                  sx={{ cursor: `${row.original.redirect && 'pointer'}`, background: `${row.original.alert && '#fcf3f3'}`, opacity: `${row.original.inactive && 0.5}` }}
                  hover={row.original.redirect}
                  onClick={() => row.original.redirect && clickOnRow(row.original.redirect)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : 'left'}
                      {...cell.getCellProps()}
                    >
                      {
                        isFetching ? <Skeleton height={30} sx={{ width: '100%' }} />
                          : cell.render('Cell')
                      }
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
      {
        page.length === 0 && (
          <SuiBox sx={{
            width: '100%',
            opacity: '0.2',
            padding: '38px 50px 10px 50px',
            textAlign: 'center',
          }}
          >
            <SuiTypography variant="h4">
              Brak wyników
            </SuiTypography>
          </SuiBox>
        )
      }
      <SuiBox
        display="flex"
        flexDirection={{ xs: 'row', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        sx={{ padding: `${page.length === 0 ? 0 : 3}`, marginTop: 2 }}
      >
        {count > limit && (
          isFetching ? (
            <>
              <Skeleton height={30} sx={{ width: '300px' }} />
              <Skeleton height={30} sx={{ width: '80px' }} />
            </>
          ) : (
            <>
              <SuiPagination
                variant={pagination.variant ? pagination.variant : 'gradient'}
                color={pagination.color ? pagination.color : 'info'}
              >
                {paginationPage > 1 && Math.ceil(count / limit) >= 5 && paginationPage > 3 && (
                  <SuiPagination item onClick={() => changeParam('page', 1)}>
                    <KeyboardDoubleArrowLeftIcon sx={{ fontWeight: 'bold' }} />
                  </SuiPagination>
                )}
                {paginationPage > 1 && Math.ceil(count / limit) >= 5 && paginationPage > 3 && (
                  <SuiPagination item onClick={() => changeParam('page', paginationPage - 1)}>
                    <ArrowBackIosIcon sx={{ fontWeight: 'bold' }} />
                  </SuiPagination>
                )}
                {Math.ceil(count / limit) >= 5 && (Math.ceil(count / limit) - 1 === parseInt(paginationPage, 10) || Math.ceil(count / limit) === parseInt(paginationPage, 10)) && (
                  <SuiPagination item onClick={() => changeParam('page', Math.ceil(count / limit) - 4)}>
                    {Math.ceil(count / limit) - 4}
                  </SuiPagination>
                )}
                {Math.ceil(count / limit) >= 5 && Math.ceil(count / limit) === parseInt(paginationPage, 10) && (
                  <SuiPagination item onClick={() => changeParam('page', Math.ceil(count / limit) - 3)}>
                    {Math.ceil(count / limit) - 3}
                  </SuiPagination>
                )}
                {renderPagination}
                {count / limit > 4 && parseInt(paginationPage, 10) === 1 && (
                  <SuiPagination item onClick={() => changeParam('page', paginationPage + 1)}>
                    4
                  </SuiPagination>
                )}
                {count / limit > 5 && (parseInt(paginationPage, 10) === 1 || parseInt(paginationPage, 10) === 2) && (
                  <SuiPagination item onClick={() => changeParam('page', paginationPage + 1)}>
                    5
                  </SuiPagination>
                )}
                {Math.ceil(count / limit) >= 5 && paginationPage < Math.ceil(count / limit) - 2 && (
                  <SuiPagination item onClick={() => changeParam('page', paginationPage + 1)}>
                    <ArrowForwardIosIcon sx={{ fontWeight: 'bold' }} />
                  </SuiPagination>
                )}
                {Math.ceil(count / limit) >= 5 && paginationPage < Math.ceil(count / limit) - 2 && (
                  <SuiPagination item onClick={() => changeParam('page', Math.ceil(count / limit))}>
                    <KeyboardDoubleArrowRightIcon sx={{ fontWeight: 'bold' }} />
                  </SuiPagination>
                )}
              </SuiPagination>
              <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
                <SuiTypography sx={{ marginRight: 2 }} variant="caption">
                  {`${((parseInt(paginationPage, 10) - 1) * 20) + 1} - ${parseInt(paginationPage, 10) * 20 > count ? count : parseInt(paginationPage, 10) * 20} / ${count}`}
                </SuiTypography>
              </SuiBox>
            </>
          )
        )}
      </SuiBox>
    </TableContainer>
  );
};

// Setting default values for the props of DataTable
DataTableBody.defaultProps = {
  canSearch: false,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  isFetching: false,
};

// Typechecking props for the DataTable
DataTableBody.propTypes = {
  canSearch: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  paginationPage: PropTypes.number.isRequired,
  changeParam: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  sortPage: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  limit: PropTypes.number.isRequired,
};

export default DataTableBody;
