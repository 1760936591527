import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data odbioru',
        accessor: 'date',
        sortable: true,
        key: 'collections.date',
      },
      {
        Header: 'Firma asenizacyjna',
        accessor: 'company',
        sortable: true,
        key: 'name',
      },
      {
        Header: 'Odebrana objętość',
        accessor: 'capacity',
        sortable: true,
        key: 'collections.capacity',
      },
    ],
    rows: results.map((row) => ({
      date: moment(row.date).format('YYYY-MM-DD'),
      company: row.company,
      capacity: `${row.capacity}m3`,
    })),
  };
  return tableData;
};

export { getTableData };
