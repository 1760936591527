import api from './index';

const Auth = {
  login: async (data) => api.post('/auth/signin', data),
  refresh: async (data) => api.post('/auth/refresh', data),
  logout: async (data) => api.post('/blacklist/', data),
  confirm: async (data) => api.post('/auth/confirm', data),
};

export default Auth;
