import * as yup from 'yup';

const initialValues = (producerData) => {
  if (producerData) {
    return {
      fulladdress: '',
      territory: producerData.territory,
      address: {
        communityId: producerData.territory,
        community: producerData.address.community,
        town: producerData.address.town,
        street: producerData.address.street,
        buildingNumber: producerData.address.buildingNumber,
        localNumber: producerData.address.localNumber,
      },
      producerType: producerData.producerType,
      numberOfRegistered: producerData.numberOfRegistered,
      numberOfInhabitants: producerData.numberOfInhabitants,
      agglomeration: producerData.agglomeration || false,
      impuritiesData: {
        impuritiesType: producerData.impuritiesData.impuritiesType,
        impuritiesTankType: producerData.impuritiesData.impuritiesTankType,
        capacity: producerData.impuritiesData.capacity,
      },
      waterData: {
        collectionMethod: producerData.waterData.collectionMethod,
        mainCounter: producerData.waterData.mainCounter.map((item) => item.number),
        gardenCounter: producerData.waterData.gardenCounter.map((item) => item.number),
      },
      contactData: {
        person: producerData.contactData && producerData.contactData.person ? producerData.contactData.person : '',
        phone: producerData.contactData && producerData.contactData.phone ? producerData.contactData.phone : '',
        email: producerData.contactData && producerData.contactData.email ? producerData.contactData.email : '',
      },
    };
  }
  return {
    fulladdress: '',
    territory: '',
    address: {
      communityId: '',
      community: '',
      town: '',
      street: '',
      buildingNumber: '',
      localNumber: '',
    },
    producerType: '',
    numberOfRegistered: 0,
    numberOfInhabitants: 0,
    agglomeration: false,
    impuritiesData: {
      impuritiesType: '',
      impuritiesTankType: '',
      capacity: 0,
    },
    waterData: {
      collectionMethod: '',
      mainCounter: [],
      gardenCounter: [],
    },
    contactData: {
      person: '',
      phone: '',
      email: '',
    },
  };
};

const validationSchema = yup.object({
  territory: yup.string().required('To pole jest wymagane!'),
  address: yup.object().shape({
    community: yup.string().required('To pole jest wymagane!'),
    town: yup.string().required('To pole jest wymagane!'),
    street: yup.string().required('To pole jest wymagane!'),
    buildingNumber: yup.string().required('To pole jest wymagane!'),
  }),
  producerType: yup.string().required('To pole jest wymagane!'),
  impuritiesData: yup.object().shape({
    impuritiesType: yup.string().required('To pole jest wymagane!'),
    impuritiesTankType: yup.string().required('To pole jest wymagane!'),
  }),
  waterData: yup.object().shape({
    collectionMethod: yup.string().required('To pole jest wymagane!'),
  }),
  contactData: yup.object().shape({
    email: yup.string().email('Podaj prawidłowy adres email!'),
  }),
});

export { initialValues, validationSchema };
