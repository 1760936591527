import moment from 'moment';

const romanNumbers = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};

const quarterDates = () => {
  const dates = [];
  let currentQuarter = moment().quarter();
  let currentYear = moment().year();
  while (currentQuarter > 0) {
    dates.push({
      label: `${romanNumbers[currentQuarter]} Kwartał ${currentYear}`,
      value: `${currentQuarter}.${currentYear}`,
    });
    currentQuarter -= 1;
  }
  while (currentYear > 2018) {
    currentYear -= 1;
    dates.push({
      label: currentYear,
      value: currentYear,
    });
  }
  return dates;
};

export { quarterDates };
