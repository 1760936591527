import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data zrzutu',
        accessor: 'date',
        sortable: true,
        key: 'dumps.date',
      },
      {
        Header: 'Pojazd zrzucający',
        accessor: 'truck',
        sortable: true,
        key: 'dumps.truck.number',
      },
      {
        Header: 'Rodzaj zrzuconych nieczystości',
        accessor: 'impuritiesType',
        sortable: true,
        key: 'dumps.impuritiesType',
      },
      {
        Header: 'Objętość (m3)',
        accessor: 'capacity',
        sortable: true,
        key: 'dumps.capacity',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      date: moment(row.date).format('YYYY-MM-DD'),
      impuritiesType: row.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe',
      capacity: `${row.capacity}m3`,
    })),
  };
  return tableData;
};

export { getTableData };
