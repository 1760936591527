import { useState } from 'react';
import { useLocation, NavLink, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import SuiBox from 'components/SuiBox';
// import MenuIcon from '@mui/icons-material/Menu';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ProtectedContent from 'guard/ProtectedContent';
import SuiTypography from 'components/SuiTypography';
import { useSoftUIController, setMiniSidenav } from 'context';
import Asenico from 'assets/img/asenico.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SidenavCollapse from './SidenavCollapse';
import SidenavList from './SidenavList';
import SidenavItem from './SidenavItem';
import SidenavCard from './SidenavCard';
import SidenavRoot from './SidenavRoot';
import sidenavLogoLabel from './styles/sidenav';
// import { navbarDesktopMenu } from '../DashboardNavbar/styles';

const Sidenav = ({
  color,
  brand,
  brandName,
  routes,
  hideMobileMenu,
  setHideMobileMenu,
  ...rest
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const itemName = pathname.split('/').slice(1)[1];

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({
      name, route, key, href,
    }) => (href ? (
      <Link
        key={key}
        href={href}
        target="_blank"
        rel="noreferrer"
        sx={{ textDecoration: 'none' }}
      >
        <SidenavItem name={name} nested />
      </Link>
    ) : (
      <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
        <SidenavItem name={name} active={route === pathname} nested />
      </NavLink>
    )));

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) => collapses.map(({
    name, collapse, route, href, key,
  }) => {
    let returnValue;

    if (collapse) {
      returnValue = (
        <SidenavItem
          key={key}
          name={name}
          active={key === itemName}
          open={openNestedCollapse === name}
          onClick={() => (openNestedCollapse === name
            ? setOpenNestedCollapse(false)
            : setOpenNestedCollapse(name))}
        >
          {renderNestedCollapse(collapse)}
        </SidenavItem>
      );
    } else {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavItem name={name} active={key === itemName} />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} active={key === itemName} />
        </NavLink>
      );
    }
    return <SidenavList key={key}>{returnValue}</SidenavList>;
  });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type, name, icon, title, collapse, route, noCollapse, key, href, role, organizationType,
    }, idx) => {
      let returnValue;

      if (type === 'collapse') {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: 'none' }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={matchPath({ path: route }, useLocation().pathname)}
              noCollapse={noCollapse}
              route={route}
            />
          </Link>
        ) : (
          <ProtectedContent key={`${key}_${idx}`} userRole={role} organizationType={organizationType}>
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={matchPath({ path: route }, useLocation().pathname)}
              open={openCollapse === name}
              onClick={() => {
                if (openCollapse === name) {
                  setOpenCollapse(false);
                } else {
                  setOpenCollapse(name);
                }
                setHideMobileMenu(true);
              }}
              route={route}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          </ProtectedContent>
        );
      } else if (type === 'title') {
        returnValue = (
          <SuiTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SuiTypography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider className="menu-divider" key={key} />;
      }

      return returnValue;
    },
  );

  const handleMiniSidenav = (state) => setMiniSidenav(dispatch, state);

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav: true, miniSidenav, hideMobileMenu }}>
      <SuiBox>
        <SuiBox className="logo-container" pt={3} pb={1} px={4} textAlign="center">
          <SuiBox sx={{ alignItems: 'center' }} display="flex" alignItems="center">
            {brand && <SuiBox className={`brand-logo ${miniSidenav ? 'roll' : ''}`} component="img" src={brand} alt="Asenico Logo" width="2rem" />}
            <SuiBox
              width={!brandName && '100%'}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <SuiBox sx={{ marginLeft: '4px' }} component="img" src={Asenico} alt="Asenico Logo" width="5rem" />
            </SuiBox>
          </SuiBox>
        </SuiBox>
        <SuiBox
          className="toggle-menu-mobile"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            color: '#247EAF',
          }}
          onClick={() => setHideMobileMenu(true)}
        >
          <SuiTypography sx={{ marginBottom: '-2px', marginLeft: '10px', color: '#247EAF' }} variant="caption">
            Schowaj menu
          </SuiTypography>
          <ArrowForwardIcon />
        </SuiBox>
        <Divider className="menu-divider" />
        <List>{renderRoutes}</List>
        <SuiBox pt={3} pb={1} px={4} className="toggle-menu-desktop">
          {/* {miniSidenav ? <MenuIcon sx={navbarDesktopMenu} onClick={() => handleMiniSidenav(false)} /> : <MenuOpenIcon sx={navbarDesktopMenu} onClick={() => handleMiniSidenav(true)} />} */}
          {
            miniSidenav ? (
              <SuiBox
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#247EAF',
                }}
                onClick={() => handleMiniSidenav(false)}
              >
                <ArrowForwardIcon />
              </SuiBox>
            ) : (
              <SuiBox
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#247EAF',
                }}
                onClick={() => handleMiniSidenav(true)}
              >
                <ArrowBackIcon />
                <SuiTypography sx={{ marginBottom: '-2px', marginLeft: '10px', color: '#247EAF' }} variant="caption">
                  Zwiń panel
                </SuiTypography>
              </SuiBox>
            )
          }
        </SuiBox>
      </SuiBox>
      <SuiBox pt={2} my={2} mx={2}>
        <SidenavCard />
      </SuiBox>
    </SidenavRoot>
  );
};

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideMobileMenu: PropTypes.bool.isRequired,
  setHideMobileMenu: PropTypes.func.isRequired,
};

export default Sidenav;
