import moment from 'moment';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data pomiaru',
        accessor: 'date',
        sortable: true,
        key: 'counterState.date',
      },
      {
        Header: 'Numer licznika',
        accessor: 'number',
        sortable: true,
        key: 'number',
      },
      {
        Header: 'Typ licznika',
        accessor: 'counterType',
        sortable: true,
        key: 'counterType',
      },
      {
        Header: 'Stan licznika',
        accessor: 'state',
        sortable: true,
        key: 'counterState.state',
      },
    ],
    rows: results.map((row) => ({
      date: row.date ? moment(row.date).format('YYYY-MM-DD') : '-',
      number: row.number,
      counterType: row.counterType === 'main' ? 'Główny' : 'Ogrodowy',
      state: `${row.state}m3`,
    })),
  };
  return tableData;
};

export { getTableData };
