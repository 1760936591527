import { getFullAddress } from 'utils/format';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import SuiBox from 'components/SuiBox';
import Tooltip from '@mui/material/Tooltip';
import { homeRed, homeGreen } from 'components/Map/utils';

const getAlertTooltip = (alerts) => {
  const producerAlerts = {
    NEW_PRODUCER: alerts.find((item) => item.alertType === 'NEW_PRODUCER'),
    BALANCE: alerts.find((item) => item.alertType === 'BALANCE'),
    COLLECTIONS_FREQUENCY: alerts.find((item) => item.alertType === 'COLLECTIONS_FREQUENCY'),
  };
  return `
      ${producerAlerts.NEW_PRODUCER ? `${producerAlerts.NEW_PRODUCER.description}. ` : ''}
      ${producerAlerts.BALANCE ? `${producerAlerts.BALANCE.description}.` : ''}
      ${producerAlerts.COLLECTIONS_FREQUENCY ? `${producerAlerts.COLLECTIONS_FREQUENCY.description}.` : ''}
  `;
};

const getTableData = (data) => {
  const tableData = {
    columns: [
      {
        Header: 'Adres nieruchomości',
        accessor: 'address',
        sortable: true,
        key: 'address.street',
      },
      {
        Header: 'Typ podmiotu',
        accessor: 'type',
        sortable: true,
        key: 'producerType',
      },
      {
        Header: 'Rodzaj zbiornika',
        accessor: 'impuritiesType',
        sortable: true,
        key: 'impuritiesData.impuritiesTankType',
      },
    ],
    rows: data.results.map((row) => ({
      redirect: `/producer/${row._id}/details`,
      alert: row.alerts && row.alerts.length > 0,
      inactive: row.inactive,
      address: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          {getFullAddress(row.address, false)}
          {row.alerts && row.alerts.length > 0 && (
            <Tooltip title={getAlertTooltip(row.alerts)} placement="top">
              <WarningAmberOutlinedIcon sx={{ marginLeft: 2 }} color="error" fontSize="small" />
            </Tooltip>
          )}
        </SuiBox>
      ),
      type: !row.producerType ? '-' : row.producerType === 'household' ? 'Gospodarstwo domowe' : 'Firma / Instytucja',
      impuritiesType: !row.impuritiesData.impuritiesTankType ? '-' : row.impuritiesData.impuritiesTankType === 'drainless' ? 'Zbiornik bezodpływowy' : row.impuritiesData.impuritiesTankType === 'toi_toi' ? 'Toi Toi' : 'Przydomowa oczyszczalnia ścieków',
    })),
  };
  return tableData;
};

const getMapData = (results) => {
  const { data } = results;
  return data.map((item) => ({
    id: item._id,
    address: item.address,
    alert: item.alerts && item.alerts.length > 0,
    reason: item.alerts && item.alerts.length > 0 ? item.alerts[0].description : '',
    icon: item.alerts && item.alerts.length ? homeRed : homeGreen,
  }));
};

export { getTableData, getMapData };
