import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

const getUserDataFromToken = () => {
  const decodedToken = Cookies.get('ACCESS_TOKEN') && jwtDecode(Cookies.get('ACCESS_TOKEN'));
  return decodedToken ? {
    group: decodedToken.groups,
  } : { group: null };
};

export { getUserDataFromToken };
