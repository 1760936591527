import * as yup from 'yup';

const initialValues = {
  dumps: [
    {
      key: Date.now(),
      date: '',
      vehicle: '',
      vehicleData: {
        id: '',
        number: '',
      },
      catchpointName: '',
      catchpoint: '',
      impuritiesType: '',
      capacity: '',
    },
  ],
};

const validationSchema = yup.object().shape({
  dumps: yup.array().of(
    yup.object().shape({
      date: yup.string().required('To pole jest wymagane!'),
      vehicle: yup.string().required('To pole jest wymagane!'),
      impuritiesType: yup.string().required('To pole jest wymagane!'),
      capacity: yup.string().required('To pole jest wymagane!'),
    }),
  ),
});

export { initialValues, validationSchema };
