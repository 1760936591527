function collapseItem(theme, ownerState) {
  const {
    palette, transitions, boxShadows, borders, functions,
  } = theme;
  const { active } = ownerState;

  const {
    dark, white, text, transparent,
  } = palette;
  const { xxl } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    background: active ? white.main : transparent.main,
    color: active ? dark.main : text.main,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(16)}`,
    margin: `0 ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    boxShadow: () => {
      if (active) {
        return xxl;
      }

      return 'none';
    },
    transition: transitions.create('box-shadow', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
  };
}

function collapseIconBox(theme, ownerState) {
  const {
    palette, transitions, boxShadows, borders, functions,
  } = theme;
  const { active, transparentSidenav } = ownerState;

  const {
    white, light, gradients, info,
  } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    borderRadius: borderRadius.md,
    display: 'grid',
    placeItems: 'center',
    boxShadow: md,
    transition: transitions.create('margin', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
    background: () => {
      let background;

      if (!active) {
        background = transparentSidenav ? info.main : light.main;
      } else {
        background = 'linear-gradient(90deg, #C3D338 0%, #23B3A0 50%, #247EAF 100%)';
      }

      return background;
    },
    '& svg, svg g': {
      fill: active ? white.main : gradients.dark.state,
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
  const {
    typography, transitions, functions,
  } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
    maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : '100%',
    marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(12.8),
    transition: transitions.create(['opacity', 'margin'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
    '& span': {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme, ownerState) {
  const {
    palette, typography, transitions, functions,
  } = theme;
  const {
    noCollapse, transparentSidenav, miniSidenav, open,
  } = ownerState;

  const { dark, gradients } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.md} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? 'rotate(0)' : 'rotate(-180deg)',
    color: open ? dark.main : rgba(gradients.dark.state, 0.4),
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
    display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? 'none !important'
          : 'block !important',
  };
}

export {
  collapseItem, collapseIconBox, collapseIcon, collapseText, collapseArrow,
};
