import React from 'react';
import { useDispatch } from 'react-redux';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography';
import OrganizationAPI from 'api/organization';
import { useParams } from 'react-router-dom';
import SuiButton from 'components/SuiButton';
import { getFullAddress } from 'utils/format';
import { useQuery, useMutation } from 'react-query';
import { setMessage } from 'store';
import DataInfo from 'components/DataInfo';
import ProtectedContent from 'guard/ProtectedContent';
import { activeLicense, deactiveLicense } from './utils';

const CompanyDetailsData = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(['company_identification_data', id], () => OrganizationAPI.getCompany(id));

  const mutation = useMutation((formData) => OrganizationAPI.updateCompanyLicense(formData, id, data && data.data && data.data.territory && data.data.territory.id), {
    onSuccess: () => {
      refetch();
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  return (
    <SuiBox sx={{ width: '100%' }}>
      <SuiBox sx={{ marginBottom: 4 }}>
        <Card>
          <SuiBox sx={{ padding: 4 }}>
            <SuiTypography variant="subtitle2">
              Dane firmy
            </SuiTypography>
            <Grid container spacing={2}>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="NIP" value={data && data.data && data.data.company && data.data.company.organization.nip} />
              </Grid>
              <ProtectedContent userRole={['editor', 'admin']} organizationType={['community']}>
                <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                  <DataInfo isLoading={isLoading} label={`Numer koncesji (${data && data.data && data.data.territory && data.data.territory && data.data.territory.isActive ? 'aktywna' : 'nieaktywna'})`} value={data && data.data && data.data.territory && data.data.territory.licenseNumber} />
                </Grid>
              </ProtectedContent>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Nazwa" value={data && data.data && data.data.company && data.data.company.organization.name} />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Adres" value={data && data.data && data.data.company && getFullAddress(data.data.company.organization.address)} />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox sx={{ marginBottom: 4 }}>
        <Card sx={{ marginTop: 4 }}>
          <SuiBox sx={{ padding: 4 }}>
            <SuiTypography variant="subtitle2">
              Dane kontaktowe
            </SuiTypography>
            <Grid container spacing={2}>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Osoba kontaktowa" value={data && data.data && data.data.user && `${data.data.user.name} ${data.data.user.surname}`} />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Numer telefonu" value={data && data.data && data.data.company && data.data.company.organization.phoneNumber} />
              </Grid>
              <Grid sx={{ marginTop: 2, position: 'relative' }} item xs={3}>
                <DataInfo isLoading={isLoading} label="Adres email" value={data && data.data && data.data.user && data.data.user.email} />
              </Grid>
            </Grid>
          </SuiBox>
        </Card>
      </SuiBox>
      <SuiBox sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ProtectedContent userRole={['editor', 'admin']} organizationType={['community']}>
          {
            data && data.data && data.data.territory && data.data.territory.isActive ? (
              <SuiButton isLoading={isLoading} isFetching={mutation.isLoading} onClick={() => deactiveLicense(() => mutation.mutate({ isActive: false }))} size="large" variant="outlined" color="info">
                Deaktywuj koncesję
              </SuiButton>
            ) : (
              <SuiButton isLoading={isLoading} isFetching={mutation.isLoading} onClick={() => activeLicense(() => mutation.mutate({ isActive: true }))} size="large" variant="outlined" color="info">
                Aktywuj koncesję
              </SuiButton>
            )
          }
        </ProtectedContent>
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyDetailsData;
