import moment from 'moment';
import SuiProgress from 'components/SuiProgress';
import SuiBox from 'components/SuiBox';

const getTableData = (data) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Okres bilansowania',
        accessor: 'period',
        sortable: true,
        key: 'balances.dateFrom',
      },
      {
        Header: 'Woda pobrana',
        accessor: 'waterTaken',
        sortable: true,
        key: 'balances.waterTaken',
      },
      {
        Header: 'Woda ogrodowa',
        accessor: 'waterGarden',
        sortable: true,
        key: 'balances.waterGarden',
      },
      {
        Header: 'Woda ściekowa',
        accessor: 'waterSewage',
        sortable: true,
        key: 'balances.waterSewage',
      },
      {
        Header: 'Przybliż. obj. ścieków',
        accessor: 'sewageVolume',
        sortable: true,
        key: 'balances.sewageVolume',
      },
      {
        Header: 'Bilans',
        accessor: 'balance',
        sortable: true,
        key: 'balances.balance',
        width: '12%',
      },
    ],
    rows: results.map((row) => ({
      period: `${row.dateFrom ? `${moment(row.dateFrom).format('YYYY-MM-DD')} - ` : ''}${moment(row.dateTo).format('YYYY-MM-DD')}`,
      waterTaken: row.waterTaken ? `${row.waterTaken.toFixed(2)}m3` : '-',
      waterGarden: row.waterGarden ? `${row.waterGarden.toFixed(2)}m3` : 0,
      waterSewage: row.waterSewage ? `${row.waterSewage.toFixed(2)}m3` : '-',
      sewageVolume: row.sewageVolume ? `${row.sewageVolume.toFixed(2)}m3` : '0',
      balance: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <SuiBox sx={{ width: '70px' }}>{`${row.balance ? row.balance.toFixed(2) : '0'}%`}</SuiBox>
          <SuiProgress
            sx={{ width: '140px', height: '4px', marginLeft: 2 }}
            gradient
            value={row.balance ? (row.balance.toFixed(2) > 100 ? 100 : row.balance.toFixed(2)) : 0}
            color={row.balance ? (row.balance.toFixed(2) < 80 ? 'error' : 'success') : 'error'}
          />
        </SuiBox>
      ),
    })),
  };
  return tableData;
};

export { getTableData };
