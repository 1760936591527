import React from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import CompanyAPI from 'api/company';
// import SuiInput from 'components/SuiInput';
// import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import SuiBox from 'components/SuiBox';
import { getTableData } from './utils';

const CompanyClientsCollections = () => {
  const { id } = useParams();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, ['company_client_collections', id], () => CompanyAPI.getClientCollections(id, params));

  return (
    <SuiBox>
      {/* <SuiBox */}
      {/*   sx={{ */}
      {/*     padding: 4, */}
      {/*     display: 'flex', */}
      {/*     justifyContent: 'flex-end', */}
      {/*     marginTop: '-96px', */}
      {/*   }} */}
      {/* > */}
      {/*   <SuiBox> */}
      {/*     <SuiInput */}
      {/*       name="search" */}
      {/*       placeholder="Szukaj..." */}
      {/*       icon={{ */}
      {/*         component: <SearchIcon />, */}
      {/*         direction: 'left', */}
      {/*       }} */}
      {/*       onChange={(event) => changeParam(event.target.name, event.target.value, true)} */}
      {/*       isLoading={isLoading} */}
      {/*     /> */}
      {/*   </SuiBox> */}
      {/* </SuiBox> */}
      <SuiBox>
        <DataTable
          table={data && getTableData(data.data)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          isFetching={isFetching}
          limit={params.limit}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyClientsCollections;
