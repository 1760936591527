import * as yup from 'yup';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

const formSchema = {
  fields: [
    {
      name: 'email',
      placeholder: 'Email',
      icon: <PersonIcon />,
    },
    {
      name: 'password',
      placeholder: 'Hasło',
      icon: <LockIcon />,
      type: 'password',
    },
  ],
  button: {
    fullWidth: true,
    label: 'Zaloguj',
    size: 'large',
    variant: 'gradient',
    color: 'info',
  },
};

const validationSchema = yup.object({
  email: yup.string().email('Podaj prawidłowy adres email!').required('To pole jest wymagane!'),
  password: yup.string().required('To pole jest wymagane!'),
});

const initialValues = { email: '', password: '' };

export { initialValues, validationSchema, formSchema };
