import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CompanyAPI from 'api/company';
import Grid from '@mui/material/Grid';
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import Skeleton from '@mui/material/Skeleton';
import SuiBadge from 'components/SuiBadge';
import ReportAPI from 'api/report';
import DataInfo from 'components/DataInfo';
import DataTable from 'components/DataTable';
import { getFullAddress } from 'utils/format';
import { setMessage } from 'store';
import moment from 'moment';
import Card from '@mui/material/Card';
import { useQuery, useMutation } from 'react-query';
import { reportStatus } from 'utils/constants';

const CompanyReportsDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(['report_details', id], () => CompanyAPI.reportDetails(id));

  const mutation = useMutation((reportData) => CompanyAPI.editReport(id, reportData), {
    onSuccess: (response) => {
      const { message } = response.data;
      refetch();
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const pdfMutation = useMutation((reportData) => ReportAPI.getPdf(id, reportData), {
    onSuccess: (response) => {
      const fileURL = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: 'application/pdf' },
        ),
      );
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const fileName = response.headers['content-disposition'].split('=')[1];
      fileLink.setAttribute('download', fileName);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', paddingBottom: 4, marginTop: !isMobile() && '32px' }}>
      {
        data && data.data && data.data.status === 'rejected' && (
          <SuiBox sx={{ marginBottom: 4 }}>
            <Card className="error-card">
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography variant="caption">
                  Powód odrzucenia
                </SuiTypography>
                <SuiTypography variant="h5">
                  { data.data.history && data.data.history[data.data.history.length - 1].status === 'rejected' && data.data.history[data.data.history.length - 1].note }
                </SuiTypography>
              </SuiBox>
            </Card>
          </SuiBox>
        )
      }
      <SuiBox sx={{ marginBottom: 4 }}>
        <Card>
          <SuiBox
            sx={{
              padding: isMobile() ? 2 : 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {
              isLoading ? (
                <>
                  <Skeleton height={48} sx={{ marginBottom: '16px', width: '60%' }} />
                  <Skeleton height={48} sx={{ marginBottom: '16px', width: '20%' }} />
                </>
              ) : (
                <SuiBox
                  className="report-header"
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SuiBox className="report-subheader" sx={{ display: 'flex' }}>
                    <SuiTypography sx={{ marginRight: isMobile() ? 2 : 4 }} variant={isMobile() ? 'h6' : 'h3'}>
                      {data && data.data && data.data.truck}
                    </SuiTypography>
                    <SuiTypography sx={{ marginRight: isMobile() ? 0 : 4 }} variant={isMobile() ? 'h6' : 'h3'}>
                      {`Raport za ${data && data.data && moment(data.data.date).format('YYYY-MM-DD')}`}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox sx={{ display: 'flex', gap: '8px' }}>
                    <SuiBadge
                      circular
                      size="lg"
                      container
                    >
                      {data && data.data && reportStatus()[data.data.status].icon}
                      {!isMobile() && data && data.data && reportStatus()[data.data.status].label}
                    </SuiBadge>
                    {
                      data && data.data && (
                        <SuiBox className="floating-button-panel">
                          {
                            (data.data.status === 'new' || data.data.status === 'outstanding') && (
                              <SuiButton
                                size="large"
                                isFetching={mutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => mutation.mutate({ data: { status: 'sent' } })}
                                variant="gradient"
                                color="info"
                              >
                                Wyślij raport
                              </SuiButton>
                            )
                          }
                          {
                            data.data.status === 'sent' && (
                              <SuiButton
                                size="large"
                                isFetching={mutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => mutation.mutate({ data: { status: 'new' } })}
                                variant="gradient"
                                color="info"
                              >
                                Cofnij wysyłanie
                              </SuiButton>
                            )
                          }
                          {
                            data.data.status === 'rejected' && (
                              <SuiButton
                                size="large"
                                isFetching={mutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => mutation.mutate({ data: { status: 'sent' } })}
                                variant="gradient"
                                color="info"
                              >
                                Wyślij poprawiony raport
                              </SuiButton>
                            )
                          }
                          {
                            data.data.status === 'approved' && (
                              <SuiButton
                                size="large"
                                isFetching={mutation.isLoading || pdfMutation.isLoading}
                                isLoading={isLoading}
                                onClick={() => pdfMutation.mutate(data.data)}
                                variant="gradient"
                                color="info"
                              >
                                Drukuj raport
                              </SuiButton>
                            )
                          }
                        </SuiBox>
                      )
                    }
                  </SuiBox>
                </SuiBox>
              )
            }
          </SuiBox>
        </Card>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: isMobile() ? 2 : 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <DataInfo
                isLoading={isLoading}
                label="Stacja odbierająca nieczystości"
                value={data && data.data && data.data.catchpoint && getFullAddress(data.data.catchpoint.address)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <DataInfo
                isLoading={isLoading}
                label="Rodzaj nieczystości"
                value={data && data.data && data.data.impuritiesType && data.data.impuritiesType === 'household' ? 'bytowe' : 'przemysłowe'}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <DataInfo
                isLoading={isLoading}
                label="Data złożenia raportu"
                value={data && data.data && data.data.sentDate ? moment(data.data.sentDate).format('YYYY-MM-DD') : '-'}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <DataInfo
                isLoading={isLoading}
                label="Data zatwierdzenia raportu"
                value={data && data.data && data.data.acceptedDate ? moment(data.data.acceptedDate).format('YYYY-MM-DD') : '-'}
              />
            </Grid>
          </Grid>
        </SuiBox>
      </Card>
      <SuiBox sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: isMobile() ? 2 : 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  isLoading={isLoading}
                  label="Łączna objętość nieczystości odebranych"
                  value={data && data.data && `${data.data.collectionsCapacitySum}m3`}
                />
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: isMobile() ? 2 : 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  isLoading={isLoading}
                  label="Łączna objętość nieczystości zrzuconych"
                  value={data && data.data && `${data.data.dumpsCapacitySum}m3`}
                />
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <SuiBox
                sx={{
                  padding: isMobile() ? 2 : 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <DataInfo
                  bold
                  withBalance
                  isLoading={isLoading}
                  label="Bilans"
                  value={data && data.data && `${data.data.balance}%`}
                />
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox sx={{ marginTop: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <SuiBox sx={{ padding: isMobile() ? 2 : 4 }}>
                <SuiTypography variant="h4">
                  Rejestr odbiorów
                </SuiTypography>
                {
                  data && data.data && (
                    <DataTable
                      table={{
                        columns: [
                          {
                            Header: 'Data odbioru',
                            accessor: 'date',
                          },
                          {
                            Header: 'Objętość',
                            accessor: 'capacity',
                          },
                          {
                            Header: 'Klient',
                            accessor: 'client',
                            width: '50%',
                          },
                        ],
                        rows: data.data.collections.map((row) => ({
                          date: moment(row.date).format('YYYY-MM-DD'),
                          capacity: `${row.capacity}m3`,
                          client: getFullAddress(row.client.address),
                        })),
                      }}
                      loading={isLoading}
                      paginationPage={0}
                      sortPage=""
                      changeParam={() => null}
                      count={100}
                      isLoading={isLoading}
                      isFetching={isLoading}
                      limit={100}
                    />
                  )
                }
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <SuiBox sx={{ padding: isMobile() ? 2 : 4 }}>
                <SuiTypography variant="h4">
                  Rejestr zrzutów
                </SuiTypography>
                {
                  data && data.data && (
                    <DataTable
                      table={{
                        columns: [
                          {
                            Header: 'Data zrzutu',
                            accessor: 'date',
                          },
                          {
                            Header: 'Objętość',
                            accessor: 'capacity',
                          },
                        ],
                        rows: data.data.dumps.map((row) => ({
                          date: moment(row.date).format('YYYY-MM-DD'),
                          capacity: `${row.capacity}m3`,
                        })),
                      }}
                      loading={isLoading}
                      paginationPage={0}
                      sortPage=""
                      changeParam={() => null}
                      count={100}
                      isLoading={isLoading}
                      isFetching={isLoading}
                      limit={100}
                    />
                  )
                }
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyReportsDetails;
