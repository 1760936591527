import React from 'react';
import ProducerAPI from 'api/producer';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import { useParams } from 'react-router-dom';
import { getTableData } from './utils';

const ProducerDetailsCollections = () => {
  const { id } = useParams();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, ['producer_measurements_', id], ProducerAPI.getProducerMeasurements);

  return (
    <SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <DataTable
            table={data && getTableData(data.data)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default ProducerDetailsCollections;
