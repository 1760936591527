import React from 'react';
import Grid from '@mui/material/Grid';
import DefaultCounterCard from 'components/CounterCards/DefaultCounterCard';
import { useQuery } from 'react-query';
import CompanyAPI from 'api/company';
import { useSelector } from 'react-redux';
import SuiBox from 'components/SuiBox';

const CompanyDashboard = () => {
  const appState = useSelector((state) => state.app);
  const { data, isLoading } = useQuery(
    'company_summary',
    () => CompanyAPI.summary(appState.user.company_id),
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SuiBox marginBottom={3}>
          <DefaultCounterCard isLoading={isLoading} title="zatwierdzonych raportów" count={data && data.data.approved_reports} />
        </SuiBox>
      </Grid>
      <Grid item xs={4}>
        <SuiBox marginBottom={3}>
          <DefaultCounterCard isLoading={isLoading} title="oczekujących raportów" count={data && data.data.sent_reports} />
        </SuiBox>
      </Grid>
      <Grid item xs={4}>
        <SuiBox marginBottom={3}>
          <DefaultCounterCard isLoading={isLoading} title="odrzuconych raportów" count={data && data.data.declined_reports} />
        </SuiBox>
      </Grid>
      <Grid item xs={4}>
        <SuiBox marginBottom={3}>
          <DefaultCounterCard isLoading={isLoading} title="zaległych raportów" count={data && data.data.late_reports} />
        </SuiBox>
      </Grid>
    </Grid>
  );
};

export default CompanyDashboard;
