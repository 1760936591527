import * as yup from 'yup';

const initialValues = {
  organization: {
    nip: '',
    licenseNumber: '',
    name: '',
    address: {
      city: '',
      postCode: '',
      street: '',
      buildingNumber: '',
      localNumber: '',
    },
    phoneNumber: '',
    type: 'company',
  },
  user: {
    name: '',
    surname: '',
    email: '',
    role: 'admin',
    organizationType: 'company',
  },
};

const validationSchema = yup.object({
  organization: yup.object().shape({
    nip: yup.string().required('To pole jest wymagane!'),
    licenseNumber: yup.string().required('To pole jest wymagane!'),
    name: yup.string().required('To pole jest wymagane!'),
    phoneNumber: yup.string().required('To pole jest wymagane!'),
    address: yup.object().shape({
      city: yup.string().required('To pole jest wymagane!'),
      postCode: yup.string().required('To pole jest wymagane!'),
      street: yup.string().required('To pole jest wymagane!'),
      buildingNumber: yup.string().required('To pole jest wymagane!'),
    }),
  }),
  user: yup.object().shape({
    name: yup.string().required('To pole jest wymagane!'),
    surname: yup.string().required('To pole jest wymagane!'),
    email: yup.string().email('Podaj prawidłowy adres email!').required('To pole jest wymagane!'),
  }),
});

export { initialValues, validationSchema };
