import React from 'react';
import useDataTable from 'hooks/useDataTable';
import AddIcon from '@mui/icons-material/Add';
import DataTable from 'components/DataTable';
import CompanyAPI from 'api/company';
import SuiInput from 'components/SuiInput';
import SearchIcon from '@mui/icons-material/Search';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import {
  useNavigate,
} from 'react-router-dom';
import { getTableData } from './utils';

const CompanyClients = () => {
  const navigate = useNavigate();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, 'company_clients', CompanyAPI.getClients);

  return (
    <SuiBox>
      <SuiBox className="filter-box" sx={{ padding: 4, display: 'flex', justifyContent: 'space-between' }}>
        <SuiBox sx={{ marginRight: 2 }}>
          <SuiInput
            name="search"
            placeholder="Szukaj..."
            icon={{
              component: <SearchIcon />,
              direction: 'left',
            }}
            onChange={(event) => changeParam(event.target.name, event.target.value, true)}
            isLoading={isLoading}
          />
        </SuiBox>
        <SuiBox className="floating-button-panel">
          <SuiButton isLoading={isLoading} onClick={() => navigate('/client/add')} variant="gradient" color="info">
            Dodaj klienta
            <AddIcon sx={{ marginLeft: 2 }} />
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <DataTable
          table={data && getTableData(data.data)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          isFetching={isFetching}
          limit={params.limit}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyClients;
