import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { getErrorFromApi } from 'utils/messages';
import { useNavigate } from 'react-router-dom';
import ProducerAPI from 'api/producer';
import OrganizationAPI from 'api/organization';
import { useDispatch } from 'react-redux';
import SuiBox from 'components/SuiBox';
import SelectField from 'components/DataForm/SelectField';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import TextField from 'components/DataForm/TextField';
import TagField from 'components/DataForm/TagField';
import SuiTypography from 'components/SuiTypography';
import SuiButton from 'components/SuiButton';
import { useMutation, useQuery } from 'react-query';
import { setMessage } from 'store';
import SearchIcon from '@mui/icons-material/Search';
import { initialValues, validationSchema } from './utils';

const ProducerAdd = () => {
  const [initValues, setInitValues] = useState(initialValues);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    'territories',
    OrganizationAPI.getTerritories,
    {
      onSuccess: (response) => {
        const territoryData = response.data;
        if (territoryData.length === 1) {
          setInitValues({
            ...initValues,
            territory: territoryData[0]._id,
            address: {
              ...initValues.address,
              communityId: territoryData[0]._id,
              community: territoryData[0].name,
            },
          });
        }
      },
    },
  );
  const mutation = useMutation((formData) => ProducerAPI.createProducer(formData), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/producer');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const submit = (values) => {
    mutation.mutate(values);
  };

  const getStreets = (community, town) => {
    let streets = [];
    if (data && data.data && community && town) {
      const foundTown = data.data.find((comm) => comm._id === community).towns.find((item) => item._id === town);
      streets = foundTown ? foundTown.streets : [];
    }
    return streets;
  };

  const validationFullAddress = (community, town, street, buildingNumber) => community || town || street || buildingNumber;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', marginTop: !isMobile() && '32px' }}>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={(values) => submit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            <Card>
              <SuiBox sx={{ padding: 4 }}>
                <SuiTypography sx={{ marginBottom: -6 }} variant="subtitle2">
                  Dane identyfikacyjne
                </SuiTypography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <Popover
                        id="find_address"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.community',
                              placeholder: 'Gmina',
                              options: data && data.data ? data.data.map((item) => ({
                                label: item.name,
                                value: item.name,
                                id: item._id,
                              })) : [],
                            }}
                            value={values.address.community}
                            error={errors && errors.address && errors.address.community && touched.address && touched.address.community}
                            handleChange={(event) => {
                              handleChange({ target: { value: event.id, name: 'address.communityId' } });
                              handleChange({ target: { value: '', name: 'address.townId' } });
                              handleChange({ target: { value: '', name: 'address.town' } });
                              handleChange({ target: { value: '', name: 'address.street' } });
                              handleChange(event);
                            }}
                            readonly={data && data.data && data.data.length === 1}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.community && touched.address && touched.address.community && errors.address.community }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.town',
                              placeholder: 'Miejscowość',
                              options: values.address.communityId && data && data.data ? data.data.find((comm) => comm._id === values.address.communityId).towns.map((item) => ({
                                label: item.name,
                                value: item.name,
                                id: item._id,
                              })) : [],
                            }}
                            value={values.address.town}
                            error={errors && errors.address && errors.address.town && touched.address && touched.address.town}
                            handleChange={(event) => {
                              handleChange({ target: { value: event.id, name: 'address.townId' } });
                              handleChange({ target: { value: '', name: 'address.street' } });
                              handleChange(event);
                            }}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.town && touched.address && touched.address.town && errors.address.town }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <SelectField
                            field={{
                              name: 'address.street',
                              placeholder: 'Ulica',
                              options: values.address.town ? getStreets(values.address.communityId, values.address.townId).map((item) => ({
                                label: item.fullName,
                                value: item.fullName,
                              })) : [],
                            }}
                            value={values.address.street}
                            error={errors && errors.address && errors.address.street && touched.address && touched.address.street}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.street && touched.address && touched.address.street && errors.address.street }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <TextField
                            field={{
                              name: 'address.buildingNumber',
                              placeholder: 'Numer budynku',
                            }}
                            value={values.address.buildingNumber}
                            error={errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber && errors.address.buildingNumber }
                          </SuiTypography>
                        </SuiBox>
                        <SuiBox sx={{ width: 600, padding: '36px 0 0 0', position: 'relative' }}>
                          <TextField
                            field={{
                              name: 'address.localNumber',
                              placeholder: 'Numer lokalu',
                            }}
                            value={values.address.localNumber}
                            error={errors && errors.address && errors.address.localNumber && touched.address && touched.address.localNumber}
                            handleChange={handleChange}
                          />
                          <SuiTypography component="div" color="error" variant="caption">
                            {errors && errors.address && errors.address.localNumber && touched.address && touched.address.localNumber && errors.address.localNumber }
                          </SuiTypography>
                        </SuiBox>
                      </Popover>
                      <TextField
                        onClick={handleClick}
                        field={{
                          name: 'fulladdress',
                          icon: <SearchIcon />,
                          placeholder: 'Adres nieruchomości',
                        }}
                        value={values.address.town && values.address.street && `${values.address.town} ${values.address.street} ${values.address.buildingNumber}${values.address.localNumber ? `/${values.address.localNumber}` : ''}${values.address.community ? `, ${values.address.community}` : ''}`}
                        error={validationFullAddress(
                          errors && errors.address && errors.address.community && touched.address && touched.address.community,
                          errors && errors.address && errors.address.town && touched.address && touched.address.town,
                          errors && errors.address && errors.address.street && touched.address && touched.address.street,
                          errors && errors.address && errors.address.buildingNumber && touched.address && touched.address.buildingNumber,
                        )}
                        readonly={isLoading}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.fulladdress && touched.fulladdress && errors.fulladdress }
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <SelectField
                        field={{
                          name: 'producerType',
                          placeholder: 'Rodzaj podmiotu',
                          options: [
                            {
                              label: 'Gospodarstwo domowe',
                              value: 'household',
                            },
                            {
                              label: 'Firma / Instytucja',
                              value: 'company',
                            },
                          ],
                        }}
                        value={values.producerType}
                        error={errors && errors.producerType && touched.producerType}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.producerType && touched.producerType && errors.producerType }
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <SelectField
                        field={{
                          name: 'agglomeration',
                          placeholder: 'Należy do aglomeracji',
                          options: [
                            {
                              label: 'Nie',
                              value: false,
                            },
                            {
                              label: 'Tak',
                              value: true,
                            },
                          ],
                        }}
                        value={values.agglomeration}
                        error={errors && errors.agglomeration && touched.agglomeration}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.agglomeration && touched.agglomeration && errors.agglomeration }
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'numberOfRegistered',
                          placeholder: 'Liczba zameldowanych',
                        }}
                        value={values.numberOfRegistered}
                        error={errors && errors.numberOfRegistered && touched.numberOfRegistered}
                        handleChange={handleChange}
                        type="number"
                        readonly={values.producerType === 'company'}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.numberOfRegistered && touched.numberOfRegistered && errors.numberOfRegistered }
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'numberOfInhabitants',
                          placeholder: 'Liczba zamieszkujących',
                        }}
                        value={values.numberOfInhabitants}
                        error={errors && errors.numberOfInhabitants && touched.numberOfInhabitants}
                        handleChange={handleChange}
                        type="number"
                        readonly={values.producerType === 'company'}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.numberOfInhabitants && touched.numberOfInhabitants && errors.numberOfInhabitants }
                      </SuiTypography>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </Card>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card>
                  <SuiBox sx={{ padding: 4 }}>
                    <SuiTypography sx={{ marginBottom: 2 }} variant="subtitle2">
                      Dane o nieczystościach
                    </SuiTypography>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <SelectField
                        field={{
                          name: 'impuritiesData.impuritiesType',
                          placeholder: 'Rodzaj dostarczanych nieczystości',
                          options: [
                            {
                              label: 'Bytowe',
                              value: 'household',
                            },
                            {
                              label: 'Przemysłowe',
                              value: 'industrial',
                            },
                          ],
                        }}
                        value={values.impuritiesData.impuritiesType}
                        error={errors && errors.impuritiesData && errors.impuritiesData.impuritiesType && touched.impuritiesData && touched.impuritiesData.impuritiesType}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.impuritiesData && errors.impuritiesData.impuritiesType && touched.impuritiesData && touched.impuritiesData.impuritiesType && errors.impuritiesData.impuritiesType}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <SelectField
                        field={{
                          name: 'impuritiesData.impuritiesTankType',
                          placeholder: 'Typ zbiornika nieczystości',
                          options: [
                            {
                              label: 'Zbiornik bezodpływowy',
                              value: 'drainless',
                            },
                            {
                              label: 'Przydomowa oczyszczalnia ścieków',
                              value: 'household_sewage',
                            },
                            {
                              label: 'Toi Toi',
                              value: 'toi_toi',
                            },
                          ],
                        }}
                        value={values.impuritiesData.impuritiesTankType}
                        error={errors && errors.impuritiesData && errors.impuritiesData.impuritiesTankType && touched.impuritiesData && touched.impuritiesData.impuritiesTankType}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.impuritiesData && errors.impuritiesData.impuritiesTankType && touched.impuritiesData && touched.impuritiesData.impuritiesTankType && errors.impuritiesData.impuritiesTankType}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'impuritiesData.capacity',
                          placeholder: 'Pojemność zbiornika nieczystości',
                        }}
                        value={values.impuritiesData.capacity}
                        error={errors && errors.impuritiesData && errors.impuritiesData.capacity && touched.impuritiesData && touched.impuritiesData.capacity}
                        handleChange={handleChange}
                        type="number"
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.impuritiesData && errors.impuritiesData.capacity && touched.impuritiesData && touched.impuritiesData.capacity && errors.impuritiesData.capacity}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <SuiBox sx={{ padding: 4 }}>
                    <SuiTypography sx={{ marginBottom: 2 }} variant="subtitle2">
                      Dane o wodzie
                    </SuiTypography>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <SelectField
                        field={{
                          name: 'waterData.collectionMethod',
                          placeholder: 'Sposób poboru wody',
                          options: [
                            {
                              label: 'Przyłącze wodociągowe',
                              value: 'water',
                            },
                            {
                              label: 'Studnia głębinowa',
                              value: 'well',
                            },
                          ],
                        }}
                        value={values.waterData.collectionMethod}
                        error={errors && errors.waterData && errors.waterData.collectionMethod && touched.waterData && touched.waterData.collectionMethod}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.waterData && errors.waterData.collectionMethod && touched.waterData && touched.waterData.collectionMethod && errors.waterData.collectionMethod}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <TagField
                        field={{
                          name: 'waterData.mainCounter',
                          placeholder: 'Liczniki główne (wciśnij enter aby wprowadzić kolejny)',
                        }}
                        value={values.waterData.mainCounter}
                        error={errors && errors.waterData && errors.waterData.mainCounter && touched.waterData && touched.waterData.mainCounter}
                        handleChange={handleChange}
                        readonly={values.waterData.collectionMethod === 'well'}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.waterData && errors.waterData.mainCounter && touched.waterData && touched.waterData.mainCounter && errors.waterData.mainCounter}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TagField
                        field={{
                          name: 'waterData.gardenCounter',
                          placeholder: 'Liczniki ogrodowe (wciśnij enter aby wprowadzić kolejny)',
                        }}
                        value={values.waterData.gardenCounter}
                        error={errors && errors.waterData && errors.waterData.gardenCounter && touched.waterData && touched.waterData.gardenCounter}
                        handleChange={handleChange}
                        readonly={values.waterData.collectionMethod === 'well'}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.waterData && errors.waterData.gardenCounter && touched.waterData && touched.waterData.gardenCounter && errors.waterData.gardenCounter}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <SuiBox sx={{ padding: 4 }}>
                    <SuiTypography sx={{ marginBottom: 2 }} variant="subtitle2">
                      Dane kontaktowe
                    </SuiTypography>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                      <TextField
                        field={{
                          name: 'contactData.person',
                          placeholder: 'Osoba do kontaktu',
                        }}
                        value={values.contactData.person}
                        error={errors && errors.contactData && errors.contactData.person && touched.contactData && touched.contactData.person}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contactData && errors.contactData.person && touched.contactData && touched.contactData.person && errors.contactData.person}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <TextField
                        field={{
                          name: 'contactData.phone',
                          placeholder: 'Numer telefonu',
                        }}
                        value={values.contactData.phone}
                        error={errors && errors.contactData && errors.contactData.phone && touched.contactData && touched.contactData.phone}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contactData && errors.contactData.phone && touched.contactData && touched.contactData.phone && errors.contactData.phone}
                      </SuiTypography>
                    </SuiBox>
                    <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative', marginBottom: 2 }}>
                      <TextField
                        field={{
                          name: 'contactData.email',
                          placeholder: 'Adres email',
                        }}
                        value={values.contactData.email}
                        error={errors && errors.contactData && errors.contactData.email && touched.contactData && touched.contactData.email}
                        handleChange={handleChange}
                      />
                      <SuiTypography component="div" color="error" variant="caption">
                        {errors && errors.contactData && errors.contactData.email && touched.contactData && touched.contactData.email && errors.contactData.email}
                      </SuiTypography>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            </Grid>
            <Grid justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
              <SuiButton
                sx={{ marginRight: 2 }}
                onClick={() => navigate('/producer')}
                size="large"
                color="white"
                disabled={mutation.isLoading}
              >
                Anuluj
              </SuiButton>
              <SuiButton
                type="submit"
                size="large"
                color="info"
                variant="gradient"
                disabled={mutation.isLoading}
              >
                { mutation.isLoading && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} /> }
                Zapisz
              </SuiButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
};

export default ProducerAdd;
