/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default styled(Box)(({ theme, ownerState }) => {
  const {
    palette, typography, functions,
  } = theme;
  const { color } = ownerState;

  const { alertColors } = palette;
  const { fontWeightMedium } = typography;
  const { pxToRem } = functions;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: pxToRem(60),
    color: alertColors[color].border,
    position: 'relative',
    padding: pxToRem(16),
    marginBottom: pxToRem(16),
    borderRadius: '12px',
    fontSize: '16px',
    fontWeight: fontWeightMedium,
    background: 'white',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  };
});
