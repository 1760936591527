import * as yup from 'yup';
import { ORGANIZATION_TYPE } from 'utils/constants';

const formSchema = (data) => ({
  fields: [
    {
      name: 'organization.name',
      placeholder: 'Nazwa firmy',
    },
    {
      name: 'organization.nip',
      placeholder: 'NIP',
    },
    {
      name: 'organization.address.street',
      placeholder: 'Ulica',
    },
    {
      name: 'organization.address.buildingNumber',
      placeholder: 'Numer budynku',
    },
    {
      name: 'organization.address.localNumber',
      placeholder: 'Numer lokalu',
    },
    {
      name: 'organization.address.city',
      placeholder: 'Miasto',
    },
    {
      name: 'organization.address.postCode',
      placeholder: 'Kod pocztowy',
    },
    {
      name: 'organization.phoneNumber',
      placeholder: 'Numer telefonu',
    },
    {
      name: 'organization.type',
      placeholder: 'Rodzaj organizacij',
      type: 'select',
      options: Object.entries(ORGANIZATION_TYPE).map((item) => ({
        label: item[1].label,
        value: item[1].value,
      })),
    },
    {
      name: 'organization.territories',
      placeholder: 'Terytorium',
      type: 'select',
      isMulti: true,
      options: data && data.data ? data.data.map((item) => ({
        label: `${item.name}, ${item.additionalName}`,
        value: item._id,
      })) : [],
    },
    {
      name: 'user.name',
      placeholder: 'Imię',
    },
    {
      name: 'user.surname',
      placeholder: 'Nazwisko',
    },
    {
      name: 'user.email',
      placeholder: 'Email',
    },
  ],
  button: {
    fullWidth: false,
    label: 'Zapisz',
    size: 'large',
    variant: 'gradient',
    color: 'info',
  },
});

const validationSchema = yup.object({
  organization: yup.object().shape({
    name: yup.string().required('To pole jest wymagane!'),
    nip: yup.string().required('To pole jest wymagane!'),
    address: yup.object().shape({
      street: yup.string().required('To pole jest wymagane!'),
      buildingNumber: yup.string().required('To pole jest wymagane!'),
      city: yup.string().required('To pole jest wymagane!'),
      postCode: yup.string().required('To pole jest wymagane!'),
    }),
    phoneNumber: yup.string().required('To pole jest wymagane!'),
    type: yup.string().required('To pole jest wymagane!'),
    territories: yup.array().required('To pole jest wymagane!'),
  }),
  user: yup.object().shape({
    name: yup.string().required('To pole jest wymagane!'),
    surname: yup.string().required('To pole jest wymagane!'),
    email: yup.string().required('To pole jest wymagane!'),
  }),
});

const initialValues = {
  organization: {
    name: '',
    nip: '',
    phoneNumber: '',
    type: '',
    territories: [],
    address: {
      street: '',
      buildingNumber: '',
      city: '',
      postCode: '',
    },
  },
  user: {
    name: '',
    surname: '',
    email: '',
    role: 'admin',
    organizationType: '',
  },
};

export { formSchema, initialValues, validationSchema };
