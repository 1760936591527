import React from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Auth from 'api/auth';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import LayoutLogin from 'layouts/LayoutLogin';
import Gniezno from 'assets/img/gniezno.jpg';
import DataForm from 'components/DataForm';
import { setUserData, setMessage } from 'store';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import { getErrorFromApi } from 'utils/messages';
import { initialValues, validationSchema, formSchema } from './utils';

const Login = () => {
  const dispatch = useDispatch();
  const mutation = useMutation((data) => Auth.login(data), {
    onSuccess: (response) => {
      const { accessToken, refreshToken } = response.data;
      dispatch(
        setUserData({
          ACCESS_TOKEN: accessToken,
          REFRESH_TOKEN: refreshToken,
        }),
      );
    },
    onError: (err) => {
      const { data } = err.response;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(data),
        }),
      );
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  return (
    <LayoutLogin image={Gniezno}>
      <Card>
        <SuiBox p={3} mb={1} textAlign="center" className="login-card-top">
          <Logo className="login-logo" />
          <SuiTypography variant="h5" fontWeight="medium">
            Podaj dane logowania
          </SuiTypography>
        </SuiBox>
        <SuiBox className="login-card" p={3}>
          <DataForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            formSchema={formSchema}
            fetching={mutation.isLoading}
            submit={onSubmit}
          />
        </SuiBox>
      </Card>
    </LayoutLogin>
  );
};

export default Login;
