import React from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import OrganizationAPI from 'api/organization';
import CloseIcon from '@mui/icons-material/Close';
import SoftDatePicker from 'components/SuiDatePicker';
import Card from '@mui/material/Card';
import { Polish } from 'flatpickr/dist/l10n/pl';
import moment from 'moment';
import SuiInput from 'components/SuiInput';
import CalendarToday from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import SuiBox from 'components/SuiBox';
import { useParams } from 'react-router-dom';
import { getTableData } from './utils';

const CompanyDetailsCollections = () => {
  const { id } = useParams();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, ['company_collections', id], OrganizationAPI.getCompanyCollections);

  return (
    <SuiBox>
      <SuiBox sx={{
        padding: 4,
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <SuiBox sx={{ display: 'flex' }}>
          <SuiBox sx={{ marginRight: 2 }}>
            <SuiInput
              name="search"
              placeholder="Szukaj..."
              icon={{
                component: <SearchIcon />,
                direction: 'left',
              }}
              onChange={(event) => changeParam(event.target.name, event.target.value, true)}
              isLoading={isLoading}
            />
          </SuiBox>
          <SuiBox sx={{ marginRight: 2, position: 'relative' }}>
            <SoftDatePicker
              options={{
                locale: Polish,
                dateFormat: 'Y-m-d',
              }}
              onChange={([date]) => changeParam('date', moment(date).format('YYYY-MM-DD'), true)}
              value={params.date}
              input={{
                placeholder: 'Data odbioru',
                value: params.date,
                name: 'date',
                icon: {
                  component: <CalendarToday />,
                  direction: 'left',
                },
              }}
              isLoading={isLoading}
              onClear={() => changeParam('date', null, true)}
              noLabel
            />
            {
              params.date && (
                <CloseIcon
                  onClick={() => changeParam('date', null, true)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    cursor: 'pointer',
                  }}
                />
              )
            }
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <DataTable
            table={data && getTableData(data.data)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default CompanyDetailsCollections;
