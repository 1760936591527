import React from 'react';
import DataTable from 'components/DataTable';
import UserAPI from 'api/user';
import SuiInput from 'components/SuiInput';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import SuiButton from 'components/SuiButton';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {
  useNavigate,
} from 'react-router-dom';
import useDataTable from 'hooks/useDataTable';
import { getUserData } from './utils';

const User = () => {
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
    refetch,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
  }, 'user', UserAPI.userList);
  const navigate = useNavigate();

  return (
    <SuiBox>
      <SuiBox
        className="filter-box"
        sx={{
          padding: 4,
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'visible',
        }}
      >
        <SuiBox className="filter-box" sx={{ display: 'flex' }}>
          <SuiBox sx={{ marginRight: 2 }}>
            <SuiInput
              name="search"
              placeholder="Szukaj..."
              icon={{
                component: <SearchIcon />,
                direction: 'left',
              }}
              onChange={(event) => changeParam(event.target.name, event.target.value, true)}
              isLoading={isLoading}
            />
          </SuiBox>
        </SuiBox>
        <SuiBox className="floating-button-panel">
          <SuiButton isLoading={isLoading} onClick={() => navigate('/user/add')} variant="gradient" color="info">
            Dodaj użytkownika
            <AddIcon sx={{ marginLeft: 2 }} />
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <Card>
        <SuiBox sx={{ padding: 4 }}>
          <DataTable
            table={data && getUserData(data, refetch)}
            loading={isLoading}
            paginationPage={params.page}
            sortPage={params.sort}
            changeParam={changeParam}
            count={count}
            isLoading={isLoading}
            isFetching={isFetching}
            limit={params.limit}
          />
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default User;
