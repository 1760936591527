import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import SearchIcon from '@mui/icons-material/Search';
import DialogContent from '@mui/material/DialogContent';
import SuiInput from 'components/SuiInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogContentText from '@mui/material/DialogContentText';
import SuiButton from 'components/SuiButton';
import { getErrorFromApi } from 'utils/messages';
import DialogTitle from '@mui/material/DialogTitle';
import SuiBox from 'components/SuiBox';
import { setMessage } from 'store';
import { useDispatch } from 'react-redux';
import { getFullAddress } from 'utils/format';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import ProducerAPI from 'api/producer';
import { useQuery, useMutation } from 'react-query';

let timer = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RemoveProducerModal = ({
  open,
  closeDialog,
  street,
  producerId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(street);
  const [newProducer, setNewProducer] = useState(null);

  const { data, isLoading, refetch } = useQuery(
    ['producers_remove_popup', street],
    () => ProducerAPI.getAll({ limit: 5000, page: 1, search: `${search}` }),
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  const contracts = useQuery(
    ['producer_contracts', producerId],
    () => ProducerAPI.getProducerContracts({ limit: 20, page: 1, search: '' }, producerId),
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  const mutation = useMutation(() => ProducerAPI.removeProducer(newProducer, producerId), {
    onSuccess: (response) => {
      setNewProducer(null);
      closeDialog();
      navigate(`/producer/${newProducer.id}/details`);
      dispatch(
        setMessage({
          variant: 'success',
          title: response.data.message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const permanentMutation = useMutation(() => ProducerAPI.remove(producerId), {
    onSuccess: (response) => {
      setNewProducer(null);
      closeDialog();
      navigate('/producer');
      dispatch(
        setMessage({
          variant: 'success',
          title: response.data.message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      refetch();
    }, 500);
  }, [search]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => { closeDialog(); setNewProducer(null); }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Usuwanie producenta</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'left', marginBottom: 2 }}>
          {contracts && contracts.data && contracts.data.data && contracts.data.data.count > 0 ? 'Usuwany podmiot posiada historię umów i odbiorów nieczytości. Wskaż producenta, któremu mają zostać przypisane po usunięciu.' : 'Czy na pewno chcesz permanentnie usunąć ten podmiot z listy producentów nieczystości? Wszelkie dane zostaną utracone.'}
        </DialogContentText>
        {
          contracts && contracts.data && contracts.data.data && contracts.data.data.count > 0 && (
            newProducer ? (
              <SuiBox
                sx={{
                  marginTop: 2,
                  padding: 2,
                  border: '1px solid #e9ecef',
                  borderRadius: 4,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {`Wybrano: ${newProducer.fullAddress}`}
                <SuiButton onClick={() => setNewProducer(null)} variant="link">Zmień</SuiButton>
              </SuiBox>
            ) : (
              <>
                <SuiInput
                  value={search}
                  name="search"
                  placeholder="Szukaj..."
                  icon={{
                    component: <SearchIcon />,
                    direction: 'left',
                  }}
                  onChange={(event) => setSearch(event.target.value)}
                  isLoading={isLoading}
                />
                <List sx={{ marginTop: 2, maxHeight: '400px', overflowY: 'auto' }}>
                  {
                    data && data.data && (
                      data.data.results.map((item) => (
                        item._id !== producerId && (
                          <ListItem onClick={() => setNewProducer({ id: item._id, fullAddress: getFullAddress(item.address) })} className="listItem" key={item._id}>
                            {getFullAddress(item.address)}
                          </ListItem>
                        )
                      ))
                    )
                  }
                </List>
              </>
            )
          )
        }
      </DialogContent>
      {
        contracts && contracts.data && contracts.data.data && contracts.data.data.count > 0 ? (
          <DialogActions>
            <SuiButton
              isFetching={mutation.isLoading}
              onClick={() => { closeDialog(); setNewProducer(null); }}
            >
              Anuluj
            </SuiButton>
            <SuiButton
              isFetching={mutation.isLoading}
              disabled={!newProducer}
              variant="gradient"
              color="info"
              onClick={() => mutation.mutate()}
            >
              Usuń producenta
            </SuiButton>
          </DialogActions>
        ) : (
          <DialogActions>
            <SuiButton
              isFetching={permanentMutation.isLoading}
              onClick={() => { closeDialog(); setNewProducer(null); }}
            >
              Nie, cofnij
            </SuiButton>
            <SuiButton
              isFetching={permanentMutation.isLoading}
              variant="gradient"
              color="info"
              onClick={() => permanentMutation.mutate()}
            >
              Tak, usuń producenta
            </SuiButton>
          </DialogActions>
        )
      }
    </Dialog>
  );
};

RemoveProducerModal.defaultProps = {
  open: false,
  closeDialog: () => null,
  street: '',
};

RemoveProducerModal.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  street: PropTypes.string,
  producerId: PropTypes.string.isRequired,
};

export default RemoveProducerModal;
