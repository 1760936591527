import api from './index';

const ProducerAPI = {
  household: async (id) => api.get(`/producer/${id}/`),
  edit: async (data, id) => api.patch(`/producer/${id}/preview/`, data),
  add: async (data) => api.post('/producer/add/', data),
  address: async () => api.get('/producer/address/grouped/'),
  remove: async (id) => api.delete(`/producer/delete/${id}/`),
  getAll: async (params) => api.get('/producer', { params }),
  getProducer: async (id) => api.get(`/producer/${id}`),
  getProducerCollections: async (params, id) => api.get(`/producer/${id}/collections`, { params }),
  getProducerContracts: async (params, id) => api.get(`/producer/${id}/contracts`, { params }),
  getProducerMeasurements: async (params, id) => api.get(`/producer/${id}/measurements`, { params }),
  getProducerBalances: async (params, id) => api.get(`/producer/${id}/balances`, { params }),
  createProducer: async (data) => api.post('/producer/', data),
  updateProducer: async (data, id) => api.put(`/producer/${id}`, data),
  getAlerts: async (id) => api.get(`/producer/${id}/alerts`),
  getAllProducers: async (params) => api.get('/producer/all', { params }),
  removeProducer: async (newProducer, producerId) => api.post(`/producer/${producerId}/remove`, { newProducer: newProducer.id }),
  deactivateProducer: async (producerId, date) => api.patch(`/producer/deactivate/${producerId}`, { date }),
  reactiveProducer: async (producerId) => api.patch(`/producer/reactive/${producerId}`, {}),
};

export default ProducerAPI;
