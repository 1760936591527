import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

let timer = null;

const useDataTable = (dataParams, key, action) => {
  const { id } = useParams();
  const [params, setParams] = useState({
    limit: 20,
    ...dataParams,
  });
  const {
    data,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(key, () => action(params, id), { refetchOnMount: false, refetchOnWindowFocus: false });

  const changeParam = (name, value, isText = false) => {
    if (name === 'page') {
      setParams({
        ...params,
        [name]: value,
      });
    } else if (isText) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setParams({
          ...params,
          page: 1,
          [name]: value,
        });
      }, 500);
    } else {
      setParams({
        ...params,
        page: 1,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    refetch();
  }, [params]);

  return {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count: data && data.data && data.data.count ? data.data.count : 0,
    capacitySum: data && data.data && data.data.capacitySum ? data.data.capacitySum : 0,
    refetch,
    setParams,
  };
};

export default useDataTable;
