import * as yup from 'yup';

const initialValues = {
  receipts: [
    {
      key: Date.now(),
      date: '',
      vehicle: '',
      vehicleData: {
        id: '',
        number: '',
      },
      clientData: {
        address: {
          community: '',
          street: '',
          buildingNumber: '',
          localNumber: '',
          town: '',
        },
      },
      territory: '',
      impuritiesType: '',
      client: '',
      capacity: '',
    },
  ],
};

const validationSchema = yup.object().shape({
  receipts: yup.array().of(
    yup.object().shape({
      date: yup.string().required('To pole jest wymagane!'),
      vehicle: yup.string().required('To pole jest wymagane!'),
      client: yup.string().required('To pole jest wymagane!'),
      capacity: yup.string().required('To pole jest wymagane!'),
    }),
  ),
});

export { initialValues, validationSchema };
