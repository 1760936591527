import React, { useEffect, useState } from 'react';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import SelectField from 'components/DataForm/SelectField';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from 'components/DataForm/TextField';
import SuiButton from 'components/SuiButton';
import CompanyAPI from 'api/company';
import { Formik, Form, useFormikContext } from 'formik';
import { getErrorFromApi } from 'utils/messages';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store';
import { useMutation } from 'react-query';
import { initialValues, validationSchema, getYears } from './utils';

const FormObserver = ({ checkStatus }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (values.year && values.quarter) {
      checkStatus(values);
    }
  }, [values.year, values.quarter]);

  return null;
};

const CompanyStatement = () => {
  const dispatch = useDispatch();
  const [sum, setSum] = useState({
    collection: null,
    dump: null,
  });
  const mutation = useMutation((formData) => CompanyAPI.checkStatementStatus(formData), {
    onSuccess: (response) => {
      const { capacitySum, dumpsSum } = response.data;
      setSum({ collection: capacitySum, dump: dumpsSum });
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const mutationStatement = useMutation((formData) => CompanyAPI.generateStatemen(formData), {
    onSuccess: (response) => {
      const fileURL = window.URL.createObjectURL(
        new Blob(
          [response.data],
          { type: 'application/pdf' },
        ),
      );
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const fileName = response.headers['content-disposition'].split('=')[1];
      fileLink.setAttribute('download', fileName);
      fileLink.setAttribute('target', '_blank');
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
    },
    onError: (err) => {
      const errorData = err.response.data;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(errorData),
        }),
      );
    },
  });

  const submit = (values) => {
    mutationStatement.mutate(values);
  };

  const checkStatus = (values) => {
    mutation.mutate(values);
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ marginTop: 8 }}>
      {
        (mutation.isLoading || mutationStatement.isLoading) && (
          <SuiBox sx={{
            top: 0,
            left: 0,
            zIndex: 100000,
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,.6)',
          }}
          >
            <CircularProgress color="secondary" />
          </SuiBox>
        )
      }
      <SuiBox sx={{ display: 'flex', justifyContent: 'center' }}>
        <SuiTypography variant="subtitle2">
          Wybierz okres sprawozdawczy
        </SuiTypography>
      </SuiBox>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => submit(values)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
        }) => (
          <Form>
            <FormObserver checkStatus={checkStatus} />
            <SuiBox>
              <SuiBox sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                gap: 2,
                flexDirection: isMobile() && 'column',
              }}
              >
                <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                  <SelectField
                    field={{
                      name: 'quarter',
                      placeholder: 'Kwartał',
                      options: [
                        {
                          label: 'I kwartał',
                          value: ['01-01', '03-31'],
                        },
                        {
                          label: 'II kwartał',
                          value: ['04-01', '06-30'],
                        },
                        {
                          label: 'III kwartał',
                          value: ['07-01', '09-30'],
                        },
                        {
                          label: 'IV kwartał',
                          value: ['10-01', '12-31'],
                        },
                      ],
                    }}
                    value={values.quarter}
                    error={errors && errors.quarter && touched.quarter && errors.quarter}
                    handleChange={handleChange}
                  />
                  <SuiTypography component="div" color="error" variant="caption">
                    {errors && errors.quarter && touched.quarter && errors.quarter}
                  </SuiTypography>
                </SuiBox>
                <SuiBox sx={{ padding: '36px 0 0 0', position: 'relative' }}>
                  <SelectField
                    field={{
                      name: 'year',
                      placeholder: 'Rok',
                      options: getYears(),
                    }}
                    value={values.year}
                    error={errors && errors.year && touched.year && errors.year}
                    handleChange={handleChange}
                  />
                  <SuiTypography component="div" color="error" variant="caption">
                    {errors && errors.year && touched.year && errors.year}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              {
                sum.collection !== sum.dump && (
                  <SuiBox sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '800px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  >
                    <SuiTypography sx={{ marginBottom: 2 }} variant="subtitle2">
                      {`Wykryto różnicę sumy nieczystości odebranych (${sum.collection}m3) względem zrzuconych (${sum.dump}m3). Dodaj wyjaśnienia.`}
                    </SuiTypography>
                    <TextField
                      field={{
                        name: 'explains',
                      }}
                      multiline
                      rows={10}
                      handleChange={handleChange}
                      error={errors && errors.explains && touched.explains && errors.explains}
                    />
                    <SuiTypography component="div" color="error" variant="caption">
                      {errors && errors.explains && touched.explains && errors.explains}
                    </SuiTypography>
                  </SuiBox>
                )
              }
            </SuiBox>
            <SuiBox className="floating-button-panel" sx={{ marginTop: 4, display: 'flex', justifyContent: !isMobile() && 'center' }}>
              {
                sum.collection !== null && sum.dump !== null && (
                  <SuiButton
                    variant="gradient"
                    color="info"
                    isFetching={mutation.isLoading}
                    type="submit"
                  >
                    Drukuj sprawozdanie
                  </SuiButton>
                )
              }
            </SuiBox>
          </Form>
        )}
      </Formik>
    </SuiBox>
  );
};

export default CompanyStatement;
