import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import CompanyAPI from 'api/company';
import confirmModal from 'components/Modal/ConfirmModal';
import SuiButton from 'components/SuiButton';

const removeCollection = (id, refetch) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz usunąć ten odbiór ?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await CompanyAPI.deleteCollection(id);
      refetch();
    },
    successText: 'Odbiór został usunięty.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

const getTableData = (data, refetch) => {
  const { results } = data;
  const tableData = {
    columns: [
      {
        Header: 'Data odbioru',
        accessor: 'date',
        sortable: true,
        key: 'collections.date',
      },
      {
        Header: 'Pojazd odbierający',
        accessor: 'truck',
        sortable: true,
        key: 'collections.truck.number',
      },
      {
        Header: 'Klient',
        accessor: 'client',
        sortable: true,
        key: 'collections.client.address.street',
      },
      {
        Header: 'Rodzaj odebranych nieczystości',
        accessor: 'impuritiesType',
        sortable: true,
        key: 'collections.impuritiesType',
      },
      {
        Header: 'Objętość (m3)',
        accessor: 'capacity',
        sortable: true,
        key: 'collections.capacity',
      },
      {
        Header: 'Usuń',
        accessor: 'delete',
        width: '8%',
        key: 'delete',
      },
    ],
    rows: results.map((row) => ({
      ...row,
      date: moment(row.date).format('YYYY-MM-DD'),
      impuritiesType: row.impuritiesType === 'household' ? 'Bytowe' : 'Przemysłowe',
      client: `${row.client.address.street} ${row.client.address.buildingNumber}${row.client.address.localNumber ? `/${row.client.address.localNumber}` : ''}, ${row.client.address.community}`,
      capacity: `${row.capacity}m3`,
      delete: (
        <Tooltip title={row.status === 'sent' || row.status === 'approved' ? 'Ten odbiór jest powiązany z wysłanym lub zatwierdzonym raportem więc nie może zostać usunięty.' : 'Usuń'} placement="top">
          <span>
            <SuiButton
              disabled={row.status === 'sent' || row.status === 'approved'}
              onClick={() => removeCollection(row.id, refetch)}
              variant="outlined"
              color="error"
              circular
              iconOnly
            >
              <DeleteIcon fontSize="large" />
            </SuiButton>
          </span>
        </Tooltip>
      ),
    })),
  };
  return tableData;
};

export { getTableData };
