import Swal from 'sweetalert2';

const toastMessage = (variant, message) => Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
}).fire({
  icon: variant,
  title: message,
});

export default toastMessage;
