import React from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import CompanyAPI from 'api/company';
import SuiInput from 'components/SuiInput';
import SearchIcon from '@mui/icons-material/Search';
import SoftDatePicker from 'components/SuiDatePicker';
import SuiBox from 'components/SuiBox';
import CloseIcon from '@mui/icons-material/Close';
import SuiSelect from 'components/SuiSelect';
import { Polish } from 'flatpickr/dist/l10n/pl';
import moment from 'moment';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { reportStatus } from 'utils/constants';
import { getTableData } from './utils';

const CompanyReports = () => {
  const {
    params,
    changeParam,
    data,
    isLoading,
    count,
    isFetching,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
    date: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  }, 'company_reports', CompanyAPI.getReports);

  return (
    <SuiBox>
      <SuiBox className="filter-box" sx={{ padding: 4, display: 'flex' }}>
        <SuiBox sx={{ marginRight: 2 }}>
          <SuiInput
            name="search"
            placeholder="Szukaj..."
            icon={{
              component: <SearchIcon />,
              direction: 'left',
            }}
            onChange={(event) => changeParam(event.target.name, event.target.value, true)}
            isLoading={isLoading}
            defaultValue={params.search}
          />
        </SuiBox>
        <SuiBox sx={{ marginRight: 2, position: 'relative', minWidth: 300 }}>
          <SoftDatePicker
            options={{
              locale: Polish,
              dateFormat: 'Y-m-d',
              mode: 'range',
            }}
            onClose={([dateFrom, dateTo]) => changeParam('date', [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')], true)}
            value={params.date}
            input={{
              placeholder: 'Data raportu',
              value: params.date,
              name: 'date',
              icon: {
                component: <CalendarToday />,
                direction: 'left',
              },
            }}
            noLabel
          />
          {
            params.date && (
              <CloseIcon
                onClick={() => changeParam('date', null, true)}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 10,
                  cursor: 'pointer',
                }}
              />
            )
          }
        </SuiBox>
        <SuiBox sx={{ marginRight: 2 }}>
          <SuiSelect
            value={params.status}
            placeholder={params.status || 'Status'}
            name="status"
            onChange={(event) => changeParam('status', event.value)}
            options={Object.entries(reportStatus()).map((item) => ({
              label: item[1].label,
              value: item[0],
            }))}
            isLoading={isLoading}
          />
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <DataTable
          table={data && getTableData(data.data)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          limit={params.limit}
          isFetching={isFetching}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyReports;
