import * as yup from 'yup';
import PasswordIcon from '@mui/icons-material/Password';

const formSchema = {
  fields: [
    {
      name: 'password',
      placeholder: 'Hasło',
      icon: <PasswordIcon />,
      type: 'password',
    },
    {
      name: 'confirm_password',
      placeholder: 'Potwierdź hasło',
      icon: <PasswordIcon />,
      type: 'password',
    },
  ],
  button: {
    fullWidth: true,
    label: 'Potwierdź hasło',
    size: 'large',
    variant: 'gradient',
    color: 'info',
  },
};

const validationSchema = yup.object({
  password: yup.string().min(6, 'Hasło musi mieć co najniej 6 znaków!').required('To pole jest wymagane!'),
  confirm_password: yup.string().min(6, 'Hasło musi mieć co najniej 6 znaków!').required('To pole jest wymagane!').oneOf([yup.ref('password'), null], 'Podane hasła nie są takie same!'),
});

const initialValues = { password: '', confirm_password: '' };

export { initialValues, validationSchema, formSchema };
