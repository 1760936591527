import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import PageLayout from './PageLayout';

const LayoutLogin = ({
  title, description, image, children,
}) => (
  <PageLayout>
    <SuiBox
      width="calc(100% - 2rem)"
      minHeight="50vh"
      borderRadius="lg"
      mx={2}
      my={2}
      pt={6}
      pb={28}
      sx={{
        backgroundImage: () => image
          && `linear-gradient(90deg, rgba(195, 211, 56, .8) 0%, rgba(35, 179, 160, .8) 50%, rgba( 36, 126, 175, .6) 100%), url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid container spacing={3} justifyContent="center" sx={{ textAlign: 'center' }}>
        <Grid item xs={10} lg={4}>
          <SuiBox mt={6} mb={1}>
            <SuiTypography variant="h1" color="white" fontWeight="bold">
              {title}
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={2}>
            <SuiTypography variant="body2" color="white" fontWeight="regular">
              {description}
            </SuiTypography>
          </SuiBox>
        </Grid>
      </Grid>
    </SuiBox>
    <SuiBox mt={{ xs: -39, lg: -39 }} px={1} width="calc(100% - 2rem)" mx="auto">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={11} sm={9} md={9} lg={6} xl={4}>
          {children}
        </Grid>
      </Grid>
    </SuiBox>
  </PageLayout>
);

LayoutLogin.defaultProps = {
  title: '',
  description: '',
};

LayoutLogin.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LayoutLogin;
