import * as yup from 'yup';
import moment from 'moment';

const getYears = () => {
  const years = [];
  let startYear = 2023;
  while (startYear <= moment().year()) {
    years.push({
      label: `${startYear}`,
      value: startYear,
    });
    startYear += 1;
  }
  return years;
};

const initialValues = {
  quarter: '',
  year: '',
  explains: '',
};

const validationSchema = yup.object({
  quarter: yup.array().required('To pole jest wymagane!'),
  year: yup.string().required('To pole jest wymagane!'),
});

export { initialValues, validationSchema, getYears };
