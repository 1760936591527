import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export default styled(Drawer)(({ theme, ownerState }) => {
  const {
    palette, boxShadows, transitions, breakpoints, functions,
  } = theme;
  const { transparentSidenav, miniSidenav, hideMobileMenu } = ownerState;

  const sidebarWidth = 260;
  const { white, transparent } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;
  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    position: 'static',
    backgroundColor: transparentSidenav ? transparent.main : white.main,
    boxShadow: transparentSidenav ? 'none' : xxl,
    marginBottom: transparentSidenav ? 0 : 'inherit',
    left: '0',
    width: sidebarWidth,
    transform: 'translateX(0)',
    transition: transitions.create(['width', 'background-color'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),

    [breakpoints.up('xl')]: {
      backgroundColor: transparentSidenav ? transparent.main : white.main,
      boxShadow: transparentSidenav ? 'none' : xxl,
      marginBottom: transparentSidenav ? 0 : 'inherit',
      left: '0',
      width: sidebarWidth,
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    '@media screen and (max-width: 768px)': {
      position: 'fixed',
      margin: 0,
      left: hideMobileMenu ? '100%' : 0,
      opacity: hideMobileMenu ? 0 : 1,
      top: 0,
      width: '100%',
      backgroundColor: 'white',
      padding: '24px',
      transition: '.3s',
      height: '100%',
      overflowY: 'auto !important',
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    position: 'static',
    backgroundColor: transparentSidenav ? transparent.main : white.main,
    boxShadow: transparentSidenav ? 'none' : xxl,
    marginBottom: transparentSidenav ? 0 : 'inherit',
    left: '0',
    width: pxToRem(96),
    overflowX: 'hidden',
    transform: 'translateX(0)',
    transition: transitions.create(['width', 'background-color'], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),
    '@media screen and (max-width: 768px)': {
      position: 'fixed',
      marginRight: '-100%',
      left: 0,
      top: 0,
      width: '100%',
      backgroundColor: 'white',
      padding: '24px',
    },
  });

  return {
    '& .MuiDrawer-paper': {
      justifyContent: 'space-between',
      boxShadow: xxl,
      border: 'none',
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
