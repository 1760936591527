import React, { useState } from 'react';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from 'components/DataForm/TextField';
import SelectField from 'components/DataForm/SelectField';
import SoftDatePicker from 'components/SuiDatePicker';
import SuiTypography from 'components/SuiTypography';
import CompanyAPI from 'api/company';
import OrganizationAPI from 'api/organization';
import SuiButton from 'components/SuiButton';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import CalendarToday from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { setMessage } from 'store';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { initialValues, validationSchema } from './utils';

const CompanyDumpsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initValues, setInitValues] = useState(initialValues);
  const trucksQuery = useQuery('company_vehicle', CompanyAPI.vehicle, { refetchOnMount: false, refetchOnWindowFocus: false });
  useQuery('catchpoint', OrganizationAPI.getCatchpoint, {
    onSuccess: (response) => {
      const { name, _id } = response.data;
      setInitValues({
        dumps: [{
          ...initValues.dumps[0],
          catchpointName: name,
          catchpoint: _id,
        }],
      });
    },
  });

  const mutation = useMutation((data) => CompanyAPI.addDumps(data), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/dump');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const addNewRow = (date, catchpoint, catchpointName, vehicle, vehicleData, values) => {
    setInitValues({
      dumps: [
        {
          key: Date.now(),
          date,
          vehicle,
          vehicleData,
          capacity: '',
          catchpoint,
          catchpointName,
        },
        ...values.dumps,
      ],
    });
  };

  const deleteRow = (key, values) => {
    setInitValues({
      dumps: values.dumps.filter((item) => item.key !== key),
    });
  };

  const trucksOptions = () => {
    if (trucksQuery && trucksQuery.data && trucksQuery.data.data) {
      const { results } = trucksQuery.data.data;
      return results.map((item) => ({
        label: item.number,
        value: item.number,
        id: item.id,
      }));
    }
    return [];
  };

  const submit = (formData) => {
    const dumps = formData.dumps.map((item) => ({
      date: item.date,
      truck: item.vehicleData,
      territory: item.territory,
      capacity: item.capacity.replaceAll(',', '.'),
      impuritiesType: item.impuritiesType,
      catchpoint: item.catchpoint,
    }));
    mutation.mutate({ dumps });
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', marginTop: !isMobile() && '32px' }}>
      <Card>
        <SuiBox className="add-collection" sx={{ padding: 4 }}>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <Form>
                <Grid
                  className="add-collection-labels"
                  sx={{ marginBottom: '-50px' }}
                  container
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <SuiTypography variant="subtitle2">
                      Data zrzutu
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiTypography variant="subtitle2">
                      Pojazd zrzucający
                    </SuiTypography>
                  </Grid>
                  {/* <Grid item xs={3}> */}
                  {/*  <SuiTypography variant="subtitle2"> */}
                  {/*    Gmina */}
                  {/*  </SuiTypography> */}
                  {/* </Grid> */}
                  <Grid item xs={3}>
                    <SuiTypography variant="subtitle2">
                      Rodzaj nieczytości
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={3}>
                    <SuiTypography variant="subtitle2">
                      Objętość (m3)
                    </SuiTypography>
                  </Grid>
                </Grid>
                {
                  values.dumps.map((item, index) => (
                    <Grid key={item.key} container spacing={2}>
                      <Grid item xs={12} md={3} mb={isMobile() && 2}>
                        <SoftDatePicker
                          options={{
                            locale: Polish,
                            dateFormat: 'Y-m-d',
                            maxDate: moment(new Date()).format('YYYY-MM-DD'),
                          }}
                          onChange={([date]) => {
                            handleChange({ target: { value: moment(date).format('YYYY-MM-DD'), name: `dumps[${index}].date` } });
                          }}
                          input={{
                            placeholder: isMobile() && 'Data zrzutu',
                            value: values.dumps[index].date,
                            name: `dumps[${index}].date`,
                            error: (errors.dumps && errors.dumps[index] && errors.dumps[index].date) && (touched.dumps && touched.dumps[index] && touched.dumps[index].date),
                            size: 'large',
                            icon: {
                              component: <CalendarToday />,
                              direction: 'left',
                            },
                          }}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.dumps && errors.dumps[index] && errors.dumps[index].date) && (touched.dumps && touched.dumps[index].date) && errors.dumps[index] && errors.dumps[index].date}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={3} mb={isMobile() && 2}>
                        <SelectField
                          field={{
                            name: `dumps[${index}].vehicle`,
                            options: trucksOptions(),
                            placeholder: isMobile() && 'Pojazd zrzucający',
                          }}
                          value={values.dumps[index].vehicle}
                          error={(errors.dumps && errors.dumps[index] && errors.dumps[index].vehicle) && (touched.dumps && touched.dumps[index] && touched.dumps[index].vehicle)}
                          handleChange={(event) => {
                            handleChange({ target: { value: { number: event.value, id: event.id }, name: `dumps[${index}].vehicleData` } });
                            handleChange(event);
                          }}
                          readonly={trucksQuery.isLoading}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.dumps && errors.dumps[index] && errors.dumps[index].vehicle) && (touched.dumps && touched.dumps[index] && touched.dumps[index].vehicle) && errors.dumps[index].vehicle}
                        </SuiTypography>
                      </Grid>
                      {/* <Grid item xs={3}> */}
                      {/*  <TextField */}
                      {/*    field={{ */}
                      {/*      name: `dumps[${index}].catchpointName`, */}
                      {/*    }} */}
                      {/*    value={values.dumps[index].catchpointName} */}
                      {/*    error={(errors.dumps && errors.dumps[index] && errors.dumps[index].catchpointName) && (touched.dumps && touched.dumps[index] && touched.dumps[index].catchpointName)} */}
                      {/*    handleChange={handleChange} */}
                      {/*    readonly */}
                      {/*  /> */}
                      {/*  <SuiTypography component="div" color="error" variant="caption"> */}
                      {/*    {(errors.dumps && errors.dumps[index] && errors.dumps[index].catchpointName) && (touched.dumps && touched.dumps[index] && touched.dumps[index].catchpointName) && errors.dumps[index].catchpointName} */}
                      {/*  </SuiTypography> */}
                      {/* </Grid> */}
                      <Grid item xs={12} md={3} mb={isMobile() && 2}>
                        <SelectField
                          field={{
                            name: `dumps[${index}].impuritiesType`,
                            options: [
                              {
                                label: 'Bytowe',
                                value: 'household',
                              },
                              {
                                label: 'Przemysłowe',
                                value: 'industrial',
                              },
                            ],
                            placeholder: isMobile() && 'Rodzaj nieczystości',
                          }}
                          value={values.dumps[index].impuritiesType}
                          error={(errors.dumps && errors.dumps[index] && errors.dumps[index].impuritiesType) && (touched.dumps && touched.dumps[index] && touched.dumps[index].impuritiesType)}
                          handleChange={handleChange}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.dumps && errors.dumps[index] && errors.dumps[index].impuritiesType) && (touched.dumps && touched.dumps[index] && touched.dumps[index].impuritiesType) && errors.dumps[index].impuritiesType}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={2} mb={isMobile() && 2}>
                        <TextField
                          field={{
                            name: `dumps[${index}].capacity`,
                            placeholder: isMobile() && 'Objętość (m3)',
                          }}
                          value={values.dumps[index].capacity}
                          error={(errors.dumps && errors.dumps[index] && errors.dumps[index].capacity) && (touched.dumps && touched.dumps[index] && touched.dumps[index].capacity)}
                          handleChange={handleChange}
                          autoComplete="off"
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.dumps && errors.dumps[index] && errors.dumps[index].capacity) && (touched.dumps && touched.dumps[index] && touched.dumps[index].capacity) && errors.dumps[index].capacity}
                        </SuiTypography>
                      </Grid>
                      <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} item xs={1}>
                        {
                          values.dumps.length > 1 && (
                            <SuiBox className="add-new-collection" sx={{ marginRight: 1 }}>
                              <Tooltip title="Usuń zrzut" placement="top">
                                <SuiButton onClick={() => deleteRow(item.key, values)} color="error" variant="outlined" circular iconOnly>
                                  <DeleteIcon fontSize="large" />
                                </SuiButton>
                              </Tooltip>
                            </SuiBox>
                          )
                        }
                        {
                          index === 0 && (
                            <Tooltip className="add-new-collection" title="Dodaj kolejny zrzut" placement="top">
                              <SuiButton onClick={() => addNewRow(values.dumps[index].date, values.dumps[index].catchpoint, values.dumps[index].catchpointName, values.dumps[index].vehicle, values.dumps[index].vehicleData, values)} variant="gradient" color="info" circular iconOnly>
                                <AddIcon fontSize="large" />
                              </SuiButton>
                            </Tooltip>
                          )
                        }
                      </Grid>
                    </Grid>
                  ))
                }
                <Grid className="add-collection-buttons" justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
                  <SuiButton
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate('/dump')}
                    size="large"
                    color="white"
                    disabled={mutation.isLoading}
                  >
                    Anuluj
                  </SuiButton>
                  <SuiButton
                    type="submit"
                    size="large"
                    color="info"
                    variant="gradient"
                    disabled={mutation.isLoading}
                  >
                    { mutation.isLoading && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} /> }
                    Zapisz
                  </SuiButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default CompanyDumpsAdd;
