import api from './index';

const ImportAPI = {
  import: async (formData) => api.post(
    '/counter/import',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  ),
};

export default ImportAPI;
