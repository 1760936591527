import store from 'store';
import api from './index';

const ReportAPI = {
  reports: async (params, id = null) => {
    const { user } = store.getState().app;
    return user.group !== 'Administrator firmy' && !id
      ? api.get('/report/', { params })
      : api.get(`/company/${id || user.company_id}/report/`, { params });
  },
  getReport: async (id) => {
    const { user } = store.getState().app;
    return user.group !== 'Administrator firmy'
      ? api.get(`/report/${id}/`)
      : api.get(`/company/${user.company_id}/report/${id}/`);
  },
  updateReport: async (data, id) => api.patch(`/report/${id}/`, data),
  getPdf: async (id, data) => api.post(`/organization/report/${id}/pdf`, data, { responseType: 'blob' }),
};

export default ReportAPI;
