import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'dropzone';
import CircularProgress from '@mui/material/CircularProgress';
import 'dropzone/dist/dropzone.css';
import SuiBox from 'components/SuiBox';
import SuiDropzoneRoot from 'components/SuiDropzone/SuiDropzoneRoot';

const SuiDropzone = ({ options, action, isLoading }) => {
  const dropzoneRef = useRef();

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, {
        ...options,
        addedfile: (file) => {
          const formData = new FormData();
          formData.append('file', file);
          action(formData);
        },
      });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    createDropzone();

    return () => removeDropzone();
  }, [options]);

  return (
    <SuiBox sx={{ width: '100%', height: '150px', position: 'relative' }}>
      {
        isLoading && (
          <SuiBox sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, .5)',
          }}
          >
            <CircularProgress size={80} color="info" />
          </SuiBox>
        )
      }
      <SuiDropzoneRoot
        component="form"
        action="/file-upload"
        ref={dropzoneRef}
        className="form-control dropzone"
      >
        <SuiBox className="fallback">
          <SuiBox component="input" name="file" type="file" multiple />
        </SuiBox>
      </SuiDropzoneRoot>
    </SuiBox>
  );
};

// Typechecking props for the SuiDropzone
SuiDropzone.defaultProps = {
  isLoading: false,
};

SuiDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  action: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default SuiDropzone;
