import React from 'react';
import useDataTable from 'hooks/useDataTable';
import DataTable from 'components/DataTable';
import CompanyAPI from 'api/company';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SoftDatePicker from 'components/SuiDatePicker';
import { Polish } from 'flatpickr/dist/l10n/pl';
import moment from 'moment';
import SuiInput from 'components/SuiInput';
import CalendarToday from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import { useNavigate } from 'react-router-dom';
import { getTableData } from './utils';

const CompanyDumps = () => {
  const navigate = useNavigate();
  const {
    params,
    changeParam,
    data,
    isLoading,
    isFetching,
    count,
    refetch,
  } = useDataTable({
    search: '',
    page: 1,
    sort: '',
    date: [moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  }, 'company_dump', CompanyAPI.dump);

  return (
    <SuiBox>
      <SuiBox className="filter-box" sx={{ padding: 4, display: 'flex', justifyContent: 'space-between' }}>
        <SuiBox className="filter-box" sx={{ display: 'flex' }}>
          <SuiBox sx={{ marginRight: 2 }}>
            <SuiInput
              name="search"
              placeholder="Szukaj..."
              icon={{
                component: <SearchIcon />,
                direction: 'left',
              }}
              onChange={(event) => changeParam(event.target.name, event.target.value, true)}
              isLoading={isLoading}
            />
          </SuiBox>
          <SuiBox sx={{ marginRight: 2, position: 'relative', minWidth: 300 }}>
            <SoftDatePicker
              options={{
                locale: Polish,
                dateFormat: 'Y-m-d',
                mode: 'range',
              }}
              onClose={([dateFrom, dateTo]) => changeParam('date', [moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD')], true)}
              value={params.date}
              input={{
                placeholder: 'Data zrzutu',
                value: params.date,
                name: 'date',
                icon: {
                  component: <CalendarToday />,
                  direction: 'left',
                },
              }}
              isLoading={isLoading}
              onClear={() => changeParam('date', null, true)}
              noLabel
            />
            {
              params.date && (
                <CloseIcon
                  onClick={() => changeParam('date', null, true)}
                  sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    cursor: 'pointer',
                  }}
                />
              )
            }
          </SuiBox>
        </SuiBox>
        <SuiBox className="floating-button-panel">
          <SuiButton isLoading={isLoading} onClick={() => navigate('/dump/add')} variant="gradient" color="info">
            Dodaj zrzut
            <AddIcon sx={{ marginLeft: 2 }} />
          </SuiButton>
        </SuiBox>
      </SuiBox>
      <SuiBox>
        <DataTable
          table={data && getTableData(data.data, refetch)}
          loading={isLoading}
          paginationPage={params.page}
          sortPage={params.sort}
          changeParam={changeParam}
          count={count}
          isLoading={isLoading}
          isFetching={isFetching}
          limit={params.limit}
        />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyDumps;
