import React from 'react';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import SuiBox from 'components/SuiBox';
import DataForm from 'components/DataForm';
import { useQuery, useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { setMessage } from 'store';
import UserAPI from 'api/user';
import { getErrorFromApi } from 'utils/messages';
import { validationSchema, formSchema } from '../utils';

const EditUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data } = useQuery(['get_user', id], () => UserAPI.getUser(id));
  const mutation = useMutation((useData) => UserAPI.edit(useData, id), {
    onSuccess: () => {
      navigate('/user');
      dispatch(
        setMessage({
          variant: 'success',
          title: 'Dane zostały zapisane.',
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });
  const onSubmit = (userData) => {
    mutation.mutate(userData);
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <Card sx={{ overflow: 'visible', marginTop: !isMobile() && '32px' }}>
      <SuiBox sx={{ padding: 2 }}>
        {
          data && data.data && (
            <DataForm
              initialValues={data.data}
              validationSchema={validationSchema}
              formSchema={formSchema}
              fetching={mutation.isLoading}
              submit={onSubmit}
              cancel="/user"
            />
          )
        }
      </SuiBox>
    </Card>
  );
};

export default EditUser;
