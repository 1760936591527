import React from 'react';
import SuiDropzone from 'components/SuiDropzone';
import { useDispatch } from 'react-redux';
import ImportAPI from 'api/import';
import Card from '@mui/material/Card';
import { setMessage } from 'store';
import { useMutation } from 'react-query';
import { getErrorFromApi } from 'utils/messages';

const Import = () => {
  const dispatch = useDispatch();
  const mutation = useMutation((data) => ImportAPI.import(data), {
    onSuccess: () => {
      dispatch(
        setMessage({
          variant: 'success',
          title: 'Import zakończył się sukcesem.',
        }),
      );
    },
    onError: (err) => {
      const { data } = err.response;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(data),
        }),
      );
    },
  });
  const onSubmit = (data) => {
    mutation.mutate(data);
  };
  return (
    <Card>
      <SuiDropzone
        options={{
          addRemoveLinks: true,
          dictDefaultMessage: 'WYBIERZ LUB UPUŚĆ PLIK',
          dictInvalidFileType: 'Nie można importować plików tego typu.',
          acceptedFiles: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
          maxFiles: 1,
          createImageThumbnails: false,
        }}
        action={onSubmit}
        isLoading={mutation.isLoading}
      />
    </Card>
  );
};

export default Import;
