import * as yup from 'yup';

const initialValues = {
  address: {
    territory: '',
    town: '',
    townId: '',
    street: '',
    community: '',
    communityId: '',
    buildingNumber: '',
    localNumber: '',
  },
  contract: {
    number: '',
    date: '',
    isActive: true,
    impuritiesType: '',
  },
  contact: {
    person: '',
    phone: '',
    email: '',
  },
};

const validationSchema = yup.object({
  address: yup.object().shape({
    town: yup.string().required('To pole jest wymagane!'),
    street: yup.string().required('To pole jest wymagane!'),
    community: yup.string().required('To pole jest wymagane!'),
    buildingNumber: yup.string().required('To pole jest wymagane!'),
  }),
  contract: yup.object().shape({
    date: yup.string().required('To pole jest wymagane!'),
    isActive: yup.string().required('To pole jest wymagane!'),
    impuritiesType: yup.string().required('To pole jest wymagane!'),
  }),
});

export { initialValues, validationSchema };
