import api from './index';

const AlertAPI = {
  getAlerts: async (params) => api.get('/alert', { params }),
  getAllAlerts: async () => api.get('/alert/all'),
  deleteAlert: async (alertId) => api.delete(`/alert/${alertId}`),
  updateAlert: async (alertId, data) => api.patch(`/alert/${alertId}`, data),
};

export default AlertAPI;
