import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ORGANIZATION_TYPE = {
  asenico: {
    value: 'asenico',
    label: 'Asenico',
  },
  catchpoint: {
    value: 'catchpoint',
    label: 'Punkt zlewny',
  },
  company: {
    value: 'company',
    label: 'Firma',
  },
  community: {
    value: 'community',
    label: 'Gmina',
  },
};

const reportStatus = () => ({
  '': {
    label: 'wszystkie',
  },
  new: {
    label: 'nowy',
    icon: <BrightnessHighIcon fontSize="medium" sx={{ color: '#C3D338', marginRight: '10px' }} />,
  },
  outstanding: {
    label: 'zaległy',
    icon: <AvTimerIcon fontSize="medium" sx={{ color: '#616161', marginRight: '10px' }} />,
  },
  sent: {
    label: 'wysłany',
    icon: <MailOutlineIcon fontSize="medium" sx={{ color: '#247EAF', marginRight: '10px' }} />,
  },
  rejected: {
    label: 'odrzucony',
    icon: <WarningAmberIcon fontSize="medium" sx={{ color: '#CF1322', marginRight: '10px' }} />,
  },
  approved: {
    label: 'zatwierdzony',
    icon: <CheckCircleOutlineIcon fontSize="medium" sx={{ color: '#419D21', marginRight: '10px' }} />,
  },
});

const catchpointReportStatus = Object.entries(reportStatus()).filter((item) => item[0] !== 'new');

export { reportStatus, ORGANIZATION_TYPE, catchpointReportStatus };
