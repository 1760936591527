import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SuiTypography from 'components/SuiTypography';
import PropTypes from 'prop-types';

const RadioButton = ({
  field,
  handleChange,
  value,
  error,
}) => (
  <>
    <FormControl className="radio-control">
      <FormLabel id="demo-row-radio-buttons-group-label">{field.placeholder}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={field.name}
        className="radio-group"
        value={value}
        onChange={handleChange}
      >
        {field.options.map((item) => (
          <FormControlLabel
            className="radio-label"
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {error && <SuiTypography sx={{ display: 'block' }} variant="caption" color="error">sldjflsdj</SuiTypography>}
  </>
);

RadioButton.defaultProps = {
  error: false,
  value: '',
};

RadioButton.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
};

export default RadioButton;
