const getErrorByCode = (error) => {
  const keyValue = Object.entries(error.keyValue)[0];
  switch (error.code) {
    case 11000:
      return `${keyValue[0]}: ${keyValue[1]} już istnieje w bazie.`;
    default:
      return '';
  }
};

const getErrorFromApi = (data) => {
  let message;
  if (data.detail) {
    message = data.detail;
  } else if (data.message && data.error) {
    message = `${data.message} ${getErrorByCode(data.error)}`;
  } else if (data.message) {
    message = data.message;
  } else if (data.error) {
    message = data.error;
  }
  // else if (Object.entries(data).length > 0) {
  //   Object.entries(data).forEach((item) => {
  //     message += `${TRANS[item[0]] ? TRANS[item[0]] : ''}: ${item[1][0]} `;
  //   });
  // }
  return message;
};

export { getErrorFromApi };
