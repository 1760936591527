import api from './index';

const CompanyAPI = {
  companyList: async (params) => api.get('/company/', { params }),
  receipts: async (params, id) => api.get(`/company/${id}/collection/`, { params }),
  dumps: async (params, id) => api.get(`/company/${id}/dump/`, { params }),
  vehicle: async (params) => api.get('/company/fleet', { params }),
  collection: async (params) => api.get('/company/collection', { params }),
  deleteVehicle: async (vehicleId) => api.delete(`/company/fleet/${vehicleId}`),
  editVehicle: async (data, vehicleId) => api.put(`/company/fleet/${vehicleId}`, data),
  edit: async (data, id) => api.patch(`/company/${id}/`, data),
  addVehicle: async (data) => api.post('/company/fleet', data),
  identificationData: async (id) => api.get(`/company/${id}/`),
  summary: async (id) => api.get(`/company/${id}/summary/`),
  getClients: async (params) => api.get('/company/client', { params }),
  addClient: async (data) => api.post('/company/client', data),
  clientData: async (id) => api.get(`/company/client/${id}`),
  editClient: async (id, data) => api.put(`/company/client/${id}`, data),
  getClientCollections: async (id, params) => api.get(`/company/client/${id}/collections`, { params }),
  addCollections: async (data) => api.post('/company/collection', data),
  deleteCollection: async (collectionId) => api.delete(`/company/collection/${collectionId}`),
  getReports: async (params) => api.get('/company/report', { params }),
  addDumps: async (data) => api.post('/company/dump', data),
  dump: async (params) => api.get('/company/dump', { params }),
  deleteDump: async (dumpId) => api.delete(`/company/dump/${dumpId}`),
  reportDetails: async (reportId) => api.get(`/company/report/${reportId}`),
  editReport: async (id, data) => api.patch(`/company/report/${id}`, data),
  checkStatementStatus: async (params) => api.get('/company/statement/status', { params }),
  generateStatemen: async (data) => api.post('/company/statement/generate', data, { responseType: 'blob' }),
};

export default CompanyAPI;
