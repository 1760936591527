import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Skeleton from '@mui/material/Skeleton';
import colors from 'assets/theme/base/colors';
import styles from 'components/SuiSelect/styles';

const SuiSelect = forwardRef(({
  isLoading, size, error, value, placeholder, success, ...rest
}, ref) => {
  const { light } = colors;
  return isLoading ? <Skeleton height={size === 'large' ? 48 : 40} sx={{ width: '100%', minWidth: '250px' }} />
    : (
      <Select
        className={`custom-select ${rest.isDisabled && 'disabled-select'}`}
        {...rest}
        ref={ref}
        placeholder={placeholder}
        styles={styles(size, error, success)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
});

// Setting default values for the props of SuiSelect
SuiSelect.defaultProps = {
  size: 'medium',
  error: false,
  success: false,
  isLoading: false,
  value: '',
  placeholder: '',
};

// Typechecking props for the SuiSelect
SuiSelect.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  error: PropTypes.bool,
  success: PropTypes.bool,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SuiSelect;
