import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SuiBox from 'components/SuiBox';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useSoftUIController,
  setTransparentNavbar,
} from 'context';
import Breadcrumbs from './Breadcrumbs';
import {
  navbar,
  navbarContainer,
  navbarRow,
} from './styles';

const DashboardNavbar = ({
  absolute,
  light,
  isMini,
  setHideMobileMenu,
}) => {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const {
    transparentNavbar, fixedNavbar,
  } = controller;
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const isMobile = () => window.innerWidth < 768;

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)} style={{ minHeight: isMobile() && '0px', marginTop: isMobile() && '-28px' }}>
        <SuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          <MenuIcon onClick={() => setHideMobileMenu(false)} sx={{ display: !isMobile() && 'none', cursor: 'pointer' }} />
        </SuiBox>
      </Toolbar>
    </AppBar
>
  );
};

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  setHideMobileMenu: PropTypes.func.isRequired,
};

export default DashboardNavbar;
