import moment from 'moment';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
// import SuiButton from 'components/SuiButton';
// import confirmModal from 'components/Modal/ConfirmModal';
// import OrganizationAPI from 'api/organization';
import CircleIcon from '@mui/icons-material/Circle';
import SuiBox from 'components/SuiBox';

// const removeCompany = (id, refetch) => {
//   const CONFIG = {
//     title: 'Czy na pewno chcesz usunąć tą firmę ?',
//     confirmButtonText: 'Tak',
//     cancelButtonText: 'Nie',
//     action: async () => {
//       await OrganizationAPI.removeCompany(id);
//       refetch();
//     },
//     successText: 'Firma została usunięta.',
//     errorText: 'Nie można usunąć tej firmy - odbiory, zrzuty lub raporty zostały już dodane przez tą firmę. Możesz dezaktyowować licencję.',
//   };
//   confirmModal(CONFIG);
// };

const getTableData = (data, user) => {
  const { results } = data;
  let role = '';
  let organizationType = '';
  if (user) {
    role = user.role;
    organizationType = user.organizationType;
  } else {
    role = '';
    organizationType = '';
  }
  const tableData = {
    columns: [
      {
        Header: 'Nazwa firmy',
        accessor: 'name',
        key: 'name',
        sortable: true,
      },
      {
        Header: 'NIP',
        accessor: 'nip',
        key: 'organization.nip',
        sortable: true,
      },
      {
        Header: 'Adres działalności',
        accessor: 'address',
        key: 'organization.address.street',
      },
      {
        Header: 'Numer koncesji',
        accessor: 'licenseNumber',
        key: 'organization.territories.licenseNumber',
      },
      {
        Header: 'Status koncesji',
        accessor: 'licenseStatus',
        key: 'organization.territories.isActive',
      },
      {
        Header: 'Ostatni odbiór',
        accessor: 'lastCollection',
        key: 'lastCollection',
      },
    ],
    rows: results.map((row) => ({
      redirect: `/company/${row._id}/details`,
      name: row.name,
      nip: row.nip,
      address: `${row.address.street} ${row.address.buildingNumber}${row.address.localNumber ? `/${row.address.localNumber}` : ''}, ${row.address.city}`,
      licenseNumber: row.license.licenseNumber,
      licenseStatus: (
        <SuiBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CircleIcon sx={{ marginRight: '16px' }} fontSize="10px" color={row.license.isActive ? 'success' : 'error'} />
          {
            row.license.isActive ? 'aktywna'
              : 'nieaktywna'
          }
        </SuiBox>
      ),
      lastCollection: row.lastCollection ? moment(row.lastCollection).format('YYYY-MM-DD') : '-',
      // delete: (
      //   <Tooltip title="Usuń" placement="top">
      //     <SuiButton onClick={() => removeCompany(row._id, refetch)} variant="outlined" color="error" circular iconOnly>
      //       <DeleteIcon fontSize="large" />
      //     </SuiButton>
      //   </Tooltip>
      // ),
    })),
  };
  if ((role === 'admin' || role === 'editor') && organizationType === 'catchpoint') {
    tableData.columns = tableData.columns.filter((item) => item.accessor !== 'licenseNumber' && item.accessor !== 'licenseStatus');
    // tableData.columns.push({
    //   Header: 'Usuń',
    //   accessor: 'delete',
    //   width: '8%',
    //   key: 'delete',
    // });
  }
  return tableData;
};

export { getTableData };
