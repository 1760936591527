const subPages = [
  {
    label: 'Dane podmiotu',
    route: 'details',
    organizationType: ['community'],
    role: ['admin', 'editor'],

  },
  {
    label: 'Rejestr odbiorów',
    route: 'collections',
    organizationType: ['community'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Rejestr umów',
    route: 'contracts',
    organizationType: ['community'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Rejestr pomiarów',
    route: 'measurements',
    organizationType: ['community'],
    role: ['admin', 'editor'],
  },
  {
    label: 'Bilanse',
    route: 'balances',
    organizationType: ['community'],
    role: ['admin', 'editor'],
  },
];

export { subPages };
