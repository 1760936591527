import React, { useState } from 'react';
import SuiBox from 'components/SuiBox';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import ProtectedContent from 'guard/ProtectedContent';
import Tab from '@mui/material/Tab';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { subPages } from './utils';

const CompanyDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(subPages.findIndex((item) => params['*'] === item.route));
  const handleSetMenu = (event, newMenu) => {
    setMenu(newMenu);
    navigate(`/company/${params.id}/${subPages[newMenu].route}`);
  };

  return (
    <SuiBox>
      <SuiBox marginBottom={3} marginTop={3}>
        <AppBar position="static">
          <Tabs orientation="horizontal" value={menu} onChange={handleSetMenu}>
            {
              subPages.map((page, index) => (
                <ProtectedContent userRole={page.role} organizationType={page.organizationType}>
                  <Tab onClick={(event) => handleSetMenu(event, index)} key={page.route} label={page.label} />
                </ProtectedContent>
              ))
            }
          </Tabs>
        </AppBar>
      </SuiBox>
      <SuiBox>
        <Outlet />
      </SuiBox>
    </SuiBox>
  );
};

export default CompanyDetails;
