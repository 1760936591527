import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ProtectedContent = ({ children, userRole, organizationType }) => {
  const appState = useSelector((state) => state.app);
  return ((!userRole && !organizationType) || (userRole.length === 0 && organizationType.length === 0)
  || (appState.user && userRole.includes(appState.user.role) && organizationType.includes(appState.user.organizationType)) ? children : null);
};

ProtectedContent.defaultProps = {
  organizationType: [],
  userRole: [],
};

ProtectedContent.propTypes = {
  children: PropTypes.node.isRequired,
  userRole: PropTypes.array,
  organizationType: PropTypes.array,
};

export default ProtectedContent;
