import React from 'react';
import { useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import DataForm from 'components/DataForm';
import UserAPI from 'api/user';
import { useNavigate } from 'react-router-dom';
import { setMessage } from 'store';
import { useMutation } from 'react-query';
import { getErrorFromApi } from 'utils/messages';
import { initialValues, validationSchema, formSchema } from '../utils';

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mutation = useMutation((data) => UserAPI.add(data), {
    onSuccess: () => {
      navigate('/user');
      dispatch(
        setMessage({
          variant: 'success',
          title: 'Użytkownik został zaproszony do utworzenia konta w Asenico drogą mailową.',
        }),
      );
    },
    onError: (err) => {
      const { data } = err.response;
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(data),
        }),
      );
    },
  });
  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <Card sx={{ overflow: 'visible', marginTop: !isMobile() && '32px' }}>
      <SuiBox sx={{ padding: 2 }}>
        <DataForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          formSchema={formSchema}
          fetching={mutation.isLoading}
          submit={onSubmit}
          cancel="/user"
        />
      </SuiBox>
    </Card>
  );
};

export default AddUser;
