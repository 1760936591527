import * as yup from 'yup';

const initialValues = {
  fulladdress: '',
  territory: '',
  address: {
    communityId: '',
    community: '',
    town: '',
    street: '',
    buildingNumber: '',
    localNumber: '',
  },
  producerType: '',
  numberOfRegistered: 0,
  numberOfInhabitants: 0,
  agglomeration: false,
  impuritiesData: {
    impuritiesType: '',
    impuritiesTankType: '',
    capacity: 0,
  },
  waterData: {
    collectionMethod: '',
    mainCounter: [],
    gardenCounter: [],
  },
  contactData: {
    person: '',
    phone: '',
    email: '',
  },
};

const validationSchema = yup.object({
  territory: yup.string().required('To pole jest wymagane!'),
  address: yup.object().shape({
    community: yup.string().required('To pole jest wymagane!'),
    town: yup.string().required('To pole jest wymagane!'),
    street: yup.string().required('To pole jest wymagane!'),
    buildingNumber: yup.string().required('To pole jest wymagane!'),
  }),
  producerType: yup.string().required('To pole jest wymagane!'),
  impuritiesData: yup.object().shape({
    impuritiesType: yup.string().required('To pole jest wymagane!'),
    impuritiesTankType: yup.string().required('To pole jest wymagane!'),
  }),
  waterData: yup.object().shape({
    collectionMethod: yup.string().required('To pole jest wymagane!'),
  }),
  contactData: yup.object().shape({
    email: yup.string().email('Podaj prawidłowy adres email!'),
  }),
});

export { initialValues, validationSchema };
