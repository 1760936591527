import PropTypes from 'prop-types';
import SuiBox from 'components/SuiBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

const DataTableHeadCell = ({
  sortable, sortPage, changeParam, width, id, children, sorted, align, sortedKey, ...rest
}) => {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const isSorted = () => {
    const isCurrent = sortPage && sortPage.includes(sortedKey);
    const direction = sortPage && sortPage.charAt(0) === '-' ? 'desc' : 'asc';
    return isCurrent ? {
      isCurrent,
      direction,
    } : false;
  };

  const setSort = () => {
    if (isSorted) {
      changeParam('sort', isSorted().direction === 'desc' ? sortedKey : `-${sortedKey}`);
    } else {
      changeParam('sort', sortedKey);
    }
  };

  return (
    <SuiBox
      component="th"
      width={width}
      borderBottom={`${borderWidth[1]} solid ${light.main}`}
      py={1.5}
      px={3}
      sx={{ cursor: `${sortable && 'pointer'}` }}
      onClick={() => sortable && setSort()}
    >
      <SuiBox
        {...rest}
        position="relative"
        textAlign={align}
        fontSize={size.xs}
        fontWeight={fontWeightBold}
        color="dark"
        className={`text-uppercase ${sortable && 'cursor-pointer user-select-none'}`}
      >
        {children}
        {sortable && sorted && (
          <SuiBox
            fontSize={size.lg}
            position="absolute"
            top={0}
            right={align !== 'right' ? '16px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
          >
            <SuiBox
              position="absolute"
              top={-6}
              color={isSorted() && isSorted().direction === 'asc' ? 'text' : 'secondary'}
              opacity={isSorted() && isSorted().direction === 'asc' ? 1 : 0.5}
            >
              <ArrowDropUpIcon />
            </SuiBox>
            <SuiBox
              position="absolute"
              top={0}
              color={isSorted() && isSorted().direction === 'desc' ? 'text' : 'secondary'}
              opacity={isSorted() && isSorted().direction === 'desc' ? 1 : 0.5}
            >
              <ArrowDropDownIcon />
            </SuiBox>
          </SuiBox>
        )}
      </SuiBox>
    </SuiBox>
  );
};

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: 'auto',
  sorted: 'none',
  align: 'left',
  sortable: false,
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, 'none', 'asce', 'desc']),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  sortable: PropTypes.bool,
  sortPage: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sortedKey: PropTypes.string.isRequired,
  changeParam: PropTypes.func.isRequired,
};

export default DataTableHeadCell;
