import confirmModal from 'components/Modal/ConfirmModal';

const activeLicense = (action) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz aktywować koncesję dla tej firmy?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await action();
    },
    successText: 'Koncesja została aktywowana.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

const deactiveLicense = (action) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz deaktywować koncesję dla tej firmy?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await action();
    },
    successText: 'Koncesja została deaktywowana.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

export { activeLicense, deactiveLicense };
