const getProgressColor = (value) => {
  let color;
  if (value > 75) {
    color = 'success';
  } else if (value <= 75 && value >= 50) {
    color = 'warning';
  } else {
    color = 'error';
  }
  return color;
};

export { getProgressColor };
