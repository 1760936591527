import store from 'store';
import api from './index';

const ReceptionAPI = {
  receptionList: async (params) => {
    const { user } = store.getState().app;
    return user.group === 'Administrator firmy'
      ? api.get(`/company/${user.company_id}/collection/`, { params })
      : api.get('/collection/', { params });
  },
};

export default ReceptionAPI;
