import axios from 'axios';
import Cookies from 'js-cookie';
import store, { destroyTokens, setUserData } from 'store';

const baseUrl = '/api';

const api = Cookies.get('ACCESS_TOKEN') ? axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${Cookies.get('ACCESS_TOKEN')}`,
  },
}) : axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use(async (req) => {
  const expirationTime = store.getState().app.EXPIRATION_TIME;
  const token = Cookies.get('ACCESS_TOKEN');
  if (Cookies.get('LAST_ACTIVITY') && ((parseInt(Cookies.get('LAST_ACTIVITY'), 10) + 60 * 60 * 24 * 1000) < Date.now())) {
    store.dispatch(destroyTokens());
    window.location.href = '/';
  } else {
    if (token) {
      req.headers['Authorization'] = `Bearer ${Cookies.get('ACCESS_TOKEN')}`;
    }
    if (token && expirationTime) {
      if (parseInt(expirationTime, 10) < Date.now()) {
        try {
          const response = await axios.post(`${baseUrl}/auth/refresh/`, { refreshToken: Cookies.get('REFRESH_TOKEN') });
          const { accessToken } = response.data;
          await store.dispatch(setUserData({
            ACCESS_TOKEN: accessToken,
            REFRESH_TOKEN: Cookies.get('REFRESH_TOKEN'),
          }));
          req.headers['Authorization'] = `Bearer ${accessToken}`;
        } catch (err) {
          store.dispatch(destroyTokens());
        }
      }
    }
    Cookies.set('LAST_ACTIVITY', Date.now());
  }
  return req;
});

api.interceptors.response.use(async (res) => {
  if (res.status === 401) {
    try {
      const response = await axios.post(`${baseUrl}/auth/refresh/`, { refreshToken: Cookies.get('REFRESH_TOKEN') });
      const { accessToken } = response.data;
      await store.dispatch(setUserData({
        ACCESS_TOKEN: accessToken,
        REFRESH_TOKEN: Cookies.get('REFRESH_TOKEN'),
      }));
      // req.headers['Authorization'] = `JWT ${access}`;
    } catch (err) {
      store.dispatch(destroyTokens());
    }
  }
  return res;
});

export default api;
