import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SoftDatePicker from 'components/SuiDatePicker';
import SuiButton from 'components/SuiButton';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { getErrorFromApi } from 'utils/messages';
import { setMessage } from 'store';
import { useDispatch } from 'react-redux';
import ProducerAPI from 'api/producer';
import { useMutation } from 'react-query';
import { Polish } from 'flatpickr/dist/l10n/pl';
import moment from 'moment/moment';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { setRefetchAlerts } from 'store';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DeactivateProducerModal = ({
  open,
  closeDialog,
  producerId,
  refetch,
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);

  const mutation = useMutation(() => ProducerAPI.deactivateProducer(producerId, date), {
    onSuccess: (response) => {
      refetch();
      dispatch(setRefetchAlerts(true));
      closeDialog();
      dispatch(
        setMessage({
          variant: 'success',
          title: response.data.message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => closeDialog()}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Deaktywacja producenta</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
          Data podłączenia do sieci kanalizacyjnej
        </DialogContentText>
        <SoftDatePicker
          options={{
            locale: Polish,
            dateFormat: 'Y-m-d',
          }}
          onClose={([newDate]) => setDate(moment(newDate).format('YYYY-MM-DD'))}
          value={date}
          input={{
            value: date,
            name: 'date',
            icon: {
              component: <CalendarToday />,
              direction: 'left',
            },
          }}
          onClear={() => setDate(null)}
          noLabel
        />
      </DialogContent>
      <DialogActions>
        <SuiButton
          isFetching={mutation.isLoading}
          onClick={() => { closeDialog(); }}
        >
          Anuluj
        </SuiButton>
        <SuiButton
          isFetching={mutation.isLoading}
          disabled={!date}
          variant="gradient"
          color="info"
          onClick={() => mutation.mutate()}
        >
          Deaktywuj producenta
        </SuiButton>
      </DialogActions>
    </Dialog>
  );
};

DeactivateProducerModal.defaultProps = {
  open: false,
  closeDialog: () => null,
};

DeactivateProducerModal.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  producerId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};
export default DeactivateProducerModal;
