import React from 'react';
import Card from '@mui/material/Card';
import SuiBox from 'components/SuiBox';
import DataForm from 'components/DataForm';
import { useQuery, useMutation } from 'react-query';
import TerritoryAPI from 'api/territory';
import OrganizationAPI from 'api/organization';
import { getErrorFromApi } from 'utils/messages';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMessage } from 'store';
import { initialValues, validationSchema, formSchema } from './utils';

const OrganizationAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useQuery('all_territories', TerritoryAPI.getTerritories, { refetchOnMount: false, refetchOnWindowFocus: false });
  const mutation = useMutation((organizationData) => OrganizationAPI.createOrganization(organizationData), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/organization');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const onSubmit = (organizationData) => {
    mutation.mutate({
      organization: {
        ...organizationData.organization,
        territories: organizationData.organization.territories.map((item) => ({
          id: item.value,
          isActive: true,
        })),
      },
      user: {
        ...organizationData.user,
        organizationType: organizationData.organization.type,
      },
    });
  };

  return (
    <Card sx={{ overflow: 'visible' }}>
      <SuiBox sx={{ padding: 2 }}>
        <DataForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          formSchema={formSchema(data)}
          fetching={mutation.isLoading}
          submit={onSubmit}
          cancel="/organization"
        />
      </SuiBox>
    </Card>
  );
};

export default OrganizationAdd;
