import React, { useState } from 'react';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import TextField from 'components/DataForm/TextField';
import SelectField from 'components/DataForm/SelectField';
import SoftDatePicker from 'components/SuiDatePicker';
import SuiTypography from 'components/SuiTypography';
import CompanyAPI from 'api/company';
import SuiButton from 'components/SuiButton';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import CalendarToday from '@mui/icons-material/CalendarToday';
import DeleteIcon from '@mui/icons-material/Delete';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { setMessage } from 'store';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { initialValues, validationSchema } from './utils';

const CompanyCollectionsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initValues, setInitValues] = useState(initialValues);
  const trucksQuery = useQuery('company_vehicle', CompanyAPI.vehicle, { refetchOnMount: false, refetchOnWindowFocus: false });
  const clientsQuery = useQuery('company_clients', () => CompanyAPI.getClients({ limit: 2000 }));

  const mutation = useMutation((data) => CompanyAPI.addCollections(data), {
    onSuccess: (response) => {
      const { message } = response.data;
      navigate('/collection');
      dispatch(
        setMessage({
          variant: 'success',
          title: message,
        }),
      );
    },
    onError: (err) => {
      dispatch(
        setMessage({
          variant: 'error',
          title: getErrorFromApi(err.response.data),
        }),
      );
    },
  });

  const addNewRow = (date, vehicle, vehicleData, values) => {
    setInitValues({
      receipts: [
        {
          key: Date.now(),
          date,
          vehicle,
          vehicleData,
          client: '',
          capacity: '',
        },
        ...values.receipts,
      ],
    });
  };

  const deleteRow = (key, values) => {
    setInitValues({
      receipts: values.receipts.filter((item) => item.key !== key),
    });
  };

  const trucksOptions = () => {
    if (trucksQuery && trucksQuery.data && trucksQuery.data.data) {
      const { results } = trucksQuery.data.data;
      return results.map((item) => ({
        label: item.number,
        value: item.number,
        id: item.id,
      }));
    }
    return [];
  };

  const clientsOptions = () => {
    if (clientsQuery && clientsQuery.data && clientsQuery.data.data) {
      const { results } = clientsQuery.data.data;
      return results.map((item) => ({
        label: `${item.address.town} ${item.address.street} ${item.address.buildingNumber}${item.address.localNumber ? `/${item.address.localNumber}` : ''}, ${item.address.community}`,
        value: `${item.address.town} ${item.address.street} ${item.address.buildingNumber}${item.address.localNumber ? `/${item.address.localNumber}` : ''}, ${item.address.community}`,
        address: item.address,
        territory: item.territory,
        impuritiesType: item.impuritiesType,
        id: item.id,
        producer: item.producer,
      }));
    }
    return [];
  };

  const submit = (formData) => {
    const collections = formData.receipts.map((item) => ({
      date: item.date,
      truck: item.vehicleData,
      territory: item.territory,
      client: item.clientData,
      capacity: item.capacity.replaceAll(',', '.'),
      impuritiesType: item.impuritiesType,
    }));
    mutation.mutate({ collections });
  };

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%', marginTop: !isMobile() && '32px' }}>
      <Card>
        <SuiBox className="add-collection" sx={{ padding: 4 }}>
          <Formik
            enableReinitialize
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => submit(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
            }) => (
              <Form>
                <Grid
                  className="add-collection-labels"
                  sx={{ marginBottom: '-50px' }}
                  container
                  spacing={2}
                >
                  <Grid item xs={3}>
                    <SuiTypography variant="subtitle2">
                      Data odbioru
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiTypography variant="subtitle2">
                      Pojazd odbierający
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <SuiTypography variant="subtitle2">
                      Klient
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <SuiTypography variant="subtitle2">
                      Objętość (m3)
                    </SuiTypography>
                  </Grid>
                </Grid>
                {
                  values.receipts.map((item, index) => (
                    <Grid key={item.key} container spacing={2}>
                      <Grid item xs={12} md={3} mb={isMobile() && 2}>
                        <SoftDatePicker
                          options={{
                            locale: Polish,
                            dateFormat: 'Y-m-d',
                            maxDate: moment(new Date()).format('YYYY-MM-DD'),
                          }}
                          onChange={([date]) => {
                            handleChange({ target: { value: moment(date).format('YYYY-MM-DD'), name: `receipts[${index}].date` } });
                          }}
                          input={{
                            placeholder: isMobile() && 'Data odbioru',
                            value: values.receipts[index].date,
                            name: `receipts[${index}].date`,
                            error: (errors.receipts && errors.receipts[index] && errors.receipts[index].date) && (touched.receipts && touched.receipts[index] && touched.receipts[index].date),
                            size: 'large',
                            icon: {
                              component: <CalendarToday />,
                              direction: 'left',
                            },
                          }}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.receipts && errors.receipts[index] && errors.receipts[index].date) && (touched.receipts && touched.receipts[index].date) && errors.receipts[index] && errors.receipts[index].date}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={2} mb={isMobile() && 2}>
                        <SelectField
                          field={{
                            name: `receipts[${index}].vehicle`,
                            options: trucksOptions(),
                            placeholder: isMobile() && 'Pojazd odbierający',
                          }}
                          value={values.receipts[index].vehicle}
                          error={(errors.receipts && errors.receipts[index] && errors.receipts[index].vehicle) && (touched.receipts && touched.receipts[index] && touched.receipts[index].vehicle)}
                          handleChange={(event) => {
                            handleChange({ target: { value: { number: event.value, id: event.id }, name: `receipts[${index}].vehicleData` } });
                            handleChange(event);
                          }}
                          readonly={trucksQuery.isLoading}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.receipts && errors.receipts[index] && errors.receipts[index].vehicle) && (touched.receipts && touched.receipts[index] && touched.receipts[index].vehicle) && errors.receipts[index].vehicle}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={4} mb={isMobile() && 2}>
                        <SelectField
                          field={{
                            name: `receipts[${index}].client`,
                            options: clientsOptions(),
                            placeholder: isMobile() && 'Klient',
                          }}
                          value={values.receipts[index].client}
                          error={(errors.receipts && errors.receipts[index] && errors.receipts[index].client) && (touched.receipts && touched.receipts[index] && touched.receipts[index].client)}
                          handleChange={(event) => {
                            handleChange({ target: { value: event.territory, name: `receipts[${index}].territory` } });
                            handleChange({ target: { value: event.impuritiesType, name: `receipts[${index}].impuritiesType` } });
                            handleChange({ target: { value: { address: event.address, id: event.id, producer: event.producer }, name: `receipts[${index}].clientData` } });
                            handleChange(event);
                          }}
                          readonly={clientsQuery.isLoading}
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.receipts && errors.receipts[index] && errors.receipts[index].client) && (touched.receipts && touched.receipts[index] && touched.receipts[index].client) && errors.receipts[index].client}
                        </SuiTypography>
                      </Grid>
                      <Grid item xs={12} md={2} mb={isMobile() && 2}>
                        <TextField
                          field={{
                            name: `receipts[${index}].capacity`,
                            placeholder: isMobile() && 'Objętość (m3)',
                          }}
                          value={values.receipts[index].capacity}
                          error={(errors.receipts && errors.receipts[index] && errors.receipts[index].capacity) && (touched.receipts && touched.receipts[index] && touched.receipts[index].capacity)}
                          handleChange={handleChange}
                          autoComplete="off"
                        />
                        <SuiTypography component="div" color="error" variant="caption">
                          {(errors.receipts && errors.receipts[index] && errors.receipts[index].capacity) && (touched.receipts && touched.receipts[index] && touched.receipts[index].capacity) && errors.receipts[index].capacity}
                        </SuiTypography>
                      </Grid>
                      <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} item xs={12} md={1}>
                        {
                          values.receipts.length > 1 && (
                            <SuiBox className="add-new-collection" sx={{ marginRight: 1 }}>
                              <Tooltip title="Usuń odbiór" placement="top">
                                <SuiButton onClick={() => deleteRow(item.key, values)} color="error" variant="outlined" circular iconOnly>
                                  <DeleteIcon fontSize="large" />
                                </SuiButton>
                              </Tooltip>
                            </SuiBox>
                          )
                        }
                        {
                          index === 0 && (
                            <Tooltip className="add-new-collection" title="Dodaj kolejny odbiór" placement="top">
                              <SuiButton onClick={() => addNewRow(values.receipts[index].date, values.receipts[index].vehicle, values.receipts[index].vehicleData, values)} variant="gradient" color="info" circular iconOnly>
                                <AddIcon fontSize="large" />
                              </SuiButton>
                            </Tooltip>
                          )
                        }
                      </Grid>
                    </Grid>
                  ))
                }
                <Grid className="add-collection-buttons" justifyContent="end" sx={{ marginBottom: 16, marginTop: 4 }} container spacing={2}>
                  <SuiButton
                    sx={{ marginRight: 2 }}
                    onClick={() => navigate('/collection')}
                    size="large"
                    color="white"
                    disabled={mutation.isLoading}
                  >
                    Anuluj
                  </SuiButton>
                  <SuiButton
                    type="submit"
                    size="large"
                    color="info"
                    variant="gradient"
                    disabled={mutation.isLoading}
                  >
                    { mutation.isLoading && <CircularProgress sx={{ marginRight: '16px' }} size={20} color="white" thickness={7} /> }
                    Zapisz
                  </SuiButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default CompanyCollectionsAdd;
