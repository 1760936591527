import React from 'react';
import SuiBox from 'components/SuiBox';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import SuiTypography from 'components/SuiTypography';
import DataInfo from 'components/DataInfo';
import SuiButton from 'components/SuiButton';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyAPI from 'api/company';

const CompanyClientsDetailsData = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useQuery(['company_client_data', id], () => CompanyAPI.clientData(id), { refetchOnMount: false, refetchOnWindowFocus: false });

  const getFullAddress = () => `${data.data.address.street} ${data.data.address.buildingNumber}${data.data.address.localNumber ? `/${data.data.address.localNumber}` : ''}, ${data.data.address.community}`;

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ width: '100%' }}>
      <SuiBox
        className="floating-button-panel"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: isMobile() ? 2 : 4,
          marginTop: isMobile() ? 0 : '-96px',
        }}
      >
        <SuiButton onClick={() => navigate(`/client/${id}/edit`)} variant="gradient" color="info">
          Edytuj dane
        </SuiButton>
      </SuiBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <SuiBox sx={{ padding: 4 }}>
              <SuiTypography variant="subtitle2">
                Dane kontaktowe
              </SuiTypography>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Adres nieruchomości" value={data && data.data && getFullAddress()} />
              </SuiBox>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Osoba kontaktowa" value={data && data.data && data.data.contact.person ? data.data.contact.person : '-'} />
              </SuiBox>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Numer telefonu" value={data && data.data && data.data.contact.phone ? data.data.contact.phone : '-'} />
              </SuiBox>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Adres email" value={data && data.data && data.data.contact.email ? data.data.contact.email : '-'} />
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <SuiBox sx={{ padding: 4 }}>
              <SuiTypography variant="subtitle2">
                Umowa
              </SuiTypography>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Numer umowy" value={data && data.data && data.data.contract.number ? data.data.contract.number : '-'} />
              </SuiBox>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Data zawarcia umowy" value={data && data.data && moment(data.data.contract.date).format('YYYY-MM-DD')} />
              </SuiBox>
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Status umowy" value={data && data.data && data.data.contract.isActive ? 'aktywna' : 'rozwiązana'} />
              </SuiBox>
              {
                data && data.data && !data.data.contract.isActive && (
                  <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                    <DataInfo isLoading={isLoading} label="Data rozwiązania umowy" value={data && data.data && data.data.contract.inactiveDate ? moment(data.data.contract.inactiveDate).format('YYYY-MM-DD') : '-'} />
                  </SuiBox>
                )
              }
              <SuiBox sx={{ marginTop: 2, position: 'relative' }}>
                <DataInfo isLoading={isLoading} label="Rodzaj przekazanych nieczystości" value={data && data.data && data.data.contract.impuritiesType === 'household' ? 'bytowe' : 'przemysłowe'} />
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default CompanyClientsDetailsData;
