import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuiSelect from 'components/SuiSelect';

const SelectField = ({
  field,
  handleChange,
  value,
  error,
  readonly,
}) => {
  const [focused, setFocused] = useState(false);

  const changeValue = (select) => {
    if (field.isMulti) {
      handleChange({ target: { value: select, name: field.name }, ...select });
    } else {
      handleChange({ target: { value: select.value, name: field.name }, ...select });
    }
  };

  return (
    <>
      <label data-text={field.placeholder} htmlFor={field.name} variant="overline" className={`input-label ${focused && 'active'}`}>{field.placeholder}</label>
      <SuiSelect
        error={error}
        size="large"
        name={field.name}
        onChange={(select) => changeValue(select)}
        placeholder={field.placeholder}
        options={field.options}
        value={value}
        isDisabled={readonly}
        defaultValue={field.options.find((item) => item.value === value)}
        isMulti={field.isMulti}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </>
  );
};

SelectField.defaultProps = {
  error: false,
  readonly: false,
  value: '',
};

SelectField.propTypes = {
  field: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  readonly: PropTypes.bool,
};

export default SelectField;
