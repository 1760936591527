import api from './index';

const OrganizationAPI = {
  createOrganization: async (data, find) => {
    if (find === true) {
      return api.patch('/organization/addLicense', data);
    }
    return api.post('/organization', data);
  },
  getTerritories: async () => api.get('/organization/territories'),
  getOrganizations: async (params) => api.get('/organization', { params }),
  getReports: async (params) => api.get('/organization/report', { params }),
  reportDetails: async (reportId) => api.get(`/organization/report/${reportId}`),
  editReport: async (id, data) => api.patch(`/organization/report/${id}`, data),
  getCollections: async (params) => api.get('/organization/collection', { params }),
  getDumps: async (params) => api.get('/organization/dump', { params }),
  getCompanies: async (params) => api.get('/organization/company', { params }),
  removeCompany: async (id) => api.delete(`/organization/company/${id}`),
  getCatchpoint: async () => api.get('/organization/catchpoint'),
  getCompany: async (id) => api.get(`/organization/company/${id}`),
  getCompanyCollections: async (params, id) => api.get(`/organization/company/${id}/collections`, { params }),
  getCompanyDumps: async (params, id) => api.get(`/organization/company/${id}/dumps`, { params }),
  getCompanyClients: async (params, id) => api.get(`/organization/company/${id}/clients`, { params }),
  getCompanyReports: async (params, id) => api.get(`/organization/company/${id}/reports`, { params }),
  updateCompanyLicense: async (data, id, territory) => api.patch(`/organization/company/${id}/updateLicense/${territory}`, data),
  searchCompany: async (params) => api.get('/organization/search/company', { params }),
};

export default OrganizationAPI;
