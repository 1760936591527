import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import SuiBox from 'components/SuiBox';
import { useSoftUIController } from 'context';
import {
  useNavigate,
} from 'react-router-dom';
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from './styles/sidenavCollapse';

const SidenavCollapse = ({
  color, icon, name, route, children, active, noCollapse, open, ...rest
}) => {
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;

  const clickLink = () => {
    navigate(route);
  };

  return (
    <>
      <ListItem onClick={() => clickLink()} component="li">
        <SuiBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
          >
            {typeof icon === 'string' ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />
        </SuiBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  color: 'info',
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  route: PropTypes.string.isRequired,
};

export default SidenavCollapse;
