import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import SuiBox from 'components/SuiBox';
// import Footer from 'examples/Footer';
import {
  useSoftUIController,
  // setMiniSidenav,
  setLayout,
  setTransparentSidenav,
} from 'context';
import routes from 'routes';
import brand from 'assets/img/spinner.png';
import DashboardLayout from './DashboardLayout';
import Sidenav from './Sidenav';
import Header from './Header';

const LayoutDashboard = ({ children }) => {
  const [hideMobileMenu, setHideMobileMenu] = useState(true);
  const [controller, dispatch] = useSoftUIController();
  // const { miniSidenav, sidenavColor } = controller;
  const { sidenavColor } = controller;
  // const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  // const handleOnMouseEnter = () => {
  //   if (miniSidenav && !onMouseEnter) {
  //     setMiniSidenav(dispatch, false);
  //     setOnMouseEnter(true);
  //   }
  // };

  // Close sidenav when mouse leave mini sidenav
  // const handleOnMouseLeave = () => {
  //   if (onMouseEnter) {
  //     setMiniSidenav(dispatch, true);
  //     setOnMouseEnter(false);
  //   }
  // };

  // Change the document layout to VR for the VR view
  useEffect(() => {
    setLayout(dispatch, 'vr');
    setTransparentSidenav(dispatch, false);
  }, [pathname]);

  const isMobile = () => window.innerWidth < 768;

  return (
    <SuiBox sx={{ display: 'flex' }}>
      <SuiBox display={{ lg: 'block' }}>
        <Sidenav
          color={sidenavColor}
          brand={brand}
          brandName="ASENICO"
          routes={routes}
          hideMobileMenu={hideMobileMenu}
          setHideMobileMenu={setHideMobileMenu}
        />
      </SuiBox>
      <SuiBox sx={{ width: '100%' }}>
        <SuiBox mt={isMobile() ? 0 : 3} mx={isMobile() ? 0 : 3}>
          <Header setHideMobileMenu={setHideMobileMenu} />
        </SuiBox>
        <SuiBox>
          <DashboardLayout>{children}</DashboardLayout>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
};

LayoutDashboard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutDashboard;
