import api from './index';

const UserAPI = {
  userList: async (params) => api.get('/auth/user', { params }),
  delete: async (id) => api.patch(`/auth/delete/${id}`),
  getUser: async (id) => api.get(`/auth//user/${id}`),
  add: async (data) => api.post('/auth/add', data),
  edit: async (data, id) => api.patch(`/auth/user/${id}`, data),
  reset: async (id) => api.post(`/auth/user/reset/${id}`),
};

export default UserAPI;
