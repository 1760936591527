import * as yup from 'yup';
import moment from 'moment';

const initialValues = (clientData) => {
  if (clientData) {
    const {
      address,
      contact,
      contract,
      producer,
    } = clientData;
    address.communityId = clientData.territory;
    return {
      fulladdress: '',
      address,
      producer,
      contract: {
        ...contract,
        date: moment(contract.date).format('YYYY-MM-DD'),
        inactiveDate: contract.inactiveDate ? moment(contract.inactiveDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      },
      contact,
    };
  }
  return {
    fulladdress: '',
    address: {
      territory: '',
      town: '',
      street: '',
      community: '',
      buildingNumber: '',
      localNumber: '',
    },
    contract: {
      number: '',
      date: '',
      inactiveDate: '',
      isActive: true,
      impuritiesType: '',
    },
    contact: {
      person: '',
      phone: '',
      email: '',
    },
  };
};

const validationSchema = yup.object({
  address: yup.object().shape({
    town: yup.string().required('To pole jest wymagane!'),
    street: yup.string().required('To pole jest wymagane!'),
    community: yup.string().required('To pole jest wymagane!'),
    buildingNumber: yup.string().required('To pole jest wymagane!'),
  }),
  contract: yup.object().shape({
    date: yup.string().required('To pole jest wymagane!'),
    isActive: yup.string().required('To pole jest wymagane!'),
    impuritiesType: yup.string().required('To pole jest wymagane!'),
    inactiveDate: yup.string('To pole jest wymagane!').required('To pole jest wymagane!').nullable(),
  }),
});

export { initialValues, validationSchema };
