import * as yup from 'yup';
import SuiButton from 'components/SuiButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import confirmModal from 'components/Modal/ConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import CompanyAPI from 'api/company';

const removeVehicle = (vehicleId, refetch) => {
  const CONFIG = {
    title: 'Czy na pewno chcesz usunąć ten pojazd ?',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    action: async () => {
      await CompanyAPI.deleteVehicle(vehicleId);
      refetch();
    },
    successText: 'Pojazd został usunięty.',
    errorText: 'Uuups, coś poszło nie tak.',
  };
  confirmModal(CONFIG);
};

const getTableData = (data, refetch, setToggleEdit) => {
  const tableData = {
    columns: [
      {
        Header: 'Numer wozu',
        accessor: 'number',
        key: 'trucks.number',
        sortable: true,
        width: '45%',
      },
      {
        Header: 'Pojemność wozu',
        accessor: 'capacity',
        key: 'trucks.capacity',
        sortable: true,
        width: '45%',
      },
      {
        Header: 'Edytuj',
        accessor: 'edit',
      },
      {
        Header: 'Usuń',
        accessor: 'delete',
      },
    ],
    rows: data.results.map((row) => ({
      ...row,
      capacity: `${row.capacity}m3`,
      delete: (
        <Tooltip title="Usuń" placement="top">
          <SuiButton
            onClick={() => removeVehicle(row.id, refetch)}
            variant="outlined"
            color="error"
            circular
            iconOnly
          >
            <DeleteIcon fontSize="large" />
          </SuiButton>
        </Tooltip>
      ),
      edit: (
        <Tooltip title="Usuń" placement="top">
          <SuiButton
            onClick={() => setToggleEdit(row)}
            variant="outlined"
            color="info"
            circular
            iconOnly
          >
            <EditIcon fontSize="large" />
          </SuiButton>
        </Tooltip>
      ),
    })),
  };
  return tableData;
};

const formSchema = {
  fields: [
    {
      name: 'number',
      placeholder: 'Numer rejestracyjny',
      width: 6,
    },
    {
      name: 'capacity',
      placeholder: 'Pojemność(m3)',
      width: 6,
      type: 'number',
    },
  ],
  button: {
    fullWidth: false,
    label: 'Zapisz',
    size: 'large',
    variant: 'gradient',
    color: 'info',
  },
};

const validationSchema = yup.object({
  number: yup.string().required('To pole jest wymagane!'),
  capacity: yup.string().required('To pole jest wymagane!'),
});

const initialValues = {
  number: '',
  capacity: '',
};

const initialValuesEdit = (data) => ({
  number: data.number,
  capacity: data.capacity,
});

export {
  getTableData,
  formSchema,
  validationSchema,
  initialValues,
  initialValuesEdit,
};
